import { Button } from "@chakra-ui/react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CustomInput from "../../utils/CustomInput";
import AuthLayout from "./AuthLayout";
import { IoIosArrowRoundBack } from "react-icons/io";
import useCheckEmptyFields from "../../hooks/useCheckEmptyFields";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../firebase";
import { handleFirebaseError } from "../../utils/Helpers";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [checkEmptyFields] = useCheckEmptyFields();
  const [formState, setFormState] = useState({
    email: "",
    loading: false,
  });

  const handleInputs = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormState({ ...formState, [name]: value });
  };

  const submitForm = async (e: any) => {
    e.preventDefault();
    const { email } = formState;

    const isFormEmpty = checkEmptyFields({
      email,
    });

    if (!isFormEmpty) {
      setFormState({ ...formState, loading: true });
      try {
        const loginDetails = await sendPasswordResetEmail(auth, email);
        console.log(loginDetails, "login details");

        setFormState({ ...formState, loading: false });
        navigate("/link-sent", {
          state: {
            data: {
              message: "🟢 Password reset email sent successfully",
              email: email,
            },
          },
        });
        // setFlow("reset");

        // Other logic...
      } catch (error) {
        handleFirebaseError(error);
        setFormState({ ...formState, loading: false });
      }
    }
  };

  return (
    <AuthLayout
      heading="Forgot password"
      subHeading="No worries, we’ll send reset instructions."
    >
      <form onSubmit={submitForm}>
        <CustomInput
          label="Your email"
          name="email"
          type="email"
          value={formState.email}
          onChange={handleInputs}
          mb="13px"
        />
        <Button
          colorScheme={"buttonPrimary"}
          mt={"29px"}
          w={"full"}
          height={"48px"}
          fontWeight={500}
          type="submit"
          rounded="8px"
          isLoading={formState.loading}
        >
          Reset password
        </Button>
        <Button
          variant={"ghost"}
          as={Link}
          to={"/"}
          w={"full"}
          leftIcon={<IoIosArrowRoundBack fontSize={"30px"} />}
          _hover={{ bg: "transparent" }}
          fontWeight={500}
        >
          Back to Sign in
        </Button>
      </form>
    </AuthLayout>
  );
};

export default ForgotPassword;

import React from "react";
import {
  Avatar,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

const AffiliateAndReferralModal = ({
  isOpen,
  onClose,
  refs,
}: {
  isOpen: boolean;
  onClose: () => void;
  refs: any[];
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={{ base: "xs", md: "md" }}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={"1.17rem"}>Friends referred</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Flex flexDirection={"column"} gap={5} mb={"30px"}>
            {refs?.map((ref) => (
              <Flex alignItems={"center"} gap={2}>
                <Avatar
                  name={ref?.username}
                  src={ref?.photoUrl}
                  boxSize={"36px"}
                  bg={"#FFD600"}
                />
                <Text fontSize={"14px"}>{ref?.username}</Text>
              </Flex>
            ))}
          </Flex>
          {!refs?.length ? (
            <Text
              color={"brand.textMuted"}
              textAlign={"center"}
              mt={"30px"}
              mb={"60px"}
            >
              You don't have any referrals yet
            </Text>
          ) : (
            ""
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AffiliateAndReferralModal;
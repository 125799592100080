import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useVerifyTFAMutation } from "../../redux/services/twoFAApi";
import { showError, showSuccess } from "../../utils/Alert";

const Verify2FA = () => {
  const navigate = useNavigate();
  const [verifyTFA, { isLoading: isVerifyTFALoading }] = useVerifyTFAMutation();
  const [code, setCode] = useState("");
  const token = localStorage.getItem("axxToken");

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [navigate, token]);

  const handleSubmit = async () => {
    if (code === "") {
      showError("Enter code");
      return;
    }

    await verifyTFA({ code })
      .unwrap()
      .then((resp) => {
        if (resp?.success) {
          localStorage.setItem("TFAVerified", JSON.stringify(true));
          showSuccess("Successful 👍");
          navigate("/dashboard");
        } else {
          showError("An unknown error occurred");
        }
      })
      .catch((err) => {
        console.log(err);
        showError(
          err?.message ||
            err?.data?.message ||
            "An error occured try again later"
        );
      });
  };

  return (
    <Modal
      isOpen={true}
      onClose={() => null}
      isCentered
      size={{ base: "xs", md: "md" }}
    >
      <ModalOverlay />
      <ModalContent rounded={"16px"}>
        <ModalHeader fontSize={"1.17rem"}>Enter security code</ModalHeader>
        <ModalBody>
          <>
            <FormControl>
              <FormLabel fontSize={"14px"} fontWeight={500}>
                To login, please enter the security code from your Authenticator
                App
              </FormLabel>
              <Input
                placeholder={"Enter code to verify"}
                focusBorderColor={"gray.300"}
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </FormControl>
          </>
        </ModalBody>
        <ModalFooter>
          <Button
            size={"lg"}
            fontSize={"16px"}
            fontWeight={400}
            colorScheme={"buttonPrimary"}
            isLoading={isVerifyTFALoading}
            w={"full"}
            onClick={handleSubmit}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default Verify2FA;

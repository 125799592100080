import { Button, Flex, Icon, Image, Text } from "@chakra-ui/react";
import Or from "../../assets/images/or.svg";
import { FaGoogle } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import AuthLayout from "./AuthLayout";
import useCheckEmptyFields from "../../hooks/useCheckEmptyFields";
import CustomInput from "../../utils/CustomInput";
import {
  getAdditionalUserInfo,
  GoogleAuthProvider,
  sendSignInLinkToEmail,
  signInWithPopup,
  updateProfile,
} from "firebase/auth";
import { auth } from "../../firebase";
import { handleFirebaseError } from "../../utils/Helpers";

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/dashboard";
  const [checkEmptyFields] = useCheckEmptyFields();
  const [formState, setFormState] = useState({
    email: "",
    loading: false,
  });

  const handleInputs = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormState({ ...formState, [name]: value });
  };

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;
        const additionalInfo = getAdditionalUserInfo(result);
        const fullName = user?.displayName;
        if (additionalInfo?.isNewUser && fullName) {
          // TODO: check for wallet pin & 2fa as usual for login flow
          updateProfile(user, {
            displayName: fullName.split(" ")[0],
          })
            .catch((error) => {
              console.log(error, "update user error");
            })
            .finally(() => {
              // @ts-ignore
              localStorage.setItem("axxToken", user?.accessToken);
              navigate(from);
            });
        } else {
          // @ts-ignore
          localStorage.setItem("axxToken", user?.accessToken);
          navigate(from);
        }
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.error(
          "Google Sign-In error:",
          errorCode,
          errorMessage,
          email,
          credential
        );
        handleFirebaseError(error);
      });
  };

  const submitForm = (e: any) => {
    e.preventDefault();
    const { email } = formState;

    const isFormEmpty = checkEmptyFields({
      email,
    });

    if (!isFormEmpty) {
      setFormState({ ...formState, loading: true });
      try {
        const actionCodeSettings = {
          url: `http://localhost:3000/verify?mode=signIn&email=${email}`, // Replace with your app's URL
          handleCodeInApp: true,
        };
        sendSignInLinkToEmail(auth, email, actionCodeSettings)
          .then(() => {
            // setFlow("login");
            localStorage.setItem("emailForSignIn", email);
            navigate("/link-sent", {
              state: {
                data: {
                  message: "🟢 Sign in link has been sent to your email.",
                  email: email,
                },
              },
            });
          })
          .catch((error) => {
            console.log(error);
            handleFirebaseError(error);
            setFormState({ ...formState, loading: false });
            // showError("An error occured while sending signin link");
          });
      } catch (error) {
        handleFirebaseError(error);
        setFormState({ ...formState, loading: false });
      }
    }
  };

  return (
    <AuthLayout heading="Login">
      <Button
        variant={"outline"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        border={"2px solid"}
        borderColor={"brand.primary"}
        padding={"26px 20px"}
        rounded={"8px"}
        gap={"8px"}
        width={"full"}
        onClick={signInWithGoogle}
      >
        <Icon as={FaGoogle} fontSize={"20px"} color="brand.primary" />
        <Text fontSize={"18px"} fontWeight={500}>
          Continue with Google
        </Text>
      </Button>
      <Flex justify={"center"} my="24px">
        <Image src={Or} alt="" />
      </Flex>

      <form onSubmit={submitForm}>
        <CustomInput
          label="Your email"
          name="email"
          type="email"
          value={formState.email}
          onChange={handleInputs}
          mb="13px"
        />
        <Button
          colorScheme={"buttonPrimary"}
          type="submit"
          mt={"29px"}
          w={"full"}
          height={"48px"}
          fontWeight={500}
          isLoading={formState.loading}
          rounded="8px"
        >
          Send link
        </Button>
        <Button
          variant={"ghost"}
          as={Link}
          to={"/signup"}
          w={"full"}
          fontWeight={500}
          _hover={{ bg: "transparent" }}
        >
          Don't have an account? Sign up
        </Button>
      </form>
    </AuthLayout>
  );
};

export default Login;

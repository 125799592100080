import { Button } from "@chakra-ui/react";
import { updatePassword, updateProfile } from "firebase/auth";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import useAlerts from "../../hooks/useAlerts";
import useCheckEmptyFields from "../../hooks/useCheckEmptyFields";
import CustomInput from "../../utils/CustomInput";
import { handleFirebaseError } from "../../utils/Helpers";
import AuthLayout from "./AuthLayout";

const CompleteProfile = () => {
  const [checkEmptyFields] = useCheckEmptyFields();
  const navigate = useNavigate();
  const { showError, showSuccess } = useAlerts();
  const [formState, setFormState] = useState({
    username: "",
    password: "",
    confirmPassword: "",
    loading: false,
  });

  const handleInputs = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormState({ ...formState, [name]: value });
  };

  const submitForm = async (e: any) => {
    e.preventDefault();
    const { username, confirmPassword, password } = formState;

    const isFormEmpty = checkEmptyFields({
      username,
      confirmPassword,
      password,
    });

    if (!isFormEmpty) {
      if (password !== confirmPassword) {
        showError("Passwords do not match");
        return;
      }

      setFormState({ ...formState, loading: true });
      try {
        if (auth.currentUser) {
          await updateProfile(auth.currentUser, {
            displayName: username,
          });

          await updatePassword(auth.currentUser, confirmPassword);

          setFormState({ ...formState, loading: false });
          // @ts-ignore
          localStorage.setItem("axxToken", auth?.currentUser?.accessToken);
          showSuccess("Successful 👍");
          navigate("/dashboard");
        } else {
          setFormState({ ...formState, loading: false });
          showError("Requires recent login");
        }
      } catch (error) {
        handleFirebaseError(error);
        setFormState({ ...formState, loading: false });
      }
    }
  };

  return (
    <AuthLayout>
      <form onSubmit={submitForm}>
        <CustomInput
          label="Username"
          name="username"
          value={formState.username}
          onChange={handleInputs}
          mb="13px"
        />
        <CustomInput
          label="Password"
          name="password"
          type="password"
          value={formState.password}
          onChange={handleInputs}
          mb="13px"
        />
        <CustomInput
          label="Confirm password"
          name="confirmPassword"
          type="password"
          value={formState.confirmPassword}
          onChange={handleInputs}
          mb="13px"
        />
        <Button
          colorScheme={"buttonPrimary"}
          type="submit"
          mt={"29px"}
          w={"full"}
          height={"48px"}
          isLoading={formState.loading}
          fontWeight={500}
          rounded="8px"
        >
          Proceed
        </Button>
      </form>
    </AuthLayout>
  );
};

export default CompleteProfile;

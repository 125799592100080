import { useEffect, useState } from "react";
import LayoutContainerWrapper from "../../components/dashboard/LayoutContainerWrapper";
import { Heading, Text } from "@chakra-ui/react";
import {
  onValue,
  ref,
  query,
  orderByKey,
  limitToLast,
} from "firebase/database";
import NotificationStyles from "../../assets/styles/notification.module.css";
import TimelineItem from "../../components/notification/TimelineItem";
import { database } from "../../firebase";
import { showError } from "../../utils/Alert";
import { useFetchProfileQuery } from "../../redux/services/accountApi";
import NotiIcon from "../../assets/images/icons/notification.png";
import WalletAdd from "../../assets/images/icons/wallet-add.png";
import WalletMinus from "../../assets/images/icons/wallet-minus.png";
import useTextTruncate from "../../hooks/useTextTruncate";

const Notification = () => {
  const [notifications, setNotifications] = useState<any[]>([]);
  const { data: user } = useFetchProfileQuery();
  const { truncateText } = useTextTruncate();

  // fetch messages
  useEffect(() => {
    const notifRef = ref(database, `notifications/users/${user?.uid}`);
    const notifQuery = query(notifRef, orderByKey(), limitToLast(20));

    const unsubscribe = onValue(
      notifQuery,
      (snapshot) => {
        if (snapshot.exists()) {
          const notificationData = snapshot.val();
          const filteredTransactions = Object.keys(notificationData)
            .map((key) => {
              const { subject, body, topic, timestamp, message } =
                notificationData[key] || {};
              return {
                id: key,
                title: subject,
                body,
                message,
                type: topic,
                time: timestamp, // Convert time to Date object
              };
            })
            .sort((a, b) => {
              if (!a.time && !b.time) return 0;
              if (!a.time) return 1;
              if (!b.time) return -1;

              const dateA = new Date(a.time);
              const dateB = new Date(b.time);

              // @ts-ignore
              return dateB - dateA;
            });
          setNotifications(filteredTransactions);
        }
      },
      (error) => {
        console.error(error);
        showError("Could not fetch notifications");
      }
    );

    return () => unsubscribe();
  }, [user]);

  return (
    <LayoutContainerWrapper noAside={true}>
      <Heading fontSize={"20px"}>Notification</Heading>

      <div>
        <div className={NotificationStyles.timeline}>
          {notifications.map((val, idx) => {
            let iconBgColor = "brand.secondary";
            let icon = NotiIcon;
            let newDate = new Date(val?.time);
            let newTime = new Date(val?.time);
            // @ts-ignore
            newDate = newDate.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            });

            // @ts-ignore
            newTime = newTime.toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            });

            if (
              val?.type?.toLowerCase() === "deposit" ||
              val?.type?.toLowerCase() === "jackpot win"
            ) {
              iconBgColor = "brand.successTint";
              icon = WalletAdd;
            }

            if (
              val?.type?.toLowerCase() === "withdraw" ||
              val?.type?.toLowerCase() === "ticket sale"
            ) {
              iconBgColor = "brand.dangerTint";
              icon = WalletMinus;
            }

            return (
              <div
                key={val?.id}
                className={`${NotificationStyles.event} ${
                  idx % 2 === 0
                    ? NotificationStyles.left
                    : NotificationStyles.right
                }`}
              >
                {/* @ts-ignore */}
                <div className={NotificationStyles.eventDate}>{newDate}</div>
                <TimelineItem
                  icon={icon}
                  iconBgColor={iconBgColor}
                  message={truncateText(val?.message, 35)}
                  date={newTime}
                />
              </div>
            );
          })}
        </div>
        {!notifications?.length ? (
          <Text textAlign={"center"} color={"brand.textMuted"} my={"80px"}>
            You have no new notification
          </Text>
        ) : (
          ""
        )}
      </div>
    </LayoutContainerWrapper>
  );
};

export default Notification;

import {
  configureStore,
  // getDefaultMiddleware
} from "@reduxjs/toolkit";
import animationReducer from "./features/animationSlice";
import authReducer from "./features/authSlice";
import { accountApi } from "./services/accountApi";
import { authApi } from "./services/authApi";
import { beneficiariesApi } from "./services/beneficiariesApi";
import { gamesApi } from "./services/gamesApi";
import { twoFAApi } from "./services/twoFAApi";
import { walletApi } from "./services/walletApi";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    animationState: animationReducer,
    [authApi.reducerPath]: authApi.reducer,
    [accountApi.reducerPath]: accountApi.reducer,
    [walletApi.reducerPath]: walletApi.reducer,
    [gamesApi.reducerPath]: gamesApi.reducer,
    [beneficiariesApi.reducerPath]: beneficiariesApi.reducer,
    [twoFAApi.reducerPath]: twoFAApi.reducer,
  },
  devTools: process.env.NODE_ENV === "development",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      authApi.middleware,
      accountApi.middleware,
      walletApi.middleware,
      gamesApi.middleware,
      beneficiariesApi.middleware,
      twoFAApi.middleware,
    ]),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

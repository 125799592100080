import React, { useEffect, useState } from "react";
import {
  useDisclosure,
  Flex,
  Heading,
  Icon,
  Box,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { BsTrash3 } from "react-icons/bs";
import {
  useDeleteCryptoBeneficiaryMutation,
  useFetchSupportedCoinsQuery,
} from "../../redux/services/beneficiariesApi";
import { CryptoBeneficiaryObj } from "../../redux/services/types";
import { showSuccess, showError } from "../../utils/Alert";
import BankIcon from "../../assets/images/icons/bank.png";
import WasteCollection from "../../assets/images/wastecollection.png";
import CryptoBeneficiaryForm from "./CryptoBeneficiaryForm";

const CryptoBeneficiaryRow = ({
  beneficiary,
}: {
  beneficiary: CryptoBeneficiaryObj;
}) => {
  const [supportedCryptos, setSupportedCryptos] = useState<any[]>([]);
  const { data: fetchedSuppCoins, isSuccess: isFetchedSuppCoinsSuccess } =
    useFetchSupportedCoinsQuery();
  const {
    isOpen: isUpdateModalOpen,
    onOpen: onUpdateModalOpen,
    onClose: onUpdateModalClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();
  const [deleteBeneficiary, { isLoading: isDeleteBeneficiaryLoading }] =
    useDeleteCryptoBeneficiaryMutation();

  //   process fetched supported coins
  useEffect(() => {
    if (isFetchedSuppCoinsSuccess) {
      const newArray = fetchedSuppCoins.map((crypto) => ({
        ...crypto,
        value: crypto.id,
        name: crypto.name.toUpperCase(),
        icon: crypto.logo_url,
      }));

      setSupportedCryptos(newArray);
    }
  }, [isFetchedSuppCoinsSuccess, fetchedSuppCoins]);

  const returnCoin = (coin_id: string) => {
    const coin = supportedCryptos.filter((val) => val.id === coin_id);
    if (coin.length) {
      return coin[0];
    }
  };

  const handleDeleteBeneficiary = () => {
    deleteBeneficiary(beneficiary?.id)
      .unwrap()
      .then(() => {
        showSuccess("Beneficiary deleted");
        onDeleteModalClose();
      })
      .catch((err) => {
        console.log(err);
        showError(err?.message || err?.data?.message || "An error occurred");
      });
  };

  const coinInfo = returnCoin(beneficiary?.coin_id);

  return (
    <>
      <Flex
        justifyContent={"space-between"}
        alignItems={"center"}
        cursor="pointer"
        mb={5}
      >
        <Flex
          alignItems={"center"}
          gap={5}
          cursor={"pointer"}
          width={"calc(100% - 15px)"}
          onClick={onUpdateModalOpen}
        >
          <Box backgroundColor={"#F3F3F3"} p={3} rounded={"full"}>
            <img
              src={coinInfo?.logo_url || BankIcon}
              width={"20px"}
              height={"20px"}
              style={{ objectFit: "cover" }}
              alt="bank"
            />
          </Box>
          <Box>
            <Heading as={"h6"} size={"sm"} textTransform={"capitalize"}>
              {beneficiary?.title || beneficiary?.address}
            </Heading>
            <Text>
              <small>{coinInfo?.name}:</small>{" "}
              <b>{beneficiary?.network + " network"}</b>
            </Text>
          </Box>
        </Flex>
        <Icon
          as={BsTrash3}
          fontSize={"20px"}
          color={"brand.dangerDark"}
          onClick={onDeleteModalOpen}
        />
      </Flex>

      <Modal
        isOpen={isUpdateModalOpen}
        onClose={onUpdateModalClose}
        isCentered
        size={{ base: "xs", md: "lg" }}
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent maxH={["80vh", "95vh"]}>
          <ModalCloseButton />
          <ModalHeader>Edit Crypto Beneficiary</ModalHeader>

          <ModalBody>
            <CryptoBeneficiaryForm
              cryptoBeneficiaryToUpdate={beneficiary}
              closeModal={onUpdateModalClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      {/* delete modal */}
      <Modal isOpen={isDeleteModalOpen} onClose={onDeleteModalClose} isCentered>
        <ModalOverlay />
        <ModalContent rounded={"16px"}>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justify={"center"}>
              <img src={WasteCollection} width={"60%"} alt="" />
            </Flex>
            <Heading
              as={"h3"}
              fontSize={["20px", "27px"]}
              textAlign={"center"}
              fontWeight={700}
            >
              Delete Beneficiary
            </Heading>
            <Text
              fontSize={["14px", "17px"]}
              textAlign={"center"}
              fontWeight={500}
            >
              Are you sure you want to delete this beneficiary?
            </Text>
          </ModalBody>
          <ModalFooter justifyContent={"space-between"} gap={4}>
            <Button
              colorScheme={"buttonLight"}
              color={"root.black"}
              width={"50%"}
            >
              Don't delete
            </Button>
            <Button
              variant={"ghostDanger"}
              width={"50%"}
              isLoading={isDeleteBeneficiaryLoading}
              onClick={handleDeleteBeneficiary}
            >
              Yes, delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CryptoBeneficiaryRow;

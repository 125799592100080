import React from "react";
import { Box, Flex, Heading, Icon, useDisclosure } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FaFacebook, FaLinkedinIn, FaTiktok, FaYoutube } from "react-icons/fa";
import { FaSquareInstagram, FaXTwitter } from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";
import CustomModal from "../../utils/CustomModal";

const AboutLo3Re = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Heading
        size={"sm"}
        fontSize={"14px"}
        color={"#868686"}
        fontWeight={600}
        textAlign={"left"}
      >
        About Lo3Re
      </Heading>
      <Box display={"flex"} flexDirection={"column"} gap={5}>
        <Link to={"/settings/#"}>
          <Box>Terms and Conditions</Box>
        </Link>
        <Link to={"/settings/#"}>
          <Box>Privacy Policy</Box>
        </Link>
        <Link to={"/#"}>
          <Box>FAQs</Box>
        </Link>
        <Box onClick={onOpen} cursor={"pointer"}>
          Contact Us
        </Box>
        {/* <Link to={"/settings/#"}><Box>Visit Our Website</Box></Link> */}
      </Box>

      <CustomModal
        isOpen={isOpen}
        title={"Contact us"}
        onClose={onClose}
        bodyPy={"0"}
      >
        <Flex pb={"30px"} gap={5} px={{ base: 3, md: 0 }}>
          <a
            href="https://www.facebook.com/profile.php?id=61552983155166"
            target={"_blank"}
            rel="noreferrer"
          >
            <Icon
              as={FaFacebook}
              fontSize={{ base: "20px", md: "27px", lg: "38px" }}
              color={"#106BFF"}
            />
          </a>
          <a
            href="https://www.linkedin.com/company/lo3re"
            target={"_blank"}
            rel="noreferrer"
          >
            <Icon
              as={FaLinkedinIn}
              fontSize={{ base: "20px", md: "27px", lg: "38px" }}
              color={"#0A66C2"}
            />
          </a>
          <a
            href="https://www.instagram.com/lo3rehq?igsh=NnY5czA2NzNzNzQz"
            target={"_blank"}
            rel="noreferrer"
          >
            <Icon
              as={FaSquareInstagram}
              fontSize={{ base: "20px", md: "27px", lg: "38px" }}
              color={"#E84636"}
            />
          </a>
          <a
            href="https://www.tiktok.com/@lo3rehq"
            target={"_blank"}
            rel="noreferrer"
          >
            <Icon
              as={FaTiktok}
              fontSize={{ base: "20px", md: "27px", lg: "38px" }}
              color={"#20141B"}
            />
          </a>
          <a
            href="https://whatsapp.com/channel/0029VaSt9iNI1rchf7iHIA2Z"
            target={"_blank"}
            rel="noreferrer"
          >
            <Icon
              as={IoLogoWhatsapp}
              fontSize={{ base: "20px", md: "27px", lg: "38px" }}
              color={"#0CC143"}
            />
          </a>
          <a
            href="https://x.com/lo3rehq?t=S8BQn_gyQ5Gtms9HLlEpbw&s=09"
            target={"_blank"}
            rel="noreferrer"
          >
            <Icon
              as={FaXTwitter}
              fontSize={{ base: "20px", md: "27px", lg: "38px" }}
              color={"#000000"}
            />
          </a>
          <a
            href="https://www.youtube.com/@lo3re"
            target={"_blank"}
            rel="noreferrer"
          >
            <Icon
              as={FaYoutube}
              fontSize={{ base: "20px", md: "27px", lg: "38px" }}
              color={"#FF0000"}
            />
          </a>
        </Flex>
      </CustomModal>
    </>
  );
};

export default AboutLo3Re;
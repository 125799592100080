import Logo from "../assets/images/logo-dark.svg";
import { Flex, Text } from "@chakra-ui/react";
import "../assets/styles/Utils.css";

const FullScreenLoader = () => {
  return (
    <div className="fullScreenloader">
      <div>
        <Flex justify={"center"} mb={3}>
          <img src={Logo} className={"animateLogo"} alt="logo" />
        </Flex>
        <div className={"verifyHeaderText"}>
          <Text fontSize={"1.625rem"} fontWeight={"bold"}>
            Unlock Your Lucky Streak🍀✨
          </Text>
        </div>
      </div>
    </div>
  );
};

export default FullScreenLoader;

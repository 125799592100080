// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDldonQYK8Ryk8frmB54IYa1_QK_YBOIO0",
  authDomain: "lo3re-client.firebaseapp.com",
  projectId: "lo3re-client",
  storageBucket: "lo3re-client.appspot.com",
  messagingSenderId: "940675223452",
  appId: "1:940675223452:web:860a7513351752562c0259",
  measurementId: "G-N9XJ7PQ19L",
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const provider = new GoogleAuthProvider();
export const database = getDatabase(app);
export const imageDb = getStorage(app);

// Initialize Firebase Authentication and get a reference to the service
export const getImage = async (location) => {
  const imgUrl = await getDownloadURL(ref(imageDb, location));
  return imgUrl;
};
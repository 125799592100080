import { Box, Flex, Heading, Icon, Text, Tooltip } from "@chakra-ui/react";
import { MdVerified } from "react-icons/md";
import LotteryBg from "../../assets/images/default.png";
import { useNavigate } from "react-router-dom";
import useTextTruncate from "../../hooks/useTextTruncate";
import { GameObj } from "../../redux/services/types";
import useTimeFormatter from "../../hooks/useTimeFormatter";
import { GiTicket } from "react-icons/gi";

interface CardProps {
  minW?: string | string[];
  game: GameObj;
}

function formatNumber(num: number) {
  if (num < 1000) {
    return num.toString();
  } else if (num < 10000) {
    return (num / 1000).toFixed(1) + "k";
  } else if (num < 1000000) {
    return (num / 1000).toFixed(0) + "k";
  } else if (num < 10000000) {
    return (num / 1000000).toFixed(1) + "m";
  } else {
    return (num / 1000000).toFixed(0) + "m";
  }
}

const LotteryGameCard = ({ minW, game }: CardProps) => {
  const navigate = useNavigate();
  const { truncateText } = useTextTruncate();
  const { formatDuration } = useTimeFormatter();
  const timeLeft = formatDuration(game?.endOn);

  const redirectUrl = () => {
    navigate(`/lotteries/view-game/${game?.id}`);
  };

  return (
    <Box
      minW={minW || ["155px"]}
      data-aos={"fade-up"}
      cursor={"pointer"}
      onClick={redirectUrl}
    >
      <Flex
        bg={"#9ebcca"}
        rounded={"16px"}
        color={"#fff"}
        flexDir={"column"}
        justify={"space-between"}
        padding={["15px", "15px"]}
        width={"100%"}
        height={["164px"]}
        backgroundImage={`url("${game?.coverUrl || LotteryBg}")`}
        backgroundSize={"cover"}
        backgroundRepeat={"no-repeat"}
        boxShadow={
          "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset"
        }
        transition={"0.3s ease-out"}
        _hover={{
          transform: "translateY(-2px)",
        }}
      >
        <Flex justify={"space-between"} align={"center"} flexWrap={"wrap"}>
          {timeLeft ? (
            <Text
              bg={"brand.lightGrey"}
              rounded={"30px"}
              display={"inline"}
              padding={"3px 8px"}
              color={"#000"}
              fontSize={"12px"}
            >
              {timeLeft} left
            </Text>
          ) : (
            <div></div>
          )}
          {/* <Text fontSize={"12px"}>10%</Text> */}
          <Tooltip label={"Odds: 10%"} hasArrow>
            <Text
              fontSize={"10px"}
              bg={"brand.secondaryTint"}
              rounded={"100px"}
              px={"4px"}
              py={"2px"}
              color={"brand.black"}
            >
              10%
            </Text>
          </Tooltip>
        </Flex>
        <Flex justify={"space-between"} align={"center"}>
          <Text
            fontSize={"18px"}
            fontWeight={500}
            textShadow={"0px 5px 10px 0px rgba(0, 0, 0, 0.06)"}
            transition={".3s ease-out"}
            color={"#000"}
            px={"5px"}
            rounded={"30px"}
            bg={"brand.lightGrey"}
          >
            ${game?.jackpot.toLocaleString()}
          </Text>
          <Icon
            as={MdVerified}
            color={"brand.secondary"}
            fontSize={["25px", "25px"]}
          />
        </Flex>
      </Flex>
      <Flex justify={"space-between"} my={"8px"}>
        <Tooltip hasArrow label={game?.title}>
          <Heading
            fontSize={["16px", "20px"]}
            fontWeight={500}
            textTransform={"capitalize"}
          >
            {truncateText(game?.title, 8)}
          </Heading>
        </Tooltip>
        <Flex gap={"5px"} align={"center"}>
          <Icon as={GiTicket} fontSize={["14px", "18px"]} color={"#2F53D7"} />
          <Text display={"inline-block"} fontSize={"12px"} color={"#48494D"}>
            {game?.ticketSalesCount
              ? `${formatNumber(game?.ticketSalesCount)}+`
              : game?.ticketSalesCount}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default LotteryGameCard;

import { createSlice } from "@reduxjs/toolkit";

export const animation = createSlice({
  name: "animationSlice",
  initialState: {
    hasQuestProgressAnimationRun: false,
    hasContactCardAnimationRun: false,
  },
  reducers: {
    toggleHasQuestProgressAnimationRun: (state, action) => {
      state.hasQuestProgressAnimationRun = action.payload;
    },
    toggleHasContactCardAnimationRun: (state, action) => {
      state.hasContactCardAnimationRun = action.payload;
    },
  },
});

export const {
  toggleHasContactCardAnimationRun,
  toggleHasQuestProgressAnimationRun,
} = animation.actions;
export default animation.reducer;

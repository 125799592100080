import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  verifyPasswordResetCode,
  applyActionCode,
  isSignInWithEmailLink,
  signInWithEmailLink,
  Auth,
} from "firebase/auth";
import { auth } from "../../firebase";
import Login from "./Login";
import { handleFirebaseError } from "../../utils/Helpers";
import AuthLayout from "./AuthLayout";
import { Button } from "@chakra-ui/react";
import { useMemo } from "react";
import { showError } from "../../utils/Alert";

const HandleEmailActions = () => {
  const location = useLocation();
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [message, setMessage] = useState("");
  const [renderLogin, setRenderLogin] = useState(false);
  const [signInEmail, setSignInEmail] = useState("");
  const navigate = useNavigate();
  const loginView = useMemo(
    () => <Login signInEmail={signInEmail} />,
    [signInEmail]
  );

  useEffect(() => {
    // Extracting query parameters from the URL
    const searchParams = new URLSearchParams(location.search);
    console.log(searchParams);
    // Get the action to complete.
    const mode = searchParams.get("mode");
    // Get the one-time code from the query parameter.
    const actionCode = searchParams.get("oobCode");
    // (Optional) Get the continue URL & apiKey from the query parameter if available.
    const continueUrl = searchParams.get("continueUrl");
    // const apiKey = searchParams.get("apiKey");
    // (Optional) Get the language code if available.
    const lang = searchParams.get("lang") || "en";

    function handleResetPassword(
      auth: Auth,
      actionCode: string | null,
      continueUrl: string | null,
      lang: string
    ) {
      // Localize the UI to the selected language as determined by the lang
      // parameter.

      // Verify the password reset code is valid.
      verifyPasswordResetCode(auth, actionCode || "")
        .then((email) => {
          // const accountEmail = email;
          navigate(`/createPassword?actionCode=${actionCode}`);
        })
        .catch((error) => {
          // Invalid or expired action code. Ask user to try to reset the password
          // again.
          handleFirebaseError(error);
          navigate("/");
        });
    }

    function handleSignInUser(auth: Auth, continueUrl: string | URL | null) {
      if (isSignInWithEmailLink(auth, window.location.href)) {
        const url = new URL(continueUrl || "");
        const emailParam = url.searchParams.get("email");
        const decodedEmail = decodeURIComponent(emailParam || "");

        // The client SDK will parse the code from the link for you.
        signInWithEmailLink(auth, decodedEmail, window.location.href)
          .then((result) => {
            //   @ts-ignore
            if (result?._tokenResponse?.isNewUser) {
              navigate(`/complete-profile`);
            } else {
              setSignInEmail(decodedEmail);
              setRenderLogin(true);
            }
          })
          .catch((error) => {
            handleFirebaseError(error);
            setRedirectUrl("/");
            // TODO: undo comment 👇🏽👇🏽
            // navigate("/");
          });
      }
    }

    function handleVerifyEmail(
      auth: Auth,
      actionCode: string | null,
      continueUrl: string | null,
      lang: string
    ) {
      // Localize the UI to the selected language as determined by the lang
      // parameter.
      // Try to apply the email verification code.
      applyActionCode(auth, actionCode || "")
        .then((resp) => {
          console.log("verify email", resp);
          setMessage("🟢 Email confirmation successful");
          setRedirectUrl("/");
        })
        .catch((error) => {
          // Code is invalid or expired. Ask the user to verify their email address
          // again.
          console.log(error);
          handleFirebaseError(error);
        });
    }

    if (mode) {
      switch (mode) {
        case "resetPassword":
          // Display reset password handler and UI.
          handleResetPassword(auth, actionCode, continueUrl, lang);
          break;

        case "verifyEmail":
          // Display email verification handler and UI.
          handleVerifyEmail(auth, actionCode, continueUrl, lang);
          break;

        case "signIn":
          handleSignInUser(auth, continueUrl);
          break;

        default:
          showError("Action unknown");
        // Error: invalid mode.
      }
    }
  }, [location.search, navigate]);

  return (
    <>
      {!renderLogin ? (
        <AuthLayout
          heading="Unlock your lucky streak🍀✨"
          subHeading={message}
          animateLogo={!redirectUrl}
        >
          {redirectUrl && (
            <Button
              as={Link}
              to={redirectUrl || ""}
              colorScheme={"buttonPrimary"}
              mt={"29px"}
              w={"full"}
              height={"48px"}
              fontWeight={500}
              rounded="8px"
            >
              Login
            </Button>
          )}
        </AuthLayout>
      ) : (
        <>{loginView}</>
      )}
    </>
  );
};

export default HandleEmailActions;

import React, {useState} from "react";
import LayoutContainerWrapper from "../../../../components/dashboard/LayoutContainerWrapper";
import ContactCard from "../../../../components/cards/ContactCard";
import {Box, Button, Flex, Heading, Text} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import LeftArrow from "../../../../assets/images/icons/arrow-left.png";
import { RiSmartphoneLine } from "react-icons/ri";
import { MdOutlineMail } from "react-icons/md";
import EmailForTwoFactorAuthenticationModal from "../../../../components/settings/2FA/EmailForTwoFactorAuthenticationModal";
import TwoFactorAuthenticationModal from "../../../../components/settings/2FA/TwoFactorAuthenticationModal";
import { useFetchProfileQuery } from "../../../../redux/services/accountApi";

const TwoFactorAuth = () => {
  const navigate = useNavigate();
  const [is2faModalOpen, setIs2faModalOpen] = useState(false);
  const [isEmail2faModalOpen, setIsEmail2faModalOpen] = useState(false);
  const { data: user } = useFetchProfileQuery();
  const isEmail2faActive = user?.user?.security?.email;
  const isAuthApp2faActive = user?.user?.security["2fa"]
    ? user?.user?.security["2fa"]?.status === "verified"
    : false;

  const goBack = () => {
    navigate(-1);
  };

  return (
    <LayoutContainerWrapper asideChildren={<Aside />}>
      <Flex alignItems={"center"} mb={"30px"}>
        <img
          src={LeftArrow}
          alt="left direction arrow"
          width={20}
          height={20}
          style={{ cursor: "pointer" }}
          onClick={goBack}
        />
        <Heading
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          m={"auto"}
          fontSize={"21px"}
          fontWeight={"semibold"}
        >
          Two-Factor Authentication
        </Heading>
      </Flex>

      <Box
        display={"flex"}
        flexDirection={"column"}
        mt={{ base: "0", md: "30px" }}
      >
        <Link to={"#"} onClick={() => setIs2faModalOpen(true)}>
          <Flex
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box display={"flex"} gap={"10px"} alignItems={"center"}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                bg={"#F3F3F3"}
                rounded={"full"}
                p={"10px"}
              >
                <RiSmartphoneLine
                  size={30}
                  style={{ margin: "auto" }}
                  className={"cursor-pointer"}
                />
              </Box>
              <Box>
                <Heading size={"sm"} fontSize={"14px"} fontWeight={600}>
                  Two-Factor Authentication App
                </Heading>
                <Text color={"#868686"} fontSize={"12px"}>
                  Use an Authentication App as your 2FA
                </Text>
              </Box>
            </Box>
            {isAuthApp2faActive ? (
              <Button colorScheme={"red"} w={"70px"} fontSize={"14px"}>
                Disable
              </Button>
            ) : (
              <Button w={"70px"} variant={"light"} fontSize={"14px"}>
                Set Up
              </Button>
            )}

            {is2faModalOpen && (
              <TwoFactorAuthenticationModal
                isOpen={is2faModalOpen}
                onClose={() => setIs2faModalOpen(false)}
                is2faActive={isAuthApp2faActive}
              />
            )}
          </Flex>
        </Link>

        <Box my={"20px"}>
          <hr />
        </Box>

        <Link to={"#"} onClick={() => setIsEmail2faModalOpen(true)}>
          <Flex
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box display={"flex"} gap={"10px"} alignItems={"center"}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                bg={"#F3F3F3"}
                rounded={"full"}
                p={"10px"}
              >
                <MdOutlineMail
                  size={30}
                  style={{ margin: "auto" }}
                  className={"cursor-pointer"}
                />
              </Box>
              <Box>
                <Heading size={"sm"} fontSize={"14px"} fontWeight={600}>
                  Email for Two-Factor Authentication
                </Heading>
                <Text color={"#868686"} fontSize={"12px"}>
                  Use the security code sent to your email as your 2FA
                </Text>
              </Box>
            </Box>
            {isEmail2faActive ? (
              <Button colorScheme={"red"} w={"70px"} fontSize={"14px"}>
                Disable
              </Button>
            ) : (
              <Button w={"70px"} fontSize={"14px"}>
                Set Up
              </Button>
            )}

            {isEmail2faModalOpen && (
              <EmailForTwoFactorAuthenticationModal
                isOpen={isEmail2faModalOpen}
                onClose={() => setIsEmail2faModalOpen(false)}
                is2faActive={isEmail2faActive || false}
              />
            )}
          </Flex>
        </Link>
      </Box>
    </LayoutContainerWrapper>
  );
};

const Aside = () => {
    return (
        <>
            <ContactCard/>
        </>
    );
};

export default TwoFactorAuth;
import {
  Box,
  Button,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ContactCard from "../../components/cards/ContactCard";
import LotteryGameCard from "../../components/cards/LotteryGameCard";
import LotteryGameCardMini from "../../components/cards/LotteryGameCardMini";
import QuestProgress from "../../components/cards/QuestProgress";
import LayoutContainerWrapper from "../../components/dashboard/LayoutContainerWrapper";
import { IoChevronDownOutline } from "react-icons/io5";
import {
  useFetchGamesQuery,
  useFetchJoinedGamesQuery,
} from "../../redux/services/gamesApi";
import Loader from "../../utils/Loader";
import { GameObj } from "../../redux/services/types";
import FeaturedGameCardMini from "../../components/cards/FeaturedGameCardMini";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// @ts-ignore
import Slider from "react-slick";

const Filters = [
  {
    name: "Latest",
    value: "latest",
  },
  {
    name: "Draw Time",
    value: "drawTime",
  },
  {
    name: "Odds (High - Low)",
    value: "oddsHL",
  },
  {
    name: "Odds (Low - High)",
    value: "oddsLH",
  },
  {
    name: "Price (High - Low)",
    value: "priceHL",
  },
  {
    name: "Price (Low - High)",
    value: "priceLH",
  },
];

const Lotteries = () => {
  const [filter, setFilter] = useState(Filters[0].value);
  const [isSmallerThan1260] = useMediaQuery("(max-width: 1260px)");
  const [isLargerThan550] = useMediaQuery("(min-width: 550px)");
  const [uniqueJoinedGames, setUniqueJoinedGames] = useState<GameObj[]>([]);
  const { data: liveGames, isLoading: isLiveGamesLoading } =
    useFetchGamesQuery();
  const {
    data: joinedGames,
    isLoading: isJoinedGamesLoading,
    isSuccess: isJoinedGamesSuccess,
  } = useFetchJoinedGamesQuery();
  // console.log("games", liveGames);

  useEffect(() => {
    if (isJoinedGamesSuccess) {
      let uniqueItems = joinedGames.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t?.id === item?.id)
      );

      uniqueItems = uniqueItems.filter(
        (item) => item?.status?.toLowerCase() === "live"
      );

      setUniqueJoinedGames(uniqueItems);
    }
  }, [isJoinedGamesSuccess, joinedGames]);

  return (
    <>
      <LayoutContainerWrapper asideChildren={<Aside />}>
        <Box mb={"24px"} minH={"100px"}>
          <Flex justify={"space-between"} align={"center"}>
            <Heading fontSize={"24px"} fontWeight={500}>
              Upcoming games
            </Heading>
            <Text
              _hover={{ textDecor: "underline" }}
              transition={".3s ease-out"}
            >
              <Link to={"/lotteries/my-tickets"}>My tickets</Link>
            </Text>
          </Flex>
          <SimpleGrid columns={{ base: 2, md: 3, lg: 4 }} gap={4} mt={"10px"}>
            {uniqueJoinedGames?.map((game, idx) => (
              <LotteryGameCardMini key={`mlt-${idx}`} game={game} />
            ))}
          </SimpleGrid>
          <Loader isLoading={isJoinedGamesLoading} height="30px" />
          {!joinedGames?.length && !isJoinedGamesLoading ? (
            <Text
              textAlign={"center"}
              my={"20px"}
              color={"brand.textMuted"}
              fontSize={["14px", "16px"]}
            >
              It looks like you haven't jumped into the action just yet. 🎮{" "}
              <br />
              Pick a game from below and let the adventure begin! 🚀
            </Text>
          ) : (
            ""
          )}
        </Box>

        <Box>
          <Flex justify={"space-between"} flexWrap={"wrap"}>
            <Heading fontSize={"24px"} fontWeight={500}>
              Lotteries
            </Heading>
            <Menu>
              <Flex gap={"10px"} align={"center"}>
                <Text>show:</Text>
                <MenuButton
                  as={Button}
                  variant={"link"}
                  color={"brand.black"}
                  fontWeight={400}
                  padding={0}
                  rightIcon={<IoChevronDownOutline />}
                >
                  {Filters.filter((val) => val.value === filter)[0].name}
                </MenuButton>
              </Flex>
              <MenuList>
                {Filters.map((val, index) => (
                  <MenuItem
                    key={"menu-" + index}
                    bg={val.value === filter ? "#EDF2F7" : ""}
                    onClick={() => setFilter(val.value)}
                  >
                    {val.name}
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </Flex>

          {/* games */}
          <Box mt={"10px"}>
            {!isLiveGamesLoading && !liveGames?.length ? (
              <Text textAlign={"center"} color={"brand.textMuted"} my={"100px"}>
                Nothing to show here
              </Text>
            ) : (
              ""
            )}
            <SimpleGrid
              columns={{
                base: isLargerThan550 ? 3 : 2,
                md: 3,
                lg: isSmallerThan1260 ? 3 : 4,
              }}
              gap={4}
            >
              {liveGames?.map((game, idx) => (
                <LotteryGameCard key={`lt-${idx}`} game={game} />
              ))}
            </SimpleGrid>
            <Loader isLoading={isLiveGamesLoading} height="200px" />
            <Flex mt={"30px"} justify={"center"}>
              <Button w={["60%"]} colorScheme={"buttonPrimary"}>
                View more
              </Button>
            </Flex>
          </Box>
        </Box>
      </LayoutContainerWrapper>
    </>
  );
};

const Aside = () => {
  const [featuredGames, setFeaturedGames] = useState<GameObj[]>([]);
  const { data: liveGames, isSuccess: isLiveGamesSuccess } =
    useFetchGamesQuery();

  useEffect(() => {
    if (isLiveGamesSuccess) {
      const noFGs = liveGames.slice(0, 3);
      const FGs = liveGames.filter((game) => game?.isFeatured);
      setFeaturedGames(FGs.length ? FGs : noFGs);
    }
  }, [isLiveGamesSuccess, liveGames]);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  return (
    <>
      <Slider {...settings}>
        {featuredGames.map((game) => (
          <FeaturedGameCardMini key={game?.id} game={game} />
        ))}
      </Slider>

      <QuestProgress />
      <ContactCard />
    </>
  );
};

export default Lotteries;

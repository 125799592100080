import { useToast } from "@chakra-ui/react";

const capitalizeText = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const useCheckEmptyFields = () => {
  const toast = useToast();

  const checkEmptyFields = (fields: any) => {
    let err = false;
    Object.keys(fields).forEach((key: string) => {
      if (fields[key] === "" || fields[key] === undefined) {
        toast({
          position: "top-right",
          title: capitalizeText(`${key?.replace(/_/g, " ")} is required`),
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        err = true;
      }
    });

    return err;
  };

  return [checkEmptyFields];
};

export default useCheckEmptyFields;

import React from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton, useDisclosure, Button
} from "@chakra-ui/react";
import BankBeneficiaryForm from "./BankBeneficiaryForm";
import CryptoBeneficiaryForm from "./CryptoBeneficiaryForm";

interface BeneficiariesModalProps {
    activeTab: string;
}

const BeneficiariesModal: React.FC<BeneficiariesModalProps> = ({activeTab}) => {
    const {isOpen, onOpen, onClose} = useDisclosure();

    return (
      <>
        <Button
          w={"100%"}
          colorScheme={"buttonLight"}
          color={"brand.black"}
          fontWeight={400}
          onClick={onOpen}
        >
          Add New Beneficiary
        </Button>

        <Modal
          isOpen={isOpen}
          onClose={onClose}
          isCentered
          size={{ base: "xs", md: "lg" }}
          scrollBehavior={"inside"}
        >
          <ModalOverlay />
          <ModalContent maxH={["80vh", "95vh"]}>
            <ModalHeader>
              {activeTab === "bank"
                ? "Add Bank Beneficiary"
                : "Add Crypto Beneficiary"}
            </ModalHeader>

            <ModalCloseButton />

            <ModalBody>
              {activeTab === "bank" && (
                <BankBeneficiaryForm closeModal={onClose} />
              )}

              {activeTab === "crypto" && (
                <CryptoBeneficiaryForm closeModal={onClose} />
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
};

export default BeneficiariesModal;
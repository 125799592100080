import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import BalanceCard from "../../components/cards/BalanceCard";
import ContactCard from "../../components/cards/ContactCard";
import LayoutContainerWrapper from "../../components/dashboard/LayoutContainerWrapper";
import { IoChevronDownSharp, IoChevronForward } from "react-icons/io5";
import TransactionHistory from "../../utils/TransactionHistory";
import Beneficiaries from "../../components/beneficiaries/Beneficiaries";
import WithdrawWalletModal from "../../components/wallet/withdrawWallet/WithdrawWalletModal";
import FundWalletModal from "../../components/wallet/fundWallet/FundWalletModal";
import { WalletTypeObj } from "../../redux/services/types";
import useTextTruncate from "../../hooks/useTextTruncate";
import {
  useFetchTransactionsQuery,
  useFetchWalletBalanceQuery,
} from "../../redux/services/walletApi";
import { showError } from "../../utils/Alert";
import Loader from "../../utils/Loader";

const walletTypes = [
  {
    name: "Main wallet",
    value: "main",
  },
  {
    name: "Referral wallet",
    value: "affiliate",
  },
  {
    name: "Bonus wallet",
    value: "bonus",
  },
];

const Wallet = () => {
  const { formatMoney } = useTextTruncate();
  const [mainWallet, setMainWallet] = useState<null | WalletTypeObj>(null);
  const [refWallet, setRefWallet] = useState<null | WalletTypeObj>(null);
  const [bonusWallet, setBonusWallet] = useState<null | WalletTypeObj>(null);
  const [walletType, setWalletType] = useState(0);
  // const {
  //   data: user,
  // } = useFetchProfileQuery();

  const {
    data: walletBalance,
    isSuccess: isWalletBalanceSuccess,
    error: walletBalanceError,
    isLoading: isWalletBalanceLoading,
  } = useFetchWalletBalanceQuery();
  const { data: transactionHistory, isFetching: isTransactionHistoryLoading } =
    useFetchTransactionsQuery("limit=5");
  const handleWalletChange = (val: number) => {
    setWalletType(val);
  };

  useEffect(() => {
    if (isWalletBalanceSuccess) {
      const mainWallet = walletBalance?.filter(
        (val) => val?.type?.toLowerCase() === walletTypes[0].value
      );
      const refWallet = walletBalance?.filter(
        (val) => val?.type?.toLowerCase() === walletTypes[1].value
      );
      const bonusWallet = walletBalance?.filter(
        (val) => val?.type?.toLowerCase() === walletTypes[2].value
      );

      setMainWallet(mainWallet[0]);
      setRefWallet(refWallet[0]);
      setBonusWallet(bonusWallet[0]);
    }
  }, [isWalletBalanceSuccess, walletBalance]);

  useEffect(() => {
    if (walletBalanceError) {
      console.log(walletBalanceError);
      showError("An error occurred, could not fetch wallet balance");
    }
  }, [walletBalanceError]);

  return (
    <LayoutContainerWrapper asideChildren={<Aside />}>
      <Box>
        <Flex justify={"space-between"} align={"center"} mb={2}>
          <Text fontSize={"24px"} fontWeight={500}>
            Wallet
          </Text>
          <Menu>
            <MenuButton
              as={Button}
              size={"sm"}
              fontWeight={400}
              bg={"#F0F0F0"}
              rightIcon={<IoChevronDownSharp />}
            >
              {walletTypes[walletType].name}
            </MenuButton>
            <MenuList>
              {walletTypes.map((wallet, idx) => (
                <MenuItem
                  key={`wt-${idx}`}
                  bg={walletType === idx ? "#EDF2F7" : ""}
                  _hover={{ bg: "#F3F3F3" }}
                  onClick={() => handleWalletChange(idx)}
                >
                  {wallet.name}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Flex>
        <Flex justify={"space-between"} flexWrap={"wrap"}>
          {walletType === 0 ? (
            <>
              <BalanceCard
                title={"Wallet Balance"}
                figure={formatMoney(mainWallet?.balance || 0)}
                isBalanceLoading={isWalletBalanceLoading}
              />
              <BalanceCard
                title={"Locked Balance"}
                figure={formatMoney(mainWallet?.lockedBalance || 0)}
                isBalanceLoading={isWalletBalanceLoading}
              />
            </>
          ) : (
            ""
          )}
          {walletType === 1 ? (
            <>
              <BalanceCard
                title={"Referral Balance"}
                figure={formatMoney(refWallet?.balance || 0) + " TKN"}
                isBalanceLoading={isWalletBalanceLoading}
                variant={"noCurrency"}
                boxClass={"mb20"}
              />
              <BalanceCard
                title={"Locked Balance"}
                figure={formatMoney(refWallet?.lockedBalance || 0) + " TKN"}
                isBalanceLoading={isWalletBalanceLoading}
                variant={"noCurrency"}
                boxClass={"mb20"}
              />
            </>
          ) : (
            ""
          )}
          {walletType === 2 ? (
            <>
              <BalanceCard
                title={"Bonus Balance"}
                figure={formatMoney(bonusWallet?.balance || 0) + " TKN"}
                isBalanceLoading={isWalletBalanceLoading}
                variant={"noCurrency"}
                boxClass={"mb20"}
              />
              <BalanceCard
                title={"Locked Balance"}
                figure={formatMoney(bonusWallet?.lockedBalance || 0) + " TKN"}
                isBalanceLoading={isWalletBalanceLoading}
                variant={"noCurrency"}
                boxClass={"mb20"}
              />
            </>
          ) : (
            ""
          )}
          <BalanceCard
            title={"Total Deposit"}
            figure={"0.00"}
            subtitle={"Updated 36mins ago"}
          />
          <BalanceCard
            title={"Total Withdrawal"}
            figure={"0.00"}
            subtitle={"Updated 36mins ago"}
          />
        </Flex>

        <Flex justify={"center"} gap={3} mt={3}>
          <WithdrawWalletModal />
          <FundWalletModal />
        </Flex>

        <Box mt={"32px"}>
          <Flex justify={"space-between"}>
            <Heading fontSize={"20px"} fontWeight={400}>
              Recent Transactions
            </Heading>
            <Link to={"/history"}>
              <Text display={"flex"} alignItems={"center"} gap={"5px"}>
                View all <IoChevronForward fontSize={"20px"} />
              </Text>
            </Link>
          </Flex>
          <Box marginTop={"10px"}>
            <Loader isLoading={isTransactionHistoryLoading} height={"100px"} />
            {!transactionHistory?.data?.length &&
            !isTransactionHistoryLoading ? (
              <Text color={"brand.textMuted"} textAlign={"center"} mt={"40px"}>
                You have not performed any transactions yet.
              </Text>
            ) : (
              ""
            )}
            {transactionHistory?.data?.map((value: any) => {
              let checkoutUrl = "";

              if (value?.currency === "ngn") {
                checkoutUrl = value?.meta?.data?.authorization_url;
              } else {
                if (value?.method === "credit_card") {
                  checkoutUrl = value?.meta?.url;
                } else if (value?.method === "crypto") {
                  checkoutUrl = value?.meta?.hosted_url;
                }
              }

              return (
                <TransactionHistory
                  key={"tnx-" + value?.id}
                  txnId={value?.id}
                  type={value?.type}
                  amount={value?.amount}
                  currency={value?.currency}
                  date={value?.createdAt?._seconds}
                  status={value?.status}
                  checkoutUrl={checkoutUrl}
                  trnx={value}
                />
              );
            })}
          </Box>
        </Box>
      </Box>
    </LayoutContainerWrapper>
  );
};

const Aside = () => {
  return (
    <>
      <Beneficiaries />
      <ContactCard />
    </>
  );
};

export default Wallet;

import React, {useState} from "react";
import {
    Button,
    Flex,
    Heading,
    HStack, Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalFooter,
    ModalHeader,
    ModalOverlay, PinInput, PinInputField
} from "@chakra-ui/react";

const WalletPinModal = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading = false,
  label = "Enter Wallet Pin",
  subLabel,
}: {
  isOpen: boolean;
  isLoading?: boolean;
  label?: string;
  subLabel?: string;
  onClose: () => void;
  onSubmit: (pin: string, resetPin: () => void) => void;
}) => {
  const [pin, setPin] = useState("");
  const resetPin = () => {
    setPin("");
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={{ base: "xs", md: "sm" }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={"1.17rem"}>{label}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {subLabel ? (
            <Heading
              fontSize={"14px"}
              textAlign={"center"}
              color={"gray.500"}
              mb={5}
              mt={1}
              w={"full"}
              fontWeight={"semibold"}
            >
              {subLabel}
            </Heading>
          ) : (
            ""
          )}
          <Flex justifyContent={"center"} gap={5}>
            <HStack>
              <PinInput value={pin} onChange={setPin} mask>
                <PinInputField
                  bg={"#E8E8E8"}
                  _focus={{ borderColor: "gray.100" }}
                />
                <PinInputField
                  bg={"#E8E8E8"}
                  _focus={{ borderColor: "gray.100" }}
                />
                <PinInputField
                  bg={"#E8E8E8"}
                  _focus={{ borderColor: "gray.100" }}
                />
                <PinInputField
                  bg={"#E8E8E8"}
                  _focus={{ borderColor: "gray.100" }}
                />
                <PinInputField
                  bg={"#E8E8E8"}
                  _focus={{ borderColor: "gray.100" }}
                />
                <PinInputField
                  bg={"#E8E8E8"}
                  _focus={{ borderColor: "gray.100" }}
                />
              </PinInput>
            </HStack>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button
            size={"lg"}
            fontSize={"16px"}
            fontWeight={400}
            colorScheme={"buttonPrimary"}
            isLoading={isLoading}
            w={"full"}
            onClick={() => onSubmit(pin, resetPin)}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WalletPinModal;
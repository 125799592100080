import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightAddon,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Link } from "react-router-dom";
import {
  useFetchBankBeneficiariesQuery,
  useFetchCryptoBeneficiariesQuery,
} from "../../redux/services/beneficiariesApi";
import Loader from "../../utils/Loader";
import BankCard from "./BankCard";
import { useFetchProfileQuery } from "../../redux/services/accountApi";
import { IoChevronDownOutline } from "react-icons/io5";
import useTextTruncate from "../../hooks/useTextTruncate";
import {
  useCheckWalletPinMutation,
  useFetchSupportedCurrenciesQuery,
  useRequestWithdrawalMutation,
} from "../../redux/services/walletApi";
import { showError, showSuccess } from "../../utils/Alert";
import {
  useRequestOtpMutation,
  useValidateEmailMFAMutation,
  useVerifyTFAMutation,
} from "../../redux/services/twoFAApi";
import WalletPinModal from "../settings/WalletPinModal";
import Shield from "../../assets/images/shield-keyhole-fill.svg";

const Beneficiaries: React.FC = () => {
  const { formatMoney } = useTextTruncate();
  const [activeTab, setActiveTab] = useState("bank");
  const [supportedCurrencies, setSupportedCurrencies] = useState<any[]>([]);
  const [quickWithdrawForm, setQuickWithdrawForm] = useState({
    title: "",
    method: "",
    currency: "",
    amount: "",
    amountToReceive: 0,
    destination_id: "",
    code: "",
  });
  const { data: bankBeneficiaries, isLoading: isBankBeneLoading } =
    useFetchBankBeneficiariesQuery();
  const { data: cryptoBeneficiaries, isLoading: isCryptoBeneLoading } =
    useFetchCryptoBeneficiariesQuery();

  const {
    data: fetchedSupportedCurrencies,
    isSuccess: isFetchSupportedCurrenciesSuccess,
  } = useFetchSupportedCurrenciesQuery();
  const { data: user } = useFetchProfileQuery();

  const [rate, setRate] = useState({
    rate: 1,
    symbol: "$",
  });

  const hasAnimationRun = useSelector(
    (state: RootState) => state.animationState.hasContactCardAnimationRun
  );

  const {
    isOpen: isQuickWithdrawModalOpen,
    onOpen: onQuickWithdrawModalOpen,
    onClose: onQuickWithdrawModalClose,
  } = useDisclosure();
  const {
    isOpen: isNo2FAModalOpen,
    onOpen: onNo2FAModalOpen,
    onClose: onNo2FAModalClose,
  } = useDisclosure();
  const {
    isOpen: isWalletPinModalOpen,
    onOpen: onWalletPinModalOpen,
    onClose: onWalletPinModalClose,
  } = useDisclosure();
  const {
    isOpen: isTFAModalOpen,
    onOpen: onTFAModalOpen,
    onClose: onTFAModalClose,
  } = useDisclosure();

  const isEmail2faActive = user?.user?.security?.email;
  const isAuthApp2faActive = user?.user?.security["2fa"]
    ? user?.user?.security["2fa"]?.status === "verified"
    : false;

  //   process fetched Supp. currencies
  useEffect(() => {
    if (isFetchSupportedCurrenciesSuccess) {
      const newArray = fetchedSupportedCurrencies?.map((cur: any) => ({
        ...cur,
        title: cur.name,
        value: cur.code,
        name: cur.code.toUpperCase(),
      }));

      setSupportedCurrencies(newArray);
    }
  }, [isFetchSupportedCurrenciesSuccess, fetchedSupportedCurrencies]);

  // set rate on currency change
  useEffect(() => {
    if (quickWithdrawForm.currency === "ngn") {
      let getRate = supportedCurrencies.filter((cur) => cur.code === "usd");
      setRate({
        rate: getRate.length ? getRate[0]?.rates?.usd?.rate : 0,
        symbol: getRate.length ? getRate[0].symbol : "?",
      });
    } else if (quickWithdrawForm.currency === "usd") {
      setRate({
        rate: 1,
        symbol: "$",
      });
    }
  }, [quickWithdrawForm.currency, supportedCurrencies]);

  // calc amtToRec
  const handleAmountChange = (val: string | number) => {
    setQuickWithdrawForm((prevState) => ({
      ...prevState,
      amount: val?.toString(),
    }));

    let amountToReceive = Number(val) * Number(rate.rate);
    amountToReceive = parseFloat(amountToReceive.toFixed(2));
    setQuickWithdrawForm((prevState) => ({ ...prevState, amountToReceive }));
  };

  const openQuickWithdrawModal = (beneficiary: any) => {
    if (isEmail2faActive || isAuthApp2faActive) {
      setQuickWithdrawForm({
        ...quickWithdrawForm,
        destination_id: beneficiary?.id,
        title: beneficiary?.title || beneficiary?.account_name,
        method: beneficiary?.coin_id ? "crypto" : "bank_transfer",
        currency: beneficiary?.currency || "usd",
      });

      onQuickWithdrawModalOpen();
    } else {
      onNo2FAModalOpen();
    }
  };

  const closeQuickWithdrawModal = () => {
    setQuickWithdrawForm({
      ...quickWithdrawForm,
      title: "",
      method: "",
      currency: "",
      amount: "",
      amountToReceive: 0,
      destination_id: "",
      code: "",
    });

    onQuickWithdrawModalClose();
    onTFAModalClose();
    onWalletPinModalClose();
  };

  const requestWalletPin = () => {
    if (quickWithdrawForm.amount === "") {
      showError("Enter amount");
      return;
    }

    if (quickWithdrawForm.destination_id === "") {
      showError("Sorry, could not validate beneficiary");
      return;
    }

    onWalletPinModalOpen();
  };

  const [checkWalletPin, { isLoading: isCheckWalletPinLoading }] =
    useCheckWalletPinMutation();
  const [validateEmailOtp, { isLoading: isValidateEmailOtp }] =
    useValidateEmailMFAMutation();
  const [validateTFAOtp, { isLoading: isValidateTFAOtp }] =
    useVerifyTFAMutation();
  const [requestOtp, { isLoading: isRequestOtpLoading }] =
    useRequestOtpMutation();
  const [reqWithdraw, { isLoading: isReqWithdrawLoading }] =
    useRequestWithdrawalMutation();

  const validateWalletPin = async (pin: string, resetPin: () => void) => {
    if (pin?.length < 6) {
      showError("Provide wallet pin");
      return;
    }

    await checkWalletPin({ pin })
      .unwrap()
      .then(async (resp) => {
        if (resp?.success) {
          if (isAuthApp2faActive) {
            onWalletPinModalClose();
            onTFAModalOpen();
            resetPin();
          } else {
            requestOtp()
              .unwrap()
              .then((resp) => {
                if (resp?.success) {
                  onWalletPinModalClose();
                  onTFAModalOpen();
                  showSuccess("A code has been sent to your email");
                  resetPin();
                }
              })
              .catch((err) => {
                showError(
                  err?.message ||
                    err?.data?.message ||
                    "An error occurred, could not send email code"
                );
              });
          }
        } else {
          showError("check pin failed");
          console.log(resp);
        }
      })
      .catch((err) => {
        console.log(err);
        showError(err?.message || err?.data?.message || "An error occurred");
      });
  };

  const handleSubmit = async () => {
    const { code } = quickWithdrawForm;
    if (code === "") {
      showError("Enter code");
      return;
    }

    let isValidateOtpErr = true;
    if (isAuthApp2faActive) {
      await validateTFAOtp({ code })
        .unwrap()
        .then((resp) => {
          if (resp?.success) {
            isValidateOtpErr = false;
          } else {
            showError("An unknown error occurred");
          }
        })
        .catch((err) => {
          console.log(err);
          showError(
            err?.message || err?.data?.message || "Could not validate code"
          );
        });
    } else if (isEmail2faActive) {
      await validateEmailOtp({ code })
        .unwrap()
        .then((resp) => {
          if (resp?.success) {
            isValidateOtpErr = false;
          } else {
            showError("An unknown error occurred");
          }
        })
        .catch((err) => {
          console.log(err);
          showError(
            err?.message || err?.data?.message || "Could not validate code"
          );
        });
    }

    if (!isValidateOtpErr) {
      await reqWithdraw(quickWithdrawForm)
        .unwrap()
        .then((resp) => {
          console.log(resp);
          showSuccess("Withdrawal request sent");

          closeQuickWithdrawModal();
        })
        .catch((err) => {
          console.log(err);
          showError(
            err?.message ||
              err?.data?.message ||
              "An error occurred, try again later"
          );
        });
    }
  };

  const renderElem = () => {
    if (activeTab === "crypto") {
      return (
        <>
          {!cryptoBeneficiaries?.length && !isCryptoBeneLoading ? (
            <Text fontSize={"15px"} textAlign={"center"} my={"50px"}>
              You have not added any crypto beneficiary yet.
            </Text>
          ) : (
            ""
          )}
          <Loader isLoading={isCryptoBeneLoading} height="100px" mt={0} />
          {cryptoBeneficiaries?.slice(0, 2)?.map((value: any) => (
            <BankCard
              key={value?.id}
              beneficiary={value}
              type={"crypto"}
              onClick={() => openQuickWithdrawModal(value)}
            />
          ))}
        </>
      );
    } else {
      return (
        <>
          {!bankBeneficiaries?.length && !isBankBeneLoading ? (
            <Text fontSize={"15px"} textAlign={"center"} my={"50px"}>
              You have not added any bank transfer beneficiary yet.
            </Text>
          ) : (
            ""
          )}
          <Loader isLoading={isBankBeneLoading} height="100px" mt={0} />
          {bankBeneficiaries?.slice(0, 2)?.map((value) => (
            <BankCard
              key={value?.id}
              beneficiary={value}
              type={"bank"}
              onClick={() => openQuickWithdrawModal(value)}
            />
          ))}
        </>
      );
    }
  };

  return (
    <Box>
      <Heading
        fontSize={"17px"}
        fontWeight={500}
        mb={"12px"}
        data-aos={!hasAnimationRun ? "fade-up" : ""}
        data-aos-delay="50"
      >
        Recent Beneficiaries
      </Heading>

      <Box display="flex" flexDirection={"column"} gap={5}>
        <Box
          background={"#E8E8E8"}
          display={"flex"}
          gap={2}
          rounded={"30px"}
          py={"1"}
          px={"1"}
        >
          <Button
            w={{ sm: "200px", lg: "150px", xl: "150px", base: "200px" }}
            rounded={"20px"}
            py={"1"}
            px={"1"}
            color={activeTab === "bank" ? "white" : "#232A44"}
            bg={activeTab === "bank" ? "#232A44" : "#f0f0f0"}
            onClick={() => setActiveTab("bank")}
            fontSize={"13px"}
            _hover={{ bg: activeTab === "bank" ? "#232A44" : "#f0f0f0" }}
            fontWeight={400}
          >
            Bank Transfers
          </Button>

          <Button
            w={{ sm: "200px", lg: "150px", xl: "150px", base: "200px" }}
            rounded={"20px"}
            py={"1"}
            px={"1"}
            color={activeTab === "crypto" ? "white" : "#232A44"}
            bg={activeTab === "crypto" ? "#232A44" : "#f0f0f0"}
            onClick={() => setActiveTab("crypto")}
            _hover={{ bg: activeTab === "crypto" ? "#232A44" : "#f0f0f0" }}
            fontSize={"13px"}
            fontWeight={400}
          >
            Crypto
          </Button>
        </Box>

        <Box>{renderElem()}</Box>

        <Box mb={5}>
          <Link to="/beneficiaries">
            <Button w={"100%"} variant={"outline"} _hover={{ bg: "#F0F0F0" }}>
              Manage beneficiaries
            </Button>
          </Link>
        </Box>
      </Box>

      <Modal
        isOpen={isQuickWithdrawModalOpen}
        onClose={closeQuickWithdrawModal}
        isCentered
        size={{ base: "xs", md: "lg" }}
      >
        <ModalOverlay />
        <ModalContent rounded={"16px"}>
          <ModalHeader>Withdraw</ModalHeader>
          <ModalCloseButton color={"brand.dangerDark"} />
          <ModalBody>
            <Box>
              <FormControl display={"flex"} flexDirection={"column"} gap={5}>
                <Box>
                  <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
                    Beneficiary
                  </FormLabel>
                  <Input
                    focusBorderColor={"gray.300"}
                    textTransform="capitalize"
                    value={quickWithdrawForm.title}
                    bg={"#FAFAFA"}
                    readOnly
                  />
                </Box>

                <Box>
                  <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
                    Method
                  </FormLabel>
                  <InputGroup>
                    <Input
                      focusBorderColor={"gray.300"}
                      textTransform="capitalize"
                      value={quickWithdrawForm.method?.replace(/_/g, " ")}
                      bg={"#FAFAFA"}
                      readOnly
                    />
                    <InputRightElement>
                      <IoChevronDownOutline />
                    </InputRightElement>
                  </InputGroup>
                </Box>

                <Box>
                  <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
                    Currency
                  </FormLabel>
                  <InputGroup>
                    <Input
                      focusBorderColor={"gray.300"}
                      value={quickWithdrawForm.currency?.toUpperCase()}
                      bg={"#FAFAFA"}
                      readOnly
                    />
                    <InputRightElement>
                      <IoChevronDownOutline />
                    </InputRightElement>
                  </InputGroup>
                </Box>

                <Box>
                  <FormLabel fontWeight={"semibold"}>
                    How much would you like to withdraw?
                  </FormLabel>

                  <InputGroup>
                    <NumberInput
                      w="100%"
                      focusBorderColor={"gray.300"}
                      value={quickWithdrawForm.amount}
                      onChange={(value) => handleAmountChange(value)}
                    >
                      <NumberInputField
                        placeholder="0"
                        borderRightRadius="0"
                        borderRight={"0"}
                      />
                    </NumberInput>

                    <InputRightAddon>USD</InputRightAddon>
                  </InputGroup>

                  <Text fontSize={"13px"}>
                    Rates: <b>$1 = {rate.symbol + formatMoney(rate.rate)}</b>
                  </Text>
                </Box>

                <Box>
                  <FormLabel fontWeight={"semibold"}>
                    Amount to receive
                  </FormLabel>

                  <InputGroup>
                    <NumberInput
                      w="100%"
                      min={0}
                      focusBorderColor={"gray.300"}
                      pointerEvents={"none"}
                      value={quickWithdrawForm.amountToReceive}
                    >
                      <NumberInputField
                        borderRightRadius="0"
                        borderRight={"0"}
                      />
                    </NumberInput>

                    <InputRightAddon textTransform={"uppercase"}>
                      {quickWithdrawForm.currency}
                    </InputRightAddon>
                  </InputGroup>
                </Box>
              </FormControl>
            </Box>
          </ModalBody>
          <ModalFooter>
            <Button
              size={"lg"}
              fontSize={"16px"}
              fontWeight={400}
              width={"full"}
              colorScheme={"buttonPrimary"}
              onClick={requestWalletPin}
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* wallet pin modal */}
      <WalletPinModal
        isOpen={isWalletPinModalOpen}
        onClose={onWalletPinModalClose}
        onSubmit={validateWalletPin}
        isLoading={isCheckWalletPinLoading || isRequestOtpLoading}
      />

      {/* email/2fa code modal */}
      <Modal isOpen={isTFAModalOpen} onClose={onTFAModalClose} isCentered>
        <ModalOverlay />
        <ModalContent rounded={"16px"}>
          <ModalHeader>Enter Security Code</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Loader isLoading={isRequestOtpLoading} height={"100px"} />
            {!isRequestOtpLoading && (
              <FormControl>
                <FormLabel fontSize={"12px"}>
                  {isAuthApp2faActive
                    ? "To confirm this request, please enter the security code from your Authenticator App"
                    : "To confirm this request, please enter the security code we emailed to you"}
                </FormLabel>
                <Input
                  placeholder={"Enter the code to verify"}
                  focusBorderColor={"gray.300"}
                  value={quickWithdrawForm.code}
                  onChange={(e) =>
                    setQuickWithdrawForm({
                      ...quickWithdrawForm,
                      code: e.target.value,
                    })
                  }
                />
              </FormControl>
            )}
          </ModalBody>
          <ModalFooter justifyContent={"space-between"} pb={"18px"}>
            <Button
              colorScheme={"buttonPrimary"}
              width={"full"}
              fontWeight={400}
              onClick={handleSubmit}
              isLoading={
                isReqWithdrawLoading || isValidateEmailOtp || isValidateTFAOtp
              }
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* no2fa modal */}
      <Modal isOpen={isNo2FAModalOpen} onClose={onNo2FAModalClose} isCentered>
        <ModalOverlay />
        <ModalContent rounded={"16px"}>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justify={"center"}>
              <img src={Shield} width={"50%"} alt="" />
            </Flex>
            <Heading
              as={"h3"}
              fontSize={["20px", "22px"]}
              textAlign={"center"}
              fontWeight={700}
              my={2}
            >
              Two-Factor Authentication not enabled
            </Heading>
            <Text
              fontSize={["14px", "16px"]}
              textAlign={"center"}
              fontWeight={500}
            >
              Enable Two-Factor Authentication to withdraw
            </Text>
          </ModalBody>
          <ModalFooter justifyContent={"space-between"} pb={"18px"}>
            <Button
              colorScheme={"buttonLight"}
              color={"root.black"}
              width={"full"}
              fontWeight={400}
              as={Link}
              to={"/dashboard/settings/2fa"}
            >
              Enable 2FA
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Beneficiaries;

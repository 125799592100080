import React from "react";
import {
  Flex,
  Heading,
  Box,
  Text,
  useDisclosure,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalFooter,
  Icon,
} from "@chakra-ui/react";
import BankIcon from "../../assets/images/icons/bank.png";
import { BsTrash3 } from "react-icons/bs";
import { BankBeneficiaryObj } from "../../redux/services/types";
import {
  useDeleteBankBeneficiaryMutation,
  useFetchSupportedBanksQuery,
} from "../../redux/services/beneficiariesApi";
import WasteCollection from "../../assets/images/wastecollection.png";
import { showError, showSuccess } from "../../utils/Alert";
import BankBeneficiaryForm from "./BankBeneficiaryForm";

const BankBeneficiaryRow = ({
  beneficiary,
}: {
  beneficiary: BankBeneficiaryObj;
}) => {
  const { data: supportedBanks } = useFetchSupportedBanksQuery();
  const [deleteBeneficiaryMutation, { isLoading: isDeleteBeneLoading }] =
    useDeleteBankBeneficiaryMutation();
    const {
      isOpen: isUpdateModalOpen,
      onOpen: onUpdateModalOpen,
      onClose: onUpdateModalClose,
    } = useDisclosure();
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose,
  } = useDisclosure();

  const deleteBeneficiary = () => {
    deleteBeneficiaryMutation(beneficiary?.id)
      .unwrap()
      .then(() => {
        showSuccess("Beneficiary deleted");
        onDeleteModalClose();
      })
      .catch((err) => {
        console.log(err);
        showError(err?.message || err?.data?.message || "An error occurred");
      });
  };

  const returnBankName = (bank_code: string) => {
    const bank = supportedBanks?.filter((val) => val?.code === bank_code);
    if (bank?.length) {
      return bank[0]?.name;
    } else {
      return null;
    }
  };

  return (
    <>
      <Flex
        justifyContent={"space-between"}
        alignItems={"center"}
        cursor="pointer"
        mb={5}
      >
        <Flex alignItems={"center"} gap={5} cursor={"pointer"}
          width={"calc(100% - 15px)"}
          onClick={onUpdateModalOpen}>
          <Box backgroundColor={"#F3F3F3"} p={3} rounded={"full"}>
            <img
              src={BankIcon}
              width={"20px"}
              height={"20px"}
              style={{ objectFit: "cover" }}
              alt="bank"
            />
          </Box>
          <Box>
            <Heading as={"h6"} size={"sm"} textTransform={"capitalize"}>
              {beneficiary?.title || beneficiary?.account_name}
            </Heading>
            <Text>
              <small>
                {returnBankName(beneficiary?.bank_code) || "Account number"}:
              </small>{" "}
              <b>{beneficiary?.account_number}</b>
            </Text>
          </Box>
        </Flex>
        <Icon
          as={BsTrash3}
          fontSize={"20px"}
          color={"brand.dangerDark"}
          onClick={onDeleteModalOpen}
        />
      </Flex>

      <Modal
        isOpen={isUpdateModalOpen}
        onClose={onUpdateModalClose}
        isCentered
        size={{ base: "xs", md: "lg" }}
        scrollBehavior={"inside"}
      >
        <ModalOverlay />
        <ModalContent maxH={["80vh", "95vh"]}>
          <ModalCloseButton />
          <ModalHeader>Edit Bank Beneficiary</ModalHeader>

          <ModalBody>
            <BankBeneficiaryForm
              beneficiaryToUpdate={beneficiary}
              closeModal={onUpdateModalClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isDeleteModalOpen} onClose={onDeleteModalClose} isCentered>
        <ModalOverlay />
        <ModalContent rounded={"16px"}>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justify={"center"}>
              <img src={WasteCollection} width={"60%"} alt="" />
            </Flex>
            <Heading
              as={"h3"}
              fontSize={["20px", "27px"]}
              textAlign={"center"}
              fontWeight={700}
            >
              Delete Beneficiary
            </Heading>
            <Text
              fontSize={["14px", "17px"]}
              textAlign={"center"}
              fontWeight={500}
            >
              Are you sure you want to delete this beneficiary?
            </Text>
          </ModalBody>
          <ModalFooter justifyContent={"space-between"} gap={4}>
            <Button
              colorScheme={"buttonLight"}
              color={"root.black"}
              width={"50%"}
            >
              Don't delete
            </Button>
            <Button
              variant={"ghostDanger"}
              width={"50%"}
              isLoading={isDeleteBeneLoading}
              onClick={deleteBeneficiary}
            >
              Yes, delete
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BankBeneficiaryRow;

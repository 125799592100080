import React from "react";
import { Box, Heading } from "@chakra-ui/react";
import { Link } from "react-router-dom";

const Profile = () => {
  return (
    <>
      <Heading
        size={"sm"}
        fontSize={"14px"}
        color={"#868686"}
        fontWeight={600}
        textAlign={"left"}
      >
        Profile
      </Heading>
      <Box display={"flex"} flexDirection={"column"} gap={5}>
        <Link to={"/settings/edit-profile"}>
          <Box>Edit Profile</Box>
        </Link>
        {/* <Link to={"/settings/kyc"}>
            <Box>
              <Flex alignItems={"center"} justifyContent={"space-between"}>
                <Text>KYC Verification</Text>
                <Text
                  bg={"#F3F3F3"}
                  py={"5px"}
                  px={"7px"}
                  rounded={"full"}
                  fontSize={"12px"}
                  mr={{ base: "10px", md: "0" }}
                >
                  Not Verified
                </Text>
              </Flex>
            </Box>
          </Link> */}
        <Link to={"/beneficiaries"}>
          <Box>Manage Beneficiaries</Box>
        </Link>
        <Link to={"/settings/affiliate"}>
          <Box>Affiliate and Referrals</Box>
        </Link>
      </Box>
    </>
  );
};

export default Profile;
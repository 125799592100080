import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Select,
  Textarea,
  Text,
} from "@chakra-ui/react";
import {
  useAddBankBeneficiaryMutation,
  useFetchSupportedBanksQuery,
  useUpdateBankBeneficiaryMutation,
  useValidateAccountDetailMutation,
} from "../../redux/services/beneficiariesApi";
import { showError, showSuccess } from "../../utils/Alert";
import { ChangeEvent } from "react";
import {
  useCheckWalletPinMutation,
  useFetchSupportedCurrenciesQuery,
} from "../../redux/services/walletApi";
import WalletPinModal from "../settings/WalletPinModal";
import { BankBeneficiaryObj } from "../../redux/services/types";

const BankBeneficiaryForm = ({
  closeModal,
  beneficiaryToUpdate = null,
}: {
  beneficiaryToUpdate?: BankBeneficiaryObj | null;
  closeModal: () => void;
}) => {
  const [supportedCurrencies, setSupportedCurrencies] = useState([]);
  const [supportedBanks, setSupportedBanks] = useState<any[]>([]);
  const { data: fetchedSuppBanks, isSuccess: isFetchedSuppBanksSuccess } =
    useFetchSupportedBanksQuery();
  const {
    data: fetchedSuppCurrencies,
    isSuccess: isFetchedSuppCurrenciesSuccess,
  } = useFetchSupportedCurrenciesQuery();
  const [updateBankBeneficiary, { isLoading: isUpdateBankBenLoading }] =
    useUpdateBankBeneficiaryMutation();
  const [addBankBeneficiary, { isLoading: isAddBankBeneficiaryLoading }] =
    useAddBankBeneficiaryMutation();
  const [validateAcctDetail, { isLoading: isValidateAcctLoading }] =
    useValidateAccountDetailMutation();
  const [showWalletPinModal, setShowWalletPinModal] = useState(false);
  const [finalFormState, setFinalFormState] = useState({});
  const [formState, setFormState] = useState({
    title: "",
    currency: "usd",
    bank_name: "",
    bank_code: "",
    account_name: "",
    account_number: "",
    account_type: "",
    sort_code: "",
    routing_number: "",
    description: "",
  });

  const accountTypes = [
    <option key={"at-01"}>Select</option>,
    <option key={"at-02"}>Personal Account</option>,
    <option key={"at-03"}>Business Account</option>,
  ];

  useEffect(() => {
    if (beneficiaryToUpdate) {
      if (beneficiaryToUpdate?.currency === "ngn") {
        setFormState((prevState) => ({
          ...prevState,
          currency: beneficiaryToUpdate?.currency || "ngn",
          title: beneficiaryToUpdate?.title || "",
          // bank_name: beneficiaryToUpdate?.bank_name || "",
          bank_code: beneficiaryToUpdate?.bank_code || "",
          account_name: beneficiaryToUpdate?.account_name || "",
          account_number: beneficiaryToUpdate?.account_number || "",
          description: beneficiaryToUpdate?.description || "",
        }));
      } else {
        setFormState((prevState) => ({
          ...prevState,
          currency: beneficiaryToUpdate?.currency || "usd",
          title: beneficiaryToUpdate?.title || "",
          // bank_name: beneficiaryToUpdate?.bank_name || "",
          bank_code: beneficiaryToUpdate?.bank_code || "",
          account_name: beneficiaryToUpdate?.account_name || "",
          account_number: beneficiaryToUpdate?.account_number || "",
          account_type: beneficiaryToUpdate?.account_type || "",
          sort_code: beneficiaryToUpdate?.sort_code || "",
          routing_number: beneficiaryToUpdate?.routing_number || "",
          description: beneficiaryToUpdate?.description || "",
        }));
      }
    } else {
      setFormState((prevState) => ({
        ...prevState,
        title: "",
        currency: "usd",
        bank_name: "",
        bank_code: "",
        account_name: "",
        account_number: "",
        account_type: "",
        sort_code: "",
        routing_number: "",
        description: "",
      }));
    }
  }, [beneficiaryToUpdate]);

  //   process fetched supported banks
  useEffect(() => {
    if (isFetchedSuppBanksSuccess) {
      const newArray = fetchedSuppBanks?.map((bank: any) => ({
        ...bank,
        value: bank?.code,
      }));

      setSupportedBanks(newArray);
    }
  }, [isFetchedSuppBanksSuccess, fetchedSuppBanks]);

  //   process fetched supported currencies
  useEffect(() => {
    if (isFetchedSuppCurrenciesSuccess) {
      const newArray = fetchedSuppCurrencies.map((cur: any) => ({
        ...cur,
        title: cur?.name,
        value: cur?.code,
        name: cur?.code.toUpperCase(),
      }));

      setSupportedCurrencies(newArray);
    }
  }, [isFetchedSuppCurrenciesSuccess, fetchedSuppCurrencies]);

  const handleOnChange = (val: string, name: string) => {
    setFormState({ ...formState, [name]: val });
  };

  const handleInputs = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    setFormState({ ...formState, [name]: value });
  };

  // validate acct
  useEffect(() => {
    if (formState.currency === "ngn") {
      if (formState.account_number.length === 10 && formState.bank_code) {
        // const { account_number, bank_code } = formState;
        validateAcctDetail({
          account_number: formState.account_number,
          bank_code: formState.bank_code,
        })
          .unwrap()
          .then((resp) => {
            if (resp?.data?.status) {
              const acName = resp?.data?.data?.account_name;
              if (acName) {
                setFormState((state) => ({ ...state, account_name: acName }));
              }
            }
          })
          .catch((err) => {
            showError("Could not validate account details");
            console.log(err);
            setFormState((state) => ({ ...state, account_name: "" }));
          });
      }
    }
  }, [
    formState.currency,
    formState.account_number,
    formState.bank_code,
    validateAcctDetail,
  ]);

  const openWalletPinModal = () => {
    const {
      title,
      currency,
      account_number,
      account_name,
      bank_code,
      routing_number,
      sort_code,
      account_type,
      description,
    } = formState;

    if (formState.currency === "ngn") {
      if (title === "" || account_number === "" || bank_code === "") {
        showError("Required fields are missing");
        return;
      }

      if (account_name === "") {
        showError("Could not verify account number");
        return;
      }

      const selectedBank = supportedBanks.filter(
        (val) => val?.code === formState.bank_code
      );
      let bank_id;
      if (selectedBank.length) {
        bank_id = selectedBank[0].id;
      } else {
        showError("Invalid bank selected");
        return;
      }
      //   console.log(selectedBank);
      //   console.log(supportedBanks);

      setFinalFormState({
        title,
        currency,
        account_number,
        account_name,
        bank_code,
        bank_id,
        description,
      });
    } else {
      if (
        title === "" ||
        routing_number === "" ||
        sort_code === "" ||
        account_number === "" ||
        account_name === "" ||
        account_type === ""
      ) {
        showError("Required fields are missing");
        return;
      }

      setFinalFormState({
        title,
        currency,
        account_number,
        account_name,
        routing_number,
        sort_code,
        account_type,
        description,
      });
    }

    if (beneficiaryToUpdate) {
      handleSubmit();
    } else {
      setShowWalletPinModal(true);
    }
  };

  const [checkWalletPin, { isLoading: isCheckWalletPinLoading }] =
    useCheckWalletPinMutation();

  const handleSubmit = async (pin?: string, resetPin?: () => void) => {
    if (!beneficiaryToUpdate) {
      if (!pin || pin.length < 6) {
        showError("Please enter wallet pin");
        return;
      }
    }

    if (beneficiaryToUpdate) {
      updateBankBeneficiary({
        id: beneficiaryToUpdate?.id,
        data: finalFormState,
      })
        .unwrap()
        .then(() => {
          showSuccess("Beneficiary updated");
          setFormState({
            ...formState,
            title: "",
            currency: "",
            bank_name: "",
            bank_code: "",
            account_name: "",
            account_number: "",
            account_type: "",
            sort_code: "",
            routing_number: "",
            description: "",
          });
          setFinalFormState({});
          closeModal();
        })
        .catch((err) => {
          console.log(err);
          showError(err?.message || err?.data?.message || "An error occurred");
        });
    } else {
      let isWalletPinErr = true;
      await checkWalletPin({ pin })
        .unwrap()
        .then(async (resp) => {
          if (resp?.success) {
            // resetPin()
            isWalletPinErr = false;
          } else {
            showError("check pin failed");
            console.log(resp);
          }
        })
        .catch((err) => {
          console.log(err);
          showError(err?.message || err?.data?.message || "An error occurred");
        });

      if (!isWalletPinErr) {
        addBankBeneficiary({
          ...finalFormState,
        })
          .unwrap()
          .then(() => {
            showSuccess("Beneficiary added");
            setFormState({
              ...formState,
              title: "",
              currency: "",
              bank_name: "",
              bank_code: "",
              account_name: "",
              account_number: "",
              account_type: "",
              sort_code: "",
              routing_number: "",
              description: "",
            });
            setFinalFormState({});
            resetPin?.();
            setShowWalletPinModal(false);
            closeModal();
          })
          .catch((err) => {
            console.log(err);
            resetPin?.();
            showError(
              err?.message || err?.data?.message || "An error occurred"
            );
          });
      }
    }
  };

  return (
    <Flex flexDirection={"column"} gap={5}>
      <Box>
        <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
          Title
        </FormLabel>
        <Input
          type="text"
          focusBorderColor={"gray.300"}
          value={formState.title}
          name="title"
          onChange={handleInputs}
        />
      </Box>

      <Box>
        <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
          Currency
        </FormLabel>
        <Select
          focusBorderColor={"gray.300"}
          value={formState.currency}
          name="currency"
          onChange={handleInputs}
          isDisabled={beneficiaryToUpdate ? true : false}
        >
          {supportedCurrencies?.map((cur: any, idx) => (
            <option key={`scur-${idx}`} value={cur?.value}>
              {cur?.name}
            </option>
          ))}
        </Select>

        {formState.currency === "usd" && (
          <>
            <FormControl
              display={"flex"}
              flexDirection={"column"}
              gap={5}
              mt={5}
            >
              <Box>
                <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
                  Account Type
                </FormLabel>
                <Select
                  focusBorderColor={"gray.300"}
                  value={formState.account_type}
                  name="account_type"
                  onChange={handleInputs}
                  isDisabled={beneficiaryToUpdate ? true : false}
                >
                  {accountTypes}
                </Select>
              </Box>

              <Box>
                <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
                  Account Number
                </FormLabel>
                <NumberInput
                  focusBorderColor={"gray.300"}
                  value={formState.account_number}
                  onChange={(val) => handleOnChange(val, "account_number")}
                  isDisabled={beneficiaryToUpdate ? true : false}
                >
                  <NumberInputField />
                </NumberInput>
              </Box>

              <Box>
                <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
                  Recipient Name
                </FormLabel>
                <Input
                  focusBorderColor={"gray.300"}
                  type="text"
                  value={formState.account_name}
                  name="account_name"
                  onChange={handleInputs}
                  isDisabled={beneficiaryToUpdate ? true : false}
                />
              </Box>

              <Box>
                <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
                  Sort Code
                </FormLabel>
                <Input
                  focusBorderColor={"gray.300"}
                  type="text"
                  value={formState.sort_code}
                  name="sort_code"
                  onChange={handleInputs}
                  isDisabled={beneficiaryToUpdate ? true : false}
                />
              </Box>

              <Box>
                <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
                  Routing Number
                </FormLabel>
                <NumberInput
                  min={0}
                  focusBorderColor={"gray.300"}
                  value={formState.routing_number}
                  onChange={(val) => handleOnChange(val, "routing_number")}
                  isDisabled={beneficiaryToUpdate ? true : false}
                >
                  <NumberInputField />
                </NumberInput>
              </Box>

              <Box>
                <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
                  Description
                </FormLabel>
                <Textarea
                  placeholder={"Optional"}
                  focusBorderColor={"gray.300"}
                  value={formState.description}
                  name="description"
                  onChange={handleInputs}
                />
              </Box>

              <Button
                colorScheme={"buttonPrimary"}
                onClick={openWalletPinModal}
                isLoading={isUpdateBankBenLoading}
              >
                Save
              </Button>
            </FormControl>
          </>
        )}

        {formState.currency === "ngn" && (
          <>
            <FormControl
              display={"flex"}
              flexDirection={"column"}
              gap={5}
              mt={5}
            >
              <Box>
                <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
                  Bank Name
                </FormLabel>
                <Select
                  focusBorderColor={"gray.300"}
                  name="bank_code"
                  value={formState.bank_code}
                  onChange={handleInputs}
                  isDisabled={beneficiaryToUpdate ? true : false}
                >
                  <option value="">Select bank</option>
                  {supportedBanks?.map((bank, idx) => (
                    <option key={idx + bank?.code} value={bank?.code}>
                      {bank?.name}
                    </option>
                  ))}
                </Select>
              </Box>

              <Box>
                <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
                  Account Number
                </FormLabel>
                <NumberInput
                  min={0}
                  focusBorderColor={"gray.300"}
                  value={formState.account_number}
                  name="account_number"
                  onChange={(value) => handleOnChange(value, "account_number")}
                  isDisabled={beneficiaryToUpdate ? true : false}
                >
                  <NumberInputField />
                </NumberInput>
              </Box>

              <Box>
                <FormLabel
                  fontSize={"14px"}
                  fontWeight={"semibold"}
                  //   onClick={validateAcct}
                >
                  Account Name
                </FormLabel>
                <Input
                  focusBorderColor={"gray.300"}
                  type="text"
                  value={formState.account_name}
                  isDisabled={beneficiaryToUpdate ? true : false}
                  readOnly
                />
                <Text fontSize={"12px"} color={"#999"} mt={"8px"}>
                  {isValidateAcctLoading ? "Verifying Account..." : ""}
                </Text>
              </Box>

              <Box>
                <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
                  Description
                </FormLabel>
                <Textarea
                  placeholder={"Optional"}
                  focusBorderColor={"gray.300"}
                  value={formState.description}
                  name={"description"}
                  onChange={handleInputs}
                />
              </Box>

              <Button
                colorScheme={"buttonPrimary"}
                onClick={openWalletPinModal}
                isLoading={isUpdateBankBenLoading}
              >
                Save
              </Button>
            </FormControl>
          </>
        )}

        {showWalletPinModal && (
          <WalletPinModal
            isOpen={showWalletPinModal}
            onClose={() => setShowWalletPinModal(false)}
            onSubmit={handleSubmit}
            isLoading={isAddBankBeneficiaryLoading || isCheckWalletPinLoading}
          />
        )}
      </Box>
    </Flex>
  );
};

export default BankBeneficiaryForm;

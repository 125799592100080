import { useToast } from "@chakra-ui/react";

const useAlerts = () => {
  const toast = useToast();

  const showError = (msg: string) => {
    toast({
      position: "top-right",
      title: msg,
      status: "error",
      duration: 5000,
      isClosable: true,
    });
  };

  const showSuccess = (msg: string) => {
    toast({
      position: "top-right",
      title: msg,
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  return { showError, showSuccess };
};

export default useAlerts;

import { showError } from "./Alert";

export const handleFirebaseError = (firebaseError: any) => {
  if (firebaseError.code && firebaseError.message) {
    const errorMessage = firebaseError.message;
    console.log("handleFirebaseError: ", errorMessage);

    // Check if the message starts with the expected prefix
    if (errorMessage.startsWith("Firebase: Error (")) {
      // Extract the part after the prefix
      const startIndex = "Firebase: Error (".length;
      const endIndex = errorMessage.indexOf(")");
      showError(errorMessage.substring(startIndex, endIndex));
    } else {
      showError(errorMessage);
    }
  } else {
    showError("An unexpected error occurred.");
  }
};

const capitalizeFirstText = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeText = (str: string) => {
  if (str === undefined) {
    return "";
  }

  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const checkEmptyFields = (fields: any) => {
  let err = false;
  Object.keys(fields).forEach((key: string) => {
    if (
      fields[key] === "" ||
      fields[key] === undefined ||
      fields[key] === null
    ) {
      showError(capitalizeFirstText(`${key?.replace(/_/g, " ")} is required`));
      err = true;
    }
  });

  return err;
};

export const truncateText = (text: string, maxLength: number) => {
  if (text?.length <= maxLength) {
    return text;
  } else {
    return text?.substring(0, maxLength) + "...";
  }
};

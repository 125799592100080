import NotificationStyles from "../../assets/styles/notification.module.css";
import { Flex, Text, Box } from "@chakra-ui/react";
import AngleRight from "../../assets/images/angle.png";

const TimelineItem = ({
  iconBgColor,
  icon,
  date,
  message,
}: {
  iconBgColor: string;
  icon: string;
  date: Date;
  message: string;
}) => {
  // const isCreditTxn = title === "deposit";

  return (
    <>
      <div className={`${NotificationStyles.container}`}>
        <img src={AngleRight} className={NotificationStyles.arrow} alt="" />
        <div className={`${NotificationStyles.content}`}>
          <Flex alignItems={"center"}>
            <Box
              className={`${NotificationStyles.icon}`}
              style={{ flexShrink: "0" }}
              bg={iconBgColor}
            >
              <img src={icon} style={{ flexShrink: "0" }} alt="icon" />
            </Box>
            <Flex direction={"column"} justify={"center"}>
              <Text
                fontWeight={500}
                textTransform="capitalize"
                className={`${NotificationStyles.title}`}
              >
                {message}
              </Text>
              {/* <Text
                color={"#787A80"}
                className={`${NotificationStyles.subtitle}`}
              >
                {subtitle}
              </Text> */}
            </Flex>
          </Flex>
          {/* {amount ? (
            <Text
              fontWeight={500}
              className={`${NotificationStyles.figure}`}
            >{`${isCreditTxn ? "+" : "-"}$${amount}`}</Text>
          ) : (
            ""
          )} */}
          <Flex
            direction={"column"}
            gap={"4px"}
            align="flex-end"
            fontWeight={500}
            color="#787A80"
            textAlign={"right"}
            className={NotificationStyles.date}
          >
            {/* @ts-ignore */}
            <p>{date}</p>
            {/* <p>10:03</p> */}
          </Flex>
        </div>
      </div>
    </>
  );
};

export default TimelineItem;

import React, {useState} from "react";
import LayoutContainerWrapper from "../../../../components/dashboard/LayoutContainerWrapper";
import ContactCard from "../../../../components/cards/ContactCard";
import {Box, Flex, Heading, Text} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import LeftArrow from "../../../../assets/images/icons/arrow-left.png";
import WalletPinModal from "../../../../components/settings/WalletPinModal";
import { showError, showSuccess } from "../../../../utils/Alert";
import { useUpdateWalletPinMutation } from "../../../../redux/services/walletApi";

const WalletPin = () => {
  const navigate = useNavigate();
  const [isPinModalOpen, setIsPinModalOpen] = useState(false);
  const [formStep, setFormStep] = useState(1);
  const [updatePin, { isLoading: isUpdatePinLoading }] =
    useUpdateWalletPinMutation();
  const [pinFormState, setPinFormState] = useState({
    old_pin: "",
    new_pin: "",
    confirm_new_pin: "",
  });

  const closeUpdatePinModal = () => {
    setPinFormState({
      old_pin: "",
      new_pin: "",
      confirm_new_pin: "",
    });
    setFormStep(1);
    setIsPinModalOpen(false);
  };

  const processUpdatePin = (pin: string, resetPin: () => void) => {
    if (formStep === 1) {
      if (pin.length < 6) {
        showError("Enter old pin");
        return;
      }

      setPinFormState({ ...pinFormState, old_pin: pin });
      // resetPin()
      setFormStep(2);
    } else if (formStep === 2) {
      if (pin.length < 6) {
        showError("Enter new pin");
        return;
      }

      setPinFormState({ ...pinFormState, new_pin: pin });
      // resetPin()
      setFormStep(3);
    } else if (formStep === 3) {
      if (pin.length < 6) {
        showError("Enter confirm pin");
        return;
      }

      if (pin !== pinFormState.new_pin) {
        showError("Confirm pin does not match new pin");
        setPinFormState({ ...pinFormState, new_pin: "", confirm_new_pin: "" });
        setFormStep(2);
        return;
      }

      setPinFormState({ ...pinFormState, confirm_new_pin: pin });
      // resetPin()
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    const { old_pin, new_pin } = pinFormState;

    await updatePin({ old_pin, new_pin, confirm_new_pin: new_pin })
      .unwrap()
      .then(() => {
        showSuccess("Pin updated successfully");
        setPinFormState({
          old_pin: "",
          new_pin: "",
          confirm_new_pin: "",
        });
        setFormStep(1);
        setIsPinModalOpen(false);
      })
      .catch((err) => {
        console.log(err);
        showError(err?.message || err?.data?.message || "An error occurred");
        setFormStep(1);
        setPinFormState({
          old_pin: "",
          new_pin: "",
          confirm_new_pin: "",
        });
      });
  };

  return (
    <LayoutContainerWrapper asideChildren={<Aside />}>
      <Flex alignItems={"center"} mb={"10px"}>
        <img
          src={LeftArrow}
          alt="left direction arrow"
          width={20}
          height={20}
          style={{ cursor: "pointer" }}
          onClick={() => navigate(-1)}
        />
        <Heading
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          m={"auto"}
          fontSize={"21px"}
          fontWeight={"semibold"}
        >
          Wallet Pin
        </Heading>
      </Flex>

      <Text
        fontWeight={"semibold"}
        mt={"30px"}
        onClick={() => setIsPinModalOpen(true)}
        cursor={"pointer"}
      >
        Change Wallet Pin
      </Text>

      <Box my={"20px"}>
        <hr />
      </Box>

      <Text fontWeight={"semibold"}>Forgot Wallet Pin</Text>

      {/* modals */}
      {formStep === 1 && (
        <WalletPinModal
          label="Change Wallet Pin"
          subLabel="Enter Old PIN"
          isOpen={isPinModalOpen}
          onClose={closeUpdatePinModal}
          onSubmit={processUpdatePin}
        />
      )}
      {formStep === 2 && (
        <WalletPinModal
          label="Change Wallet Pin"
          subLabel="Enter New PIN"
          isOpen={isPinModalOpen}
          onClose={closeUpdatePinModal}
          onSubmit={processUpdatePin}
        />
      )}
      {formStep === 3 && (
        <WalletPinModal
          label="Change Wallet Pin"
          subLabel="Confirm New PIN"
          isOpen={isPinModalOpen}
          onClose={closeUpdatePinModal}
          onSubmit={processUpdatePin}
          isLoading={isUpdatePinLoading}
        />
      )}
    </LayoutContainerWrapper>
  );
};

const Aside = () => {
    return (
        <>
            <ContactCard/>
        </>
    );
};

export default WalletPin;
import { Button } from "@chakra-ui/react";
import { confirmPasswordReset } from "firebase/auth";
import React, { useState } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import useAlerts from "../../hooks/useAlerts";
import useCheckEmptyFields from "../../hooks/useCheckEmptyFields";
import CustomInput from "../../utils/CustomInput";
import { handleFirebaseError } from "../../utils/Helpers";
import AuthLayout from "./AuthLayout";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const actionCode = searchParams.get("actionCode");
  const { showError } = useAlerts();
  const [checkEmptyFields] = useCheckEmptyFields();
  const [formState, setFormState] = useState({
    password: "",
    confirmPassword: "",
    loading: false,
  });

  const handleInputs = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormState({ ...formState, [name]: value });
  };

  const submitForm = (e: any) => {
    e.preventDefault();
    const { password, confirmPassword } = formState;

    const isFormEmpty = checkEmptyFields({
      password,
      confirmPassword,
    });

    if (!isFormEmpty) {
      if (password !== confirmPassword) {
        showError("Passwords do not match");
        return;
      }

      setFormState({ ...formState, loading: true });
      confirmPasswordReset(auth, actionCode || "", confirmPassword)
        .then((resp) => {
          console.log("password reset", resp);
          navigate("/reset-done");
          setFormState({ ...formState, loading: false });
        })
        .catch((error) => {
          handleFirebaseError(error);
          setFormState({ ...formState, loading: false });
        });
    }
  };

  return (
    <AuthLayout
      heading="Forgot password"
      subHeading="Your new password should be different from passwords previously used"
    >
      <form onSubmit={submitForm}>
        <CustomInput
          label="Password"
          name="password"
          type="password"
          value={formState.password}
          onChange={handleInputs}
          mb="13px"
        />
        <CustomInput
          label="Confirm Password"
          name="confirmPassword"
          type="password"
          value={formState.confirmPassword}
          onChange={handleInputs}
          mb="13px"
        />
        <Button
          colorScheme={"buttonPrimary"}
          mt={"29px"}
          w={"full"}
          height={"48px"}
          fontWeight={500}
          type="submit"
          rounded="8px"
          isLoading={formState.loading}
        >
          Reset password
        </Button>
        <Button
          variant={"ghost"}
          as={Link}
          to={"/"}
          w={"full"}
          leftIcon={<IoIosArrowRoundBack fontSize={"30px"} />}
          _hover={{ bg: "transparent" }}
          fontWeight={500}
        >
          Back to Sign in
        </Button>
      </form>
    </AuthLayout>
  );
};

export default ResetPassword;

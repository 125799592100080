import { Button } from "@chakra-ui/react";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import AuthLayout from "./AuthLayout";

const ResetLink = () => {
  const location = useLocation();
  const data = location.state;
  // console.log(data?.data);

  const recipientEmail = `mailto:${data?.data?.email}`;

  return (
    <AuthLayout
      heading="Unlock your lucky streak🍀✨"
      subHeading={data?.data?.message || "🟢 Email sent successfully"}
    >
      <Button
        as={Link}
        to={recipientEmail}
        colorScheme={"buttonPrimary"}
        mt={"29px"}
        w={"full"}
        height={"48px"}
        fontWeight={500}
        rounded="8px"
      >
        Go to mail
      </Button>
    </AuthLayout>
  );
};

export default ResetLink;

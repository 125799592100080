import {useEffect} from "react";
import { Outlet, useNavigate } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import SideBar from "../nav/SideBar";
import { useFetchProfileQuery } from "../../redux/services/accountApi";
import FullScreenLoader from "../../utils/FullScreenLoader";
import RoutesAuth from "../../route/RoutesAuth";
import ScrollToTop from "../../utils/ScrollToTop";
import { useDispatch } from "react-redux";
// import { logOutUser } from "../../redux/features/authSlice";

const Layout = () => {
  const {
    data: user,
    isError: isUserError,
    isLoading: isUserLoading,
  } = useFetchProfileQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // redirect user to activate wallet pin if not activated yet || logout if 2FA is active and not verified
  useEffect(() => {
    if (!isUserError && !isUserLoading) {
      // @ts-ignore
      const TFAVerified = JSON.parse(localStorage.getItem("TFAVerified"));
      const isWalletPinActive = user?.user?.security?.wallet_pin;
      const isAuthApp2faActive =
        user?.user?.security && user?.user?.security["2fa"]
          ? user?.user?.security["2fa"]?.status === "verified"
          : false;
      if (!isWalletPinActive) {
        navigate("/activate-wallet-pin");
      } else if (isAuthApp2faActive && !TFAVerified) {
        navigate("/verify-2fa");
        // console.log("2fa active and not set");
        // dispatch(logOutUser());
      }
    }
  }, [isUserError, isUserLoading, user, navigate, dispatch]);

  useEffect(() => {
    AOS.init({
      once: true,
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <>
      {isUserLoading && <FullScreenLoader />}
      <SideBar>
        <div style={{ maxWidth: "1150px", margin: "auto" }}>
          <ScrollToTop>
            <RoutesAuth>
              <Outlet />
            </RoutesAuth>
          </ScrollToTop>
        </div>
      </SideBar>
    </>
  );
};

export default Layout;

import { Box, Flex, Icon, Text, useDisclosure } from "@chakra-ui/react";
import { useEffect } from "react";
import { FaFacebook, FaLinkedinIn } from "react-icons/fa";
import {
  FaSquareInstagram,
  FaTiktok,
  FaXTwitter,
  FaYoutube,
} from "react-icons/fa6";
import { IoLogoWhatsapp } from "react-icons/io";
import { PiChatCenteredDotsLight } from "react-icons/pi";
import { SlArrowRight } from "react-icons/sl";
import { useDispatch, useSelector } from "react-redux";
import { toggleHasContactCardAnimationRun } from "../../redux/features/animationSlice";
import { RootState } from "../../redux/store";
import CustomModal from "../../utils/CustomModal";

const ContactCard = () => {
  const dispatch = useDispatch();
  const hasAnimationRun = useSelector(
    (state: RootState) => state.animationState.hasContactCardAnimationRun
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    dispatch(toggleHasContactCardAnimationRun(true));
  }, [dispatch]);

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={10}
        onClick={onOpen}
        cursor={"pointer"}
      >
        <Box>
          <Text
            fontSize={"14px"}
            fontWeight={500}
            mb={"12px"}
            data-aos={!hasAnimationRun ? "fade-up" : ""}
            data-aos-delay="50"
          >
            Customer corner
          </Text>
          <Flex
            justify={"space-between"}
            align={"center"}
            padding={"14px"}
            bg={"#fff"}
            borderRadius={"16px"}
            boxShadow={"0px 5px 10px 0px rgba(0, 0, 0, 0.06)"}
            data-aos={!hasAnimationRun ? "fade-up" : ""}
          >
            <Flex gap={"10px"}>
              <Flex
                boxSize={"48px"}
                bg={"brand.secondary"}
                borderRadius={"50%"}
                align={"center"}
                justify={"center"}
                flexShrink={0}
              >
                <PiChatCenteredDotsLight size={22} />
              </Flex>
              <Box>
                <Text fontSize={"17px"} fontWeight={500}>
                  Contact us
                </Text>
                <Text fontSize={"14px"}>
                  Chat with a member of our customer support team.
                </Text>
              </Box>
            </Flex>
            <SlArrowRight />
          </Flex>
        </Box>
      </Box>
      <CustomModal
        isOpen={isOpen}
        title={"Contact us"}
        onClose={onClose}
        bodyPy={"0"}
      >
        <Flex pb={"30px"} gap={5} px={{ base: 3, md: 0 }}>
          <a
            href="https://www.facebook.com/profile.php?id=61552983155166"
            target={"_blank"}
            rel="noreferrer"
          >
            <Icon
              as={FaFacebook}
              fontSize={{ base: "20px", md: "27px", lg: "38px" }}
              color={"#106BFF"}
            />
          </a>
          <a
            href="https://www.linkedin.com/company/lo3re"
            target={"_blank"}
            rel="noreferrer"
          >
            <Icon
              as={FaLinkedinIn}
              fontSize={{ base: "20px", md: "27px", lg: "38px" }}
              color={"#0A66C2"}
            />
          </a>
          <a
            href="https://www.instagram.com/lo3rehq?igsh=NnY5czA2NzNzNzQz"
            target={"_blank"}
            rel="noreferrer"
          >
            <Icon
              as={FaSquareInstagram}
              fontSize={{ base: "20px", md: "27px", lg: "38px" }}
              color={"#E84636"}
            />
          </a>
          <a
            href="https://www.tiktok.com/@lo3rehq"
            target={"_blank"}
            rel="noreferrer"
          >
            <Icon
              as={FaTiktok}
              fontSize={{ base: "20px", md: "27px", lg: "38px" }}
              color={"#20141B"}
            />
          </a>
          <a
            href="https://whatsapp.com/channel/0029VaSt9iNI1rchf7iHIA2Z"
            target={"_blank"}
            rel="noreferrer"
          >
            <Icon
              as={IoLogoWhatsapp}
              fontSize={{ base: "20px", md: "27px", lg: "38px" }}
              color={"#0CC143"}
            />
          </a>
          <a
            href="https://x.com/lo3rehq?t=S8BQn_gyQ5Gtms9HLlEpbw&s=09"
            target={"_blank"}
            rel="noreferrer"
          >
            <Icon
              as={FaXTwitter}
              fontSize={{ base: "20px", md: "27px", lg: "38px" }}
              color={"#000000"}
            />
          </a>
          <a
            href="https://www.youtube.com/@lo3re"
            target={"_blank"}
            rel="noreferrer"
          >
            <Icon
              as={FaYoutube}
              fontSize={{ base: "20px", md: "27px", lg: "38px" }}
              color={"#FF0000"}
            />
          </a>
        </Flex>
      </CustomModal>
    </>
  );
};

export default ContactCard;

import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useEffect } from "react";
import { PiEyeLight, PiEyeSlash } from "react-icons/pi";

interface CardProps {
  title: string;
  figure: number | string;
  subtitle?: string;
  isBalanceLoading?: boolean;
  variant?: "security" | "aside" | "noCurrency";
  boxClass?: string;
}

const BalanceCard = ({
  title,
  figure,
  subtitle,
  boxClass,
  variant = "security",
  isBalanceLoading = false,
}: CardProps) => {
  const [hideBalance, setHideBalance] = useState(false);
  const isAside = variant === "aside";
  const isNoCurrency = variant === "noCurrency";

  const showFigure = isNoCurrency ? figure : `$${figure}`;

  useEffect(() => {
    if (isBalanceLoading) {
      setHideBalance(true);
    } else {
      setHideBalance(false);
    }
  }, [isBalanceLoading]);

  return (
    <Box
      boxSizing={"border-box"}
      mb={isAside || isNoCurrency ? 0 : "20px"}
      width={isAside ? "full" : ["full", "calc(50% - 10px)"]}
      bg={"#fff"}
      rounded={"16px"}
      boxShadow={"0 4px 16px 0 #0000000f"}
      padding={"16px 24px"}
      className={`balance-card ${boxClass}`}
    >
      <Flex align={"center"}>
        <Text fontSize={"17px"} fontWeight={500} mr={"10px"}>
          {title}
        </Text>
        {hideBalance ? (
          <Icon
            as={PiEyeLight}
            cursor={"pointer"}
            fontSize={20}
            onClick={() => setHideBalance(!hideBalance)}
            display={isNoCurrency ? "none" : "inline-block"}
          />
        ) : (
          <Icon
            as={PiEyeSlash}
            cursor={"pointer"}
            fontSize={20}
            onClick={() => setHideBalance(!hideBalance)}
            display={isNoCurrency ? "none" : "inline-block"}
          />
        )}
      </Flex>
      <Text fontSize={"27px"} fontWeight={700} my={"10px"}>
        {hideBalance ? "***" : showFigure}
      </Text>
      {subtitle ? (
        <Text color={"#727272"} fontSize={"14px"} fontWeight={500}>
          {subtitle}
        </Text>
      ) : (
        ""
      )}
    </Box>
  );
};

export default BalanceCard;

import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import LayoutContainerWrapper from "../../../../components/dashboard/LayoutContainerWrapper";
import ContactCard from "../../../../components/cards/ContactCard";
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import LeftArrow from "../../../../assets/images/icons/arrow-left.png";
import { Link } from "react-router-dom";
import {
  useFetchProfileIIQuery,
  useFetchProfileQuery,
  useUpdateProfileMutation,
} from "../../../../redux/services/accountApi";
import CustomDropdown from "../../../../utils/CustomDropdown";
import { checkEmptyFields } from "../../../../utils/Helpers";
import { showError, showSuccess } from "../../../../utils/Alert";
import { ref, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";
import { getImage, imageDb } from "../../../../firebase";

const EditProfile = () => {
  const fileElement: { current: any } | null = useRef(null);
  const [file, setFile] = useState<File | undefined>();
  const [preview, setPreview] = useState<string>("");
  const [photoLoading, setPhotoLoading] = useState(false);
  const { data: user } = useFetchProfileQuery();
  const { data: profile, isSuccess: isProfileSuccess } =
    useFetchProfileIIQuery();

  const userProfile = profile?.length ? profile[0] : {}
  // console.log(first)
  const [formState, setFormState] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    dob: "",
    gender: "",
  });

  useEffect(() => {
    if (isProfileSuccess) {
      if (profile?.length) {
        const profil: any = profile[0];

        setFormState((prevState) => ({
          ...prevState,
          firstName: profil?.firstName,
          middleName: profil?.middleName,
          lastName: profil?.lastName,
          dob: profil?.dob,
          gender: profil?.gender,
        }));
      }
    }
  }, [isProfileSuccess, profile]);

  const handleInputs = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormState({ ...formState, [name]: value });
  };

  const fileOnChange = (e: React.FocusEvent<HTMLInputElement>) => {
    let images = e.target.files;

    const allowedExtensions = /(\.jpeg|\.jpg|\.png)$/i;
    if (!allowedExtensions.exec(e.target.value)) {
      showError("Invalid file type");
      return false;
    }

    const TwoMB = 2097152;
    if (images?.length) {
      if (images[0].size >= TwoMB) {
        showError("File must be less than 2MB");
        return;
      } else {
        setFile(images?.length ? images[0] : undefined);
      }
    }
  };

  useEffect(() => {
    if (!file) {
      setPreview("");
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  const clickFileElem = () => {
    if (fileElement.current) {
      fileElement.current.click();
    }
  };

  const [updateProfile, { isLoading: isUpdateProfileLoading }] =
    useUpdateProfileMutation();

  const storageRef = ref(imageDb, `uploads/${v4()}`);
  const submitForm = async () => {
    const { firstName, middleName, lastName, gender, dob } = formState;
    const isEmpty = checkEmptyFields({
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
      gender,
      date_of_birth: dob,
    });

    if (!profile?.length || !profile[0]?.id) {
      showError("Could not update profile, contact support");
      return;
    }

    if (!isEmpty) {
      const profileObj = {
        ...formState,
        uid: profile[0]?.id || "",
      };

      if (file) {
        setPhotoLoading(true);
        await uploadBytes(storageRef, file)
          .then(async (snapshot) => {
            const photoUrl = snapshot?.metadata?.fullPath || "";
            await getImage(photoUrl).then((url) => {
              // @ts-ignore
              profileObj.photoUrl = url;
              setPhotoLoading(false);
            });
          })
          .catch((err) => {
            setPhotoLoading(false);
            console.log(err);
          });
      }

      updateProfile(profileObj)
        .unwrap()
        .then((resp) => {
          if (resp?.success) {
            showSuccess("Profile update successful");
          } else {
            console.log(resp);
            showError("Something went wrong, try again later");
          }
        })
        .catch((err) => {
          console.log(err);
          showError(err?.data?.message || err?.message || "An error occurred");
        });
    }
  };

  return (
    <LayoutContainerWrapper asideChildren={<Aside />}>
      <Flex alignItems={"center"} mb={"10px"}>
        <Link to="/settings">
          <img
            src={LeftArrow}
            alt="left direction arrow"
            width={20}
            height={20}
          />
        </Link>
        <Heading
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          m={"auto"}
          fontSize={"21px"}
          fontWeight={"semibold"}
        >
          Edit Profile
        </Heading>
      </Flex>

      <Flex
        direction={"column"}
        gap={"10px"}
        justifyContent={"center"}
        alignItems={"center"}
        m={"auto"}
      >
        <Avatar
          name={`${user?.name} ${user?.name[user?.name?.length - 1]}`}
          // @ts-ignore
          src={userProfile?.photoUrl || preview}
          boxSize={"120px"}
          bg={"#FFD600"}
          color={"#000"}
          fontWeight={"semibold"}
        />

        <input
          type="file"
          ref={fileElement}
          onChange={fileOnChange}
          style={{ display: "none" }}
        />
        <Button variant={"outline"} bg={"inherit"} onClick={clickFileElem}>
          Change profile picture
        </Button>

        <Text textAlign={"center"} color={"#868686"} fontSize={"14px"}>
          At least 800 x 800 px recommended. <br />
          JPG or PNG allowed
        </Text>
      </Flex>

      <Box my={"20px"}>
        <hr />
      </Box>

      <Box>
        <Flex direction={"column"} gap={"30px"}>
          <Flex gap={"10px"}>
            <FormControl isRequired>
              <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
                First Name
              </FormLabel>
              <Input
                focusBorderColor={"gray.300"}
                value={formState.firstName}
                name={"firstName"}
                onChange={handleInputs}
              />
            </FormControl>

            <FormControl isRequired>
              <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
                Middle Name
              </FormLabel>
              <Input
                focusBorderColor={"gray.300"}
                value={formState.middleName}
                name={"middleName"}
                onChange={handleInputs}
              />
            </FormControl>
          </Flex>

          <FormControl isRequired>
            <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
              Last Name
            </FormLabel>
            <Input
              focusBorderColor={"gray.300"}
              value={formState.lastName}
              name={"lastName"}
              onChange={handleInputs}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
              Email Address
            </FormLabel>
            <Input
              focusBorderColor={"gray.300"}
              value={user?.email || ""}
              readOnly
            />
          </FormControl>

          <FormControl>
            <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
              Date of Birth
            </FormLabel>
            <Input
              type="date"
              value={formState.dob}
              name={"dob"}
              onChange={handleInputs}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
              Gender
            </FormLabel>
            <CustomDropdown
              value={formState.gender}
              itemOnClick={(val) => setFormState({ ...formState, gender: val })}
              dropdownItems={[
                {
                  name: "Male",
                  value: "male",
                },
                {
                  name: "Female",
                  value: "female",
                },
              ]}
            />
          </FormControl>

          <Box display="flex" justifyContent="flex-end" gap={4}>
            <Button
              size={"lg"}
              fontSize={"13px"}
              rounded={"8px"}
              fontWeight={400}
              colorScheme={"buttonPrimary"}
              color={"white"}
              onClick={submitForm}
              isLoading={isUpdateProfileLoading || photoLoading}
            >
              Update
            </Button>
          </Box>
        </Flex>
      </Box>
    </LayoutContainerWrapper>
  );
};

const Aside = () => {
  return (
    <>
      <ContactCard />
    </>
  );
};

export default EditProfile;

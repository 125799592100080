import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  FormControl,
  FormLabel,
  Select,
  NumberInput,
  NumberInputField,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { Checkbox } from "@chakra-ui/react";
import CustomDropdown from "../../../utils/CustomDropdown";
import {
  useFetchSupportedBanksQuery,
  useFetchSupportedCoinsQuery,
  useValidateAccountDetailMutation,
} from "../../../redux/services/beneficiariesApi";
import { showError } from "../../../utils/Alert";

interface AddBeneProps {
  currency: string;
  payMethod: any;
  bankFormState: any;
  cryptoFormState: any;
  handleChange: (val: any, name: string) => void;
}

const accountTypes = [
  <option>Select</option>,
  <option>Personal Account</option>,
  <option>Business Account</option>,
];

const AddBeneficiary = ({
  currency,
  payMethod,
  bankFormState,
  cryptoFormState,
  handleChange,
}: AddBeneProps) => {
  const isFormTypeNGN =
    payMethod?.code === "bank_transfer" && currency === "ngn";
  const isFormTypeUSD =
    payMethod?.code === "bank_transfer" && currency === "usd";
  const [supportedBanks, setSupportedBanks] = useState<any[]>([]);
  const [showTagInput, setShowTagInput] = useState(false);
  const [supportedNetworks, setSupportedNetworks] = useState<any[]>([]);
  const [supportedCryptos, setSupportedCryptos] = useState<any[]>([]);

  const { data: fetchedSuppCoins, isSuccess: isFetchedSuppCoinsSuccess } =
    useFetchSupportedCoinsQuery();
  const { data: fetchedSuppBanks, isSuccess: isFetchedSuppBanksSuccess } =
    useFetchSupportedBanksQuery();

  const [validateAcctDetail, { isLoading: isValidateAcctLoading }] =
    useValidateAccountDetailMutation();

  // validate acct
  useEffect(() => {
    if (currency === "ngn") {
      if (
        bankFormState.account_number.length === 10 &&
        bankFormState.bank_code
      ) {
        // const { account_number, bank_code } = bankFormState;
        validateAcctDetail({
          account_number: bankFormState.account_number,
          bank_code: bankFormState.bank_code,
        })
          .unwrap()
          .then((resp) => {
            if (resp?.data?.status) {
              const acName = resp?.data?.data?.account_name;
              if (acName) {
                handleChange(acName, "account_name");
              }
            } else {
              showError(resp?.message || "Could not validate account details");
            }
          })
          .catch((err) => {
            showError("Could not validate account details");
            console.log(err);
            handleChange("", "account_name");
          });
      }
    }
  }, [
    currency,
    bankFormState.account_number,
    bankFormState.bank_code,
    validateAcctDetail,
    handleChange,
  ]);

  //   process fetched supported banks
  useEffect(() => {
    if (isFetchedSuppBanksSuccess) {
      const newArray = fetchedSuppBanks?.map((bank: any) => ({
        ...bank,
        value: bank?.code,
      }));

      setSupportedBanks(newArray);
    }
  }, [isFetchedSuppBanksSuccess, fetchedSuppBanks]);

  //   process fetched supported coins
  useEffect(() => {
    if (isFetchedSuppCoinsSuccess) {
      const newArray = fetchedSuppCoins?.map((crypto) => ({
        ...crypto,
        value: crypto.id,
        name: crypto.name.toUpperCase(),
        icon: crypto.logo_url,
      }));

      setSupportedCryptos(newArray);
      //   console.log("supp crypto", fetchedSuppCoins);
    }
  }, [isFetchedSuppCoinsSuccess, fetchedSuppCoins]);

  //   pop supported networks
  useEffect(() => {
    const selectedCoin = supportedCryptos.filter(
      (val) => val.id === cryptoFormState.coin_id
    );
    if (selectedCoin.length) {
      const networkArr = selectedCoin[0]?.networks?.map((val: any) => ({
        name: val,
        value: val,
      }));
      setSupportedNetworks(networkArr);
      //   console.log(networkArr);
    }
  }, [cryptoFormState.coin_id, supportedCryptos]);

  // show/hide tag_id input
  useEffect(() => {
    const selectedCoin = supportedCryptos.filter(
      (val) => val.id === cryptoFormState.coin_id
    );
    if (
      selectedCoin.length &&
      selectedCoin[0]?.name?.toLowerCase() === "ripple"
    ) {
      setShowTagInput(true);
    } else {
      setShowTagInput(false);
    }
  }, [cryptoFormState.coin_id, supportedCryptos]);

  return (
    <Box>
      {isFormTypeNGN && (
        <FormControl display={"flex"} flexDirection={"column"} gap={5}>
          <Box>
            <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
              Bank Name
            </FormLabel>
            <Select
              focusBorderColor={"gray.300"}
              value={bankFormState.bank_code}
              onChange={({ target }) => handleChange(target.value, "bank_code")}
            >
              <option value="">Select bank</option>
              {supportedBanks?.map((bank, idx) => (
                <option key={idx + bank?.code} value={bank?.code}>
                  {bank?.name}
                </option>
              ))}
            </Select>
          </Box>

          <Box>
            <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
              Account Number
            </FormLabel>
            <NumberInput
              focusBorderColor={"gray.300"}
              value={bankFormState.account_number}
              onChange={(val) => handleChange(val, "account_number")}
            >
              <NumberInputField />
            </NumberInput>
          </Box>

          <Box>
            <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
              Account Name
            </FormLabel>
            <Input
              focusBorderColor={"gray.300"}
              value={bankFormState.account_name}
              onChange={({ target }) =>
                handleChange(target.value, "account_name")
              }
              readOnly
            />
            <Text fontSize={"12px"} color={"#999"} mt={"8px"}>
              {isValidateAcctLoading ? "Verifying Account..." : ""}
            </Text>
          </Box>

          <Box>
            <Checkbox
              colorScheme={"buttonPrimary"}
              isChecked={bankFormState.saveForLater}
              onChange={({ target }) =>
                handleChange(target.checked, "saveForLater")
              }
            >
              Save for future transactions
            </Checkbox>
          </Box>
        </FormControl>
      )}

      {isFormTypeUSD && (
        <FormControl display={"flex"} flexDirection={"column"} gap={5}>
          <Box>
            <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
              Account Type
            </FormLabel>
            <Select
              focusBorderColor={"gray.300"}
              value={bankFormState.account_type}
              onChange={({ target }) =>
                handleChange(target.value, "account_type")
              }
            >
              {accountTypes}
            </Select>
          </Box>

          <Box>
            <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
              Account Number
            </FormLabel>
            <NumberInput
              focusBorderColor={"gray.300"}
              value={bankFormState.account_number}
              onChange={(value) => handleChange(value, "account_number")}
            >
              <NumberInputField />
            </NumberInput>
          </Box>

          <Box>
            <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
              Recipient Name
            </FormLabel>
            <Input
              focusBorderColor={"gray.300"}
              type="text"
              value={bankFormState.account_name}
              onChange={({ target }) =>
                handleChange(target.value, "account_name")
              }
            />
          </Box>

          <Box>
            <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
              Sort Code
            </FormLabel>
            <Input
              focusBorderColor={"gray.300"}
              type="text"
              value={bankFormState.sort_code}
              onChange={({ target }) => handleChange(target.value, "sort_code")}
            />
          </Box>

          <Box>
            <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
              Routing Number
            </FormLabel>
            <NumberInput
              min={0}
              focusBorderColor={"gray.300"}
              value={bankFormState.routing_number}
              onChange={(value) => handleChange(value, "routing_number")}
            >
              <NumberInputField />
            </NumberInput>
          </Box>

          <Box>
            <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
              Note
            </FormLabel>
            <Textarea
              placeholder={"Optional"}
              focusBorderColor={"gray.300"}
              value={bankFormState.note}
              onChange={({ target }) => handleChange(target.value, "note")}
            />
          </Box>

          <Box>
            <Checkbox
              colorScheme={"buttonPrimary"}
              isChecked={bankFormState.saveForLater}
              onChange={({ target }) =>
                handleChange(target.checked, "saveForLater")
              }
            >
              Save for future transactions
            </Checkbox>
          </Box>
        </FormControl>
      )}

      {payMethod?.code === "crypto" && (
        <FormControl display={"flex"} flexDirection={"column"} gap={5}>
          <Box>
            <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
              Select Coin
            </FormLabel>
            <CustomDropdown
              value={cryptoFormState.coin_id}
              itemOnClick={(val) => handleChange(val, "coin_id")}
              dropdownItems={supportedCryptos}
            />
          </Box>

          <Box>
            <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
              Select Network
            </FormLabel>
            <CustomDropdown
              value={cryptoFormState.network}
              itemOnClick={(val) => handleChange(val, "network")}
              dropdownItems={supportedNetworks}
            />
          </Box>

          <Box>
            <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
              Wallet Address
            </FormLabel>
            <Input
              focusBorderColor={"gray.300"}
              value={cryptoFormState.address}
              onChange={({ target }) => handleChange(target.value, "address")}
            />
          </Box>

          {showTagInput && (
            <Box>
              <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
                Tag ID
              </FormLabel>
              <Input
                value={cryptoFormState.tag_id}
                name="tag_id"
                onChange={({ target }) => handleChange(target.value, "address")}
              />
            </Box>
          )}

          <Box>
            <Checkbox
              colorScheme={"buttonPrimary"}
              isChecked={cryptoFormState.saveForLater}
              onChange={({ target }) =>
                handleChange(target.checked, "saveForLater")
              }
            >
              Save for future transactions
            </Checkbox>
          </Box>
        </FormControl>
      )}
    </Box>
  );
};

export default AddBeneficiary;

import React, { useEffect, useState } from "react";
import { Avatar, Box, Flex, Heading, Text } from "@chakra-ui/react";
import { useFetchProfileQuery } from "../../redux/services/accountApi";
import { useFetchWalletBalanceQuery } from "../../redux/services/walletApi";

const UserCount = () => {
  const [refWallet, setRefWallet] = useState<any>(null);
  const { data: user } = useFetchProfileQuery();

  const { data: walletBalance, isSuccess: isWalletBalanceSuccess } =
    useFetchWalletBalanceQuery();

  useEffect(() => {
    if (isWalletBalanceSuccess) {
      const refWallet = walletBalance?.filter(
        (val) => val?.type?.toLowerCase() === "affiliate"
      );

      setRefWallet(refWallet[0]);
    }
  }, [isWalletBalanceSuccess, walletBalance]);

  return (
    <>
      <Flex
        direction={{ base: "column", lg: "row" }}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Avatar
          boxSize={{ base: "120px" }}
          name={`${user?.name} ${user?.name[user?.name?.length - 1]}`}
          src={user?.picture || user?.profile[0]?.photoUrl}
          bg="#FFD600"
          color="#000"
          fontSize={"42px"}
          fontWeight={"semibold"}
          flexShrink={0}
        />

        <Flex
          flexDirection="column"
          gap="10px"
          w={{ base: "100%", lg: "calc(100% - 140px)" }}
          minW={{ base: "100%", lg: "300px" }}
        >
          <Heading
            size="md"
            fontWeight={"semibold"}
            textAlign={{ base: "center", lg: "left" }}
            mt={{ base: "10px", lg: "0" }}
            textTransform={"capitalize"}
          >
            @{user?.name}
          </Heading>
          <Flex
            justifyContent={"space-between"}
            alignItems={"center"}
            bg={"#f3f3f3"}
            py={"15px"}
            px={"20px"}
            borderRadius={"100px"}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              cursor={"pointer"}
            >
              <Text fontSize={["14px", "17px"]} fontWeight={"semibold"}>
                {user?.user?.statistics?.totalTicketsBought || 0}
              </Text>
              <Text whiteSpace={"nowrap"} fontSize={["10px", "14px"]}>
                Total tickets bought
              </Text>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              cursor={"pointer"}
            >
              <Text fontSize={["14px", "17px"]} fontWeight={"semibold"}>
                {user?.user?.statistics?.totalGamesPlayed || 0}
              </Text>
              <Text whiteSpace={"nowrap"} fontSize={["10px", "14px"]}>
                Total games played
              </Text>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Text fontSize={["14px", "17px"]} fontWeight={"semibold"}>
                {`${refWallet?.balance || 0} TKN`}
              </Text>
              <Text whiteSpace={"nowrap"} fontSize={["10px", "14px"]}>
                Referral commissions
              </Text>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default UserCount;

import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  PinInput,
  PinInputField,
  HStack,
  Flex,
  Heading,
} from "@chakra-ui/react";

const OldPinEntryModal = ({
  isOpen,
  onClose,
  onSubmit,
  title,
  subtitle,
  isSubmitLoading,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (pin: string, resetPin: () => void) => void;
  title?: string;
  subtitle?: string;
  isSubmitLoading?: boolean;
}) => {
  const [pin, setPin] = useState("");
  const resetPin = () => {
    setPin("");
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={{ base: "xs", md: "sm" }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={"1.17rem"}>
          {title || "Enter your PIN"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Heading
            fontSize={"14px"}
            textAlign={"center"}
            color={"gray.500"}
            mb={5}
            mt={1}
            w={"full"}
            fontWeight={"semibold"}
          >
            {subtitle || "Enter Your 6-Digit wallet PIN"}
          </Heading>
          <Flex justifyContent={"center"} gap={5}>
            <HStack>
              <PinInput value={pin} onChange={setPin} mask>
                <PinInputField
                  bg={"#E8E8E8"}
                  _focus={{ borderColor: "gray.100" }}
                />
                <PinInputField
                  bg={"#E8E8E8"}
                  _focus={{ borderColor: "gray.100" }}
                />
                <PinInputField
                  bg={"#E8E8E8"}
                  _focus={{ borderColor: "gray.100" }}
                />
                <PinInputField
                  bg={"#E8E8E8"}
                  _focus={{ borderColor: "gray.100" }}
                />
                <PinInputField
                  bg={"#E8E8E8"}
                  _focus={{ borderColor: "gray.100" }}
                />
                <PinInputField
                  bg={"#E8E8E8"}
                  _focus={{ borderColor: "gray.100" }}
                />
              </PinInput>
            </HStack>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme={"buttonPrimary"}
            w={"full"}
            onClick={() => onSubmit(pin, resetPin)}
            isLoading={isSubmitLoading}
          >
            Confirm
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
export default OldPinEntryModal;

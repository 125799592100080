import { Flex, Icon, Text } from "@chakra-ui/react";
// import { FaEllipsis } from "react-icons/fa6";
import { MdVerified } from "react-icons/md";
import LotteryBg from "../../assets/images/default.png";
import useTextTruncate from "../../hooks/useTextTruncate";
import { useNavigate } from "react-router-dom";
import { GameObj } from "../../redux/services/types";
import useTimeFormatter from "../../hooks/useTimeFormatter";
import { GiTicket } from "react-icons/gi";

interface CardProps {
  game: GameObj;
}

function formatNumber(num: number) {
  if (num < 1000) {
    return num.toString();
  } else if (num < 10000) {
    return (num / 1000).toFixed(1) + "k";
  } else if (num < 1000000) {
    return (num / 1000).toFixed(0) + "k";
  } else if (num < 10000000) {
    return (num / 1000000).toFixed(1) + "m";
  } else {
    return (num / 1000000).toFixed(0) + "m";
  }
}

const LotteryGameCardMini = ({ game }: CardProps) => {
  const navigate = useNavigate();
  const { truncateText } = useTextTruncate();
  const { formatDuration } = useTimeFormatter();
  const timeLeft = formatDuration(game?.endOn);

  const redirectUrl = () => {
    navigate(`/lotteries/view-game/${game?.id}`);
  };

  return (
    <Flex
      justify={"flex-start"}
      align={"center"}
      gap={"8px"}
      minW={"149px"}
      cursor={"pointer"}
      data-aos={"fade-up"}
      onClick={redirectUrl}
    >
      <Flex
        bg={"#9ebcca"}
        rounded={"8px"}
        color={"#fff"}
        flexDir={"column"}
        justify={"space-between"}
        padding={"8px"}
        boxSize={"80px"}
        align={"flex-end"}
        backgroundImage={`url("${game?.coverUrl || LotteryBg}")`}
        backgroundSize={"cover"}
        backgroundRepeat={"no-repeat"}
      >
        <Flex justify={"flex-start"} w={"full"}>
          {timeLeft ? (
            <Text
              bg={"brand.lightGrey"}
              rounded={"30px"}
              display={"inline"}
              padding={"3px 8px"}
              color={"#000"}
              fontSize={"10px"}
            >
              {timeLeft}
            </Text>
          ) : (
            ""
          )}
        </Flex>
        <Icon as={MdVerified} color={"brand.secondary"} fontSize="18px" />
      </Flex>
      <Flex justify={"space-between"} my={"8px"} flexDir={"column"}>
        <Text
          fontSize={"14px"}
          fontWeight={500}
          textTransform={"capitalize"}
          title={game?.title}
        >
          {truncateText(game?.title, 8)}
        </Text>
        {/* <Text fontSize={"14px"} fontWeight={700}>
          ${jackpot}
        </Text> */}
        <Flex gap={"5px"} align={"center"}>
          <Icon as={GiTicket} fontSize={["14px", "18px"]} color={"#2F53D7"} />
          <Text display={"inline-block"} fontSize={"12px"} color={"#48494D"}>
            {game?.ticketSalesCount
              ? `${formatNumber(game?.ticketSalesCount)}+`
              : game?.ticketSalesCount}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default LotteryGameCardMini;

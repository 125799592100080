import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./customFetchBase";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery,
  endpoints: (builder) => ({
    validateRefCode: builder.mutation<any, any>({
      query: (data) => ({
        url: "/auth/affiliate/validate",
        method: "POST",
        body: data,
      }),
      // transformResponse: (result: { data: { message: any } }) => result.data.message
    }),
    tempStoreRefCode: builder.mutation<any, any>({
      query: (data) => ({
        url: `/auth/affiliate/temp/${data.uid}`,
        method: "POST",
        body: data.body,
      }),
      // transformResponse: (result: { data: { message: any } }) => result.data.message
    }),
  }),
});

export const { useValidateRefCodeMutation, useTempStoreRefCodeMutation } =
  authApi;

import React from "react";
import { Button } from "@chakra-ui/react";
import AuthLayout from "./AuthLayout";
import { Link } from "react-router-dom";
import ThumbsUp from "../../assets/images/thumbs-up.png";

const ResetDone = () => {
  return (
    <AuthLayout
      heading="Password Reset!🚀"
      subHeading="You’ve successfully reset your password, click below to continue your access"
      logoReplacement={ThumbsUp}
    >
      <Button
        colorScheme={"buttonPrimary"}
        w={"full"}
        as={Link}
        to={"/"}
        height={"48px"}
        fontWeight={500}
        type="submit"
        rounded="8px"
      >
        Continue
      </Button>
    </AuthLayout>
  );
};

export default ResetDone;

import React from "react";
import LayoutContainerWrapper from "../../../../components/dashboard/LayoutContainerWrapper";
import ContactCard from "../../../../components/cards/ContactCard";
import {Flex, Heading} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import LeftArrow from "../../../../assets/images/icons/arrow-left.png";

const KycVerification = () => {
    return (
        <LayoutContainerWrapper asideChildren={<Aside/>}>
            <Flex alignItems={"center"} mb={"10px"}>
                <Link to="/settings">
                    <img src={LeftArrow} alt="left direction arrow" width={20} height={20}/>
                </Link>
                <Heading display={"flex"} justifyContent={"center"} alignItems={"center"} m={"auto"}
                         fontSize={"21px"} fontWeight={"semibold"}>
                    KYC Verification
                </Heading>
            </Flex>
        </LayoutContainerWrapper>
    );
};

const Aside = () => {
    return (
        <>
            <ContactCard/>
        </>
    );
};

export default KycVerification;
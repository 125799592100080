import { Button, Flex, Icon, Image, Text } from "@chakra-ui/react";
import Or from "../../assets/images/or.svg";
import { FaGoogle } from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import AuthLayout from "./AuthLayout";
import useCheckEmptyFields from "../../hooks/useCheckEmptyFields";
import CustomInput from "../../utils/CustomInput";
import {
  useTempStoreRefCodeMutation,
  useValidateRefCodeMutation,
} from "../../redux/services/authApi";
import { useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  getAdditionalUserInfo,
  GoogleAuthProvider,
  sendEmailVerification,
  signInWithPopup,
  signOut,
  updateProfile,
} from "firebase/auth";
import { auth } from "../../firebase";
import { handleFirebaseError } from "../../utils/Helpers";
import { showError } from "../../utils/Alert";

const Signup = () => {
  const navigate = useNavigate();
  const { refCode } = useParams();
  const [checkEmptyFields] = useCheckEmptyFields();
  const [validateRefCode] = useValidateRefCodeMutation();
  const [referralError, setReferralError] = useState("");
  const [referralSuccess, setReferralSuccess] = useState("");
  const [refCodeLoading, setRefCodeLoading] = useState(false);
  const [formState, setFormState] = useState({
    username: "",
    email: "",
    password: "",
    confirmPassword: "",
    referral_code: "",
    loading: false,
  });

  const handleInputs = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormState({ ...formState, [name]: value });
  };

  useEffect(() => {
    if (refCode) {
      setFormState((prevState) => ({ ...prevState, referral_code: refCode }));
    }
  }, [refCode]);

  useEffect(() => {
    const validateCode = async () => {
      let obj: { failed: boolean; result?: any; error?: any } = {
        failed: false,
        result: undefined,
        error: undefined,
      };

      setRefCodeLoading(true);
      await validateRefCode({ referral_code: formState.referral_code })
        .unwrap()
        .then((resp) => {
          setRefCodeLoading(false);
          console.log("ref code err: ", resp);
          obj = { failed: false, result: resp };
        })
        .catch((error) => {
          setRefCodeLoading(false);
          obj = { failed: true, error: error };
        });

      return obj;
    };

    (async () => {
      if (formState.referral_code !== "") {
        const validationResult = await validateCode();
        if (validationResult.failed) {
          setReferralError(
            validationResult?.error?.data?.message ||
              validationResult?.error[1]?.message
          );
          setReferralSuccess("");
        } else {
          setReferralSuccess("✅ Promo code is valid");
          setReferralError("");
        }
      } else {
        setReferralError("");
      }
    })();
  }, [formState.referral_code, validateRefCode]);

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;
        const additionalInfo = getAdditionalUserInfo(result);
        const fullName = user?.displayName;
        if (additionalInfo?.isNewUser && fullName) {
          // TODO: check for wallet pin & 2fa as usual for login flow
          updateProfile(user, {
            displayName: fullName.split(" ")[0],
          })
            .catch((error) => {
              console.log(error, "update user error");
            })
            .finally(() => {
              // @ts-ignore
              localStorage.setItem("axxToken", user?.accessToken);
              navigate("/dashboard");
            });
        } else {
          // @ts-ignore
          localStorage.setItem("axxToken", user?.accessToken);
          navigate("/dashboard");
        }
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.error(
          "Google Sign-In error:",
          errorCode,
          errorMessage,
          email,
          credential
        );
        handleFirebaseError(error);
      });
  };

  const [tempStoreRefCode] = useTempStoreRefCodeMutation();
  const submitForm = async (e: any) => {
    e.preventDefault();
    const { email, username, password, confirmPassword, referral_code } =
      formState;

    const isFormEmpty = checkEmptyFields({
      email,
      username,
      password,
      confirmPassword,
    });

    if (!isFormEmpty) {
      if (password !== confirmPassword) {
        showError("Passwords do not match");
        return;
      }

      let usernameRegex = RegExp(/\s/);
      if (usernameRegex.test(username)) {
        showError("Username cannot contain whitespace");
        return;
      }

      if (referralError !== "") {
        showError(referralError);
        return;
      }

      setFormState({ ...formState, loading: true });
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;
        console.log(user, "user");
        // Update user profile with display name
        if (user) {
          if (referral_code) {
            // @ts-ignore
            localStorage.setItem("axxToken", user?.accessToken);
            await tempStoreRefCode({ uid: user?.uid, body: { referral_code } })
              .unwrap()
              .then((resp) => {
                console.log("ref code resp", resp);
                localStorage.removeItem("axxToken");
              })
              .catch((error) => {
                localStorage.removeItem("axxToken");
                console.log("ref code error", error);
              });
          }

          updateProfile(user, {
            displayName: username,
          }).catch((error) => {
            console.log(error, "update user error");
          });
        }

        if (user) {
          sendEmailVerification(user)
            .then((resp) => {
              console.log("vEmail", resp);
              signOut(auth);
              setFormState({ ...formState, loading: false });
              // setFlow("signUp");
              navigate("/link-sent", {
                state: {
                  data: {
                    message:
                      "🟢 Verification link has been sent to email. Please check inbox or spam folder for this link",
                    email: email,
                  },
                },
              });
            })
            .catch((error) => {
              console.log(error);
              handleFirebaseError(error);
            });
        }
      } catch (error) {
        handleFirebaseError(error);
        setFormState({ ...formState, loading: false });
      }
    }
  };

  return (
    <AuthLayout>
      <Button
        variant={"outline"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        border={"2px solid"}
        borderColor={"brand.primary"}
        padding={"26px 20px"}
        rounded={"8px"}
        gap={"8px"}
        width={"full"}
        onClick={signInWithGoogle}
      >
        <Icon as={FaGoogle} fontSize={"20px"} color="brand.primary" />
        <Text fontSize={"18px"} fontWeight={500}>
          Continue with Google
        </Text>
      </Button>
      <Flex justify={"center"} my="24px">
        <Image src={Or} alt="" />
      </Flex>

      <form onSubmit={submitForm}>
        <CustomInput
          label="Username"
          name="username"
          value={formState.username}
          onChange={handleInputs}
          mb="13px"
        />
        <CustomInput
          label="Your email"
          name="email"
          type="email"
          value={formState.email}
          onChange={handleInputs}
          mb="13px"
        />
        <CustomInput
          label="Password"
          name="password"
          type="password"
          value={formState.password}
          onChange={handleInputs}
          mb="13px"
        />
        <CustomInput
          label="Confirm password"
          name="confirmPassword"
          type="password"
          value={formState.confirmPassword}
          onChange={handleInputs}
          mb="13px"
        />
        <CustomInput
          label="Promo code"
          name="referral_code"
          value={formState.referral_code}
          onChange={handleInputs}
          mb={!referralError && !refCodeLoading ? "13px" : 0}
        />
        <Text
          as={"small"}
          color={refCodeLoading ? "gray.600" : "red.600"}
          mb="13px"
        >
          {refCodeLoading ? "Validating promo code" : referralError}
        </Text>
        <Text as={"small"} color={"green.600"} mb="13px">
          {referralSuccess}
        </Text>
        <Button
          colorScheme={"buttonPrimary"}
          type="submit"
          mt={"29px"}
          w={"full"}
          height={"48px"}
          isLoading={formState.loading}
          fontWeight={500}
          rounded="8px"
          isDisabled={refCodeLoading}
        >
          Sign up
        </Button>
        <Button
          variant={"ghost"}
          as={Link}
          to={"/"}
          w={"full"}
          fontWeight={500}
          _hover={{ bg: "transparent" }}
        >
          Already have an account? Login
        </Button>
      </form>
    </AuthLayout>
  );
};

export default Signup;

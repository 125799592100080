import {
  Box,
  Button,
  Flex,
  Text,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Heading,
  ModalCloseButton,
} from "@chakra-ui/react";
import WalletAdd from "../assets/images/icons/wallet-add.png";
import WalletMinus from "../assets/images/icons/wallet-minus.png";
import useCopyToClipBoard from "../hooks/useCopyToClipboard";
import { LuCopy } from "react-icons/lu";
import { AiOutlineCheck } from "react-icons/ai";

interface TxHistoryProps {
  type: string;
  amount: number;
  currency: string;
  date: number;
  txnId: string;
  status: string;
  checkoutUrl?: string;
  trnx: any;
}

const TransactionHistory = ({
  type,
  amount,
  currency,
  date,
  txnId,
  status,
  checkoutUrl = "",
  trnx,
}: TxHistoryProps) => {
  const { handleCopyClick, isCopied } = useCopyToClipBoard();
  const {
    isOpen: isTxnModalOpen,
    onOpen: onTxnModalOpen,
    onClose: onTxnModalClose,
  } = useDisclosure();
  const isCreditTxn =
    type?.toLowerCase() === "deposit" ||
    type?.toLowerCase() === "jackpot win" ||
    type?.toLowerCase() === "ticket refund";
  let newDate: Date | string = new Date(date * 1000);
  newDate = newDate.toLocaleDateString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
  });

  const statusClass = () => {
    let txt = "brand.warning";
    if (
      status?.toLowerCase() === "success" ||
      status?.toLowerCase() === "completed"
    ) {
      txt = "brand.success";
    } else if (
      status?.toLowerCase() === "failed" ||
      status?.toLowerCase() === "expired"
    ) {
      txt = "brand.danger";
    }

    return txt;
  };

  const redirectToCheckout = () => {
    window.open(checkoutUrl, "_blank");
  };

  return (
    <>
      <Flex
        justify={"space-between"}
        cursor={"pointer"}
        py={"16px"}
        onClick={onTxnModalOpen}
        _hover={{ bg: "#FBFBFB" }}
        data-aos={"fade-left"}
      >
        <Flex>
          <Flex
            align={"center"}
            justify={"center"}
            boxSize={"48px"}
            rounded={"50%"}
            mr={"10px"}
            padding={"14px 15px"}
            bg={isCreditTxn ? "brand.successTint" : "brand.dangerTint"}
          >
            <Image
              src={isCreditTxn ? WalletAdd : WalletMinus}
              alt="icon"
              boxSize={"24px"}
            />
          </Flex>
          <Flex flexDir={"column"} justify={"center"}>
            <Text
              textTransform={"capitalize"}
              fontSize={"17px"}
              fontWeight={600}
            >
              {type}
            </Text>
            <Text color={"#727272"} fontSize={"14px"} fontWeight={500}>
              {newDate}
            </Text>
          </Flex>
        </Flex>
        <Box textAlign={"right"}>
          <Text fontSize={"17px"}>
            {isCreditTxn ? "+" + amount : "-" + amount} {currency.toUpperCase()}
          </Text>
          <Text textTransform={"capitalize"} color={statusClass()}>
            {status}
          </Text>
        </Box>
      </Flex>

      <Modal
        isOpen={isTxnModalOpen}
        isCentered
        onClose={onTxnModalClose}
        size={{ base: "xs", md: "lg" }}
      >
        <ModalOverlay bg="blackAlpha.600" />
        <ModalContent boxShadow={"md"} rounded={"xl"} p={5}>
          <ModalCloseButton />
          <ModalBody textAlign={"center"} mt={"24px"} px={[0, 6]}>
            <Heading as={"h2"} fontSize={"42px"} fontWeight={700} mb={"16px"}>
              {isCreditTxn ? "+" + amount : "-" + amount}{" "}
              {currency.toUpperCase()}
            </Heading>
            {/* txn info pill */}
            <Flex
              bg={isCreditTxn ? "brand.successTint" : "brand.dangerTint"}
              align={"center"}
              justify={"space-between"}
              rounded={"100px"}
              gap={"8px"}
              margin={"16px auto auto"}
              width={"fit-content"}
              padding={"12px 16px"}
            >
              <Box boxSize={"24px"}>
                <Image
                  src={isCreditTxn ? WalletAdd : WalletMinus}
                  boxSize={"100%"}
                  alt=""
                />
              </Box>
              <Text textTransform={"capitalize"}>
                {type} | {newDate}
              </Text>
            </Flex>
            {/* body */}
            <Box mt={"12px"}>
              {trnx?.method ? (
                <Flex justify={"space-between"} py={"16px"}>
                  <Text color={"brand.textMuted"}>Transaction method</Text>
                  <Text textTransform={"capitalize"}>
                    {trnx?.method.replace(/_/g, " ")}
                  </Text>
                </Flex>
              ) : (
                ""
              )}
              {trnx?.fee ? (
                <Flex justify={"space-between"} py={"16px"}>
                  <Text color={"brand.textMuted"}>Fee</Text>
                  <Text textTransform={"capitalize"}>{trnx?.fee} USD</Text>
                </Flex>
              ) : (
                ""
              )}
              <Flex justify={"space-between"} py={"16px"}>
                <Text color={"brand.textMuted"}>Status</Text>
                <Text textTransform={"capitalize"} color={statusClass()}>
                  {status}
                </Text>
              </Flex>
              <Flex justify={"space-between"} py={"16px"}>
                <Text color={"brand.textMuted"}>Transaction ID</Text>
                <Flex align={"center"} gap={1}>
                  <Text color={"brand.textMuted"}>{txnId}</Text>
                  <Box
                    cursor={"pointer"}
                    onClick={() => handleCopyClick(txnId)}
                  >
                    {isCopied ? <AiOutlineCheck color="green" /> : <LuCopy />}
                  </Box>
                </Flex>
              </Flex>
            </Box>
            {isCreditTxn && status?.toLowerCase() === "pending" ? (
              <Button
                variant={"light"}
                fontWeight={400}
                onClick={redirectToCheckout}
                w={"full"}
                mt={"35px"}
              >
                Checkout
              </Button>
            ) : (
              ""
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      {/* 
      <Modal isOpen={showTxnModal} onClose={() => setShowTxnModal(false)}>
        <h2 className={`${historyStyles.modalAmount} satoshi-text`}>
          {isCreditTxn ? "+" + amount : "-" + amount} {currency.toUpperCase()}
        </h2>
        <div
          className={`${historyStyles.pill} ${
            isCreditTxn ? "iconBg-success" : "iconBg-danger"
          }`}
        >
          <div className={historyStyles.pillIcon}>
            <img src={isCreditTxn ? WalletAdd : WalletMinus} alt="" />
          </div>
          <p>
            <span className="capitalize">{type}</span> | {newDate}
          </p>
        </div>
        <div className={historyStyles.modalBody}>
          <div
            className={`flexRow justifyBetween ${historyStyles.modalItemRow}`}
          >
            <p className="text-muted">Transaction method</p>
            <p className="capitalize">{method.replace(/_/g, " ")}</p>
          </div>

          <div
            className={`flexRow justifyBetween ${historyStyles.modalItemRow}`}
          >
            <p className="text-muted">Status</p>
            <p className={"capitalize " + statusClass()}>{status}</p>
          </div>
          <div
            className={`flexRow justifyBetween ${historyStyles.modalItemRow}`}
          >
            <p className="text-muted">Transaction ID</p>
            <div className="flexRow alignCenter gap-1">
              <p className="text-muted">{txnId}</p>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => handleCopyClick(txnId)}
              >
                {isCopied ? <AiOutlineCheck color="green" /> : <LuCopy />}
              </div>
            </div>
          </div>
          {status?.toLowerCase() === "pending" && (
            <div>
              <Button onClick={redirectToCheckout}>Checkout</Button>
            </div>
          )}
        </div>
      </Modal> */}
    </>
  );
};

export default TransactionHistory;

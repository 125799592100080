import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { ReactNode } from "react";

const CustomModal = ({
  isOpen,
  onClose,
  children,
  title,
  bodyPy = "30px",
}: {
  isOpen: boolean;
  title?: string;
  children: ReactNode;
  bodyPy?: string;
  onClose: () => void;
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={{ base: "xs", md: "md" }}
      isCentered
    >
      <ModalOverlay />
      <ModalContent rounded={"16px"} bg={{ base: "#fff", md: "#fff" }}>
        {title ? <ModalHeader>{title}</ModalHeader> : ""}
        <ModalCloseButton />
        <ModalBody py={bodyPy} px={{ base: 3, md: 6 }}>
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;

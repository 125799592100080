import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import useCopyToClipBoard from "../../../hooks/useCopyToClipboard";
import {
  useActivateTFAMutation,
  useDeactivateTFAMutation,
  useRequestTFAQuery,
} from "../../../redux/services/twoFAApi";
import { useDispatch } from "react-redux";
import { showError, showSuccess } from "../../../utils/Alert";
import { accountApi } from "../../../redux/services/accountApi";
import QRCode from "qrcode";
import Loader from "../../../utils/Loader";
import { FaCheck } from "react-icons/fa";
import { GoCopy } from "react-icons/go";
import useTextTruncate from "../../../hooks/useTextTruncate";

const TwoFactorAuthenticationModal = ({
  isOpen,
  onClose,
  is2faActive,
}: {
  isOpen: boolean;
  onClose: () => void;
  is2faActive: boolean;
}) => {
  const [skipTFARequest, setSkipTFARequest] = useState(true);
  const { handleCopyClick, isCopied } = useCopyToClipBoard();
  const { truncateText } = useTextTruncate();
  const [activateTFA, { isLoading: isActivateTFALoading }] =
    useActivateTFAMutation();
  const [deactivateTFA, { isLoading: isDeactivateTFALoading }] =
    useDeactivateTFAMutation();
  const [code, setCode] = useState("");
  const dispatch = useDispatch();

  const {
    data: tfa,
    isSuccess: isTfaSuccess,
    isLoading: isTfaLoading,
  } = useRequestTFAQuery("", { skip: skipTFARequest });

  useEffect(() => {
    if (isOpen && !is2faActive) {
      setSkipTFARequest(false);
    }
  }, [isOpen, is2faActive]);

  const [qr, setQr] = useState("");
  const GenQRCode = useCallback(() => {
    //   @ts-ignore
    QRCode?.toDataURL(
      //   @ts-ignore
      tfa ? tfa?.uri || tfa?.binding?.uri : " ",
      {
        width: "100%",
        color: {
          dark: "#000",
          light: "#fff",
        },
      },
      (err: any, url: any) => {
        if (err) return console.error(err);

        setQr(url);
      }
    );
  }, [tfa]);

  useEffect(() => {
    if (isTfaSuccess) {
      GenQRCode();
    }
  }, [isTfaSuccess, GenQRCode]);

  const handleSubmit = async () => {
    if (code === "") {
      showError("Enter authenticator code");
      return;
    }

    if (is2faActive) {
      await deactivateTFA({ code })
        .unwrap()
        .then((resp) => {
          console.log(resp);
          showSuccess("Token deactivation successful");
          dispatch(accountApi.util.invalidateTags(["profile"]));
          localStorage.removeItem("TFAVerified");
          onClose();
        })
        .catch((err) => {
          console.log(err);
          showError(
            err?.message ||
              err?.data?.message ||
              "An error occurred, try again later"
          );
        });
    } else {
      await activateTFA({ code, secret: tfa?.secret || tfa?.binding?.secret })
        .unwrap()
        .then((resp) => {
          console.log(resp);
          showSuccess("Token activation successful");
          localStorage.setItem("TFAVerified", JSON.stringify(true));
          dispatch(accountApi.util.invalidateTags(["profile"]));
          onClose();
        })
        .catch((err) => {
          console.log(err);
          showError(err?.message || err?.data?.message || "");
        });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={{ base: "xs", md: "md" }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={"1.17rem"}>
          {is2faActive ? "Disable" : "Enable"} 2FA Authentication
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {is2faActive ? (
            <>
              <FormControl>
                <FormLabel
                  fontSize={"12px"}
                  fontWeight={"semibold"}
                  color={"#968686"}
                >
                  To disable 2FA authentication, please enter the security code
                  from your Authenticator App.
                </FormLabel>
                <Input
                  placeholder={"Enter code to verify"}
                  focusBorderColor={"gray.300"}
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                />
              </FormControl>
            </>
          ) : (
            <>
              <Loader isLoading={isTfaLoading} height={"100px"} />
              {!isTfaLoading && (
                <Flex flexDirection={"column"} gap={5}>
                  <Flex
                    flexDirection={"column"}
                    gap={5}
                    color={"#868686"}
                    fontSize={"14px"}
                  >
                    <Text>
                      Step 1: Download and install an Authenticator App for your
                      phone or tablet
                    </Text>

                    <Text>
                      Step 2: Scan below QR code with the app, or you can add
                      account manually into the app
                    </Text>
                  </Flex>

                  <Box>
                    <hr />
                  </Box>

                  <Flex justifyContent={"space-between"}>
                    <Flex flexDirection={"column"} gap={5}>
                      <Heading size={"sm"}>Manually add account:</Heading>

                      <Box>
                        <Text display={"flex"} gap={"2px"} fontSize={"12px"}>
                          <Text color={"#868686"} as={"span"}>
                            Account Name:
                          </Text>{" "}
                          {tfa?.friendlyName}
                        </Text>
                        <Box>
                          <Flex
                            flexDirection={"column"}
                            mb={2}
                            position={"relative"}
                          >
                            <FormLabel
                              fontSize={"12px"}
                              fontWeight={"semibold"}
                              color={"#868686"}
                            >
                              Your Key:
                            </FormLabel>
                            <Flex flexDirection={"column"} gap={"10px"}>
                              <Input
                                focusBorderColor={"gray.300"}
                                px={2}
                                value={truncateText(
                                  tfa?.secret || tfa?.binding?.secret,
                                  14
                                )}
                                readOnly={true}
                                fontSize={"14px"}
                              />
                              <Box
                                position={"absolute"}
                                top={"38px"}
                                right={"10px"}
                                color={isCopied ? "brand.success" : "#868686"}
                                cursor={"pointer"}
                                onClick={() =>
                                  handleCopyClick(
                                    tfa?.secret || tfa?.binding?.secret
                                  )
                                }
                              >
                                <Box fontSize="md" fontWeight={"semibold"}>
                                  {isCopied ? <FaCheck /> : <GoCopy />}
                                </Box>
                              </Box>
                            </Flex>
                          </Flex>
                        </Box>
                      </Box>
                    </Flex>

                    <Box>
                      <img src={qr} width={120} height={120} alt="" />
                    </Box>
                  </Flex>

                  <Box>
                    <hr />
                  </Box>

                  <Box>
                    <FormControl>
                      <FormLabel fontSize={"12px"} fontWeight={"semibold"}>
                        Enter Authenticator Code
                      </FormLabel>
                      <Input
                        placeholder={"Enter code to verify"}
                        focusBorderColor={"gray.300"}
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                      />
                    </FormControl>
                  </Box>
                </Flex>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          {!isTfaLoading ? (
            <Button
              size={"lg"}
              fontSize={"16px"}
              fontWeight={400}
              colorScheme={!is2faActive ? "buttonPrimary" : ""}
              variant={is2faActive ? "danger" : "solid"}
              isLoading={isActivateTFALoading || isDeactivateTFALoading}
              w={"full"}
              onClick={handleSubmit}
            >
              {is2faActive ? "Disable" : "Confirm & Enable"}
            </Button>
          ) : (
            ""
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TwoFactorAuthenticationModal;

import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./customFetchBase";
import { FollowersObj, ProfileObj, UserProfileObj } from "./types";

export const accountApi = createApi({
  reducerPath: "accountApi",
  baseQuery,
  tagTypes: ["profile", "profileII"],
  endpoints: (builder) => ({
    fetchProfile: builder.query<UserProfileObj, void>({
      query: () => `/user`,
      providesTags: ["profile"],
      transformResponse: (results: { data: any }) => results.data,
    }),
    fetchProfileII: builder.query<ProfileObj[], void>({
      query: () => `/profile`,
      providesTags: ["profileII"],
      transformResponse: (results: { data: ProfileObj[] }) => results.data,
    }),
    fetchFollowers: builder.query<FollowersObj[], void>({
      query: () => `/followers`,
      transformResponse: (results: { data: FollowersObj[] }) => results.data,
    }),
    fetchReferrals: builder.query<any, void>({
      query: () => `/referrals`,
      transformResponse: (results: { data: any }) => results.data,
    }),
    updateProfile: builder.mutation({
      query: (data) => ({
        url: `profile/${data.uid}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["profileII", "profile"],
    }),
    registerAffiliate: builder.mutation({
      query: (data) => ({
        url: `auth/affiliate`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useFetchProfileQuery,
  useFetchProfileIIQuery,
  useFetchFollowersQuery,
  useFetchReferralsQuery,
  useUpdateProfileMutation,
  useRegisterAffiliateMutation,
} = accountApi;

import React, { useState } from "react";
import LayoutContainerWrapper from "../../../../components/dashboard/LayoutContainerWrapper";
import ContactCard from "../../../../components/cards/ContactCard";
import { useNavigate } from "react-router-dom";
import LeftArrow from "../../../../assets/images/icons/arrow-left.png";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
} from "@chakra-ui/react";
import { ChangeEvent } from "react";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import useCheckEmptyFields from "../../../../hooks/useCheckEmptyFields";
import { showError } from "../../../../utils/Alert";

const PasswordReset = () => {
  const [checkEmptyFields] = useCheckEmptyFields();
  const navigate = useNavigate();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleOldPasswordVisibility = () => {
    setShowOldPassword(!showOldPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const [formState, setFormState] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const handleInputs = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = () => {
    const { oldPassword, newPassword, confirmPassword } = formState;

    const isErr = checkEmptyFields({
      confirm_password: confirmPassword,
      new_password: newPassword,
      old_password: oldPassword,
    });

    if (!isErr) {
      if (newPassword !== confirmPassword) {
        showError("Confirm password does not match new password");
        return;
      }

      // do smth
    }
  };

  return (
    <LayoutContainerWrapper asideChildren={<Aside />}>
      <Flex
        flexDirection={"column"}
        gap={"10px"}
        justifyContent={"center"}
        m={"auto"}
      >
        <Flex alignItems={"center"} mb={"10px"}>
          <img
            src={LeftArrow}
            alt="left direction arrow"
            width={20}
            height={20}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
          />
          <Heading
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            m={"auto"}
            fontSize={"21px"}
            fontWeight={"semibold"}
          >
            Password reset
          </Heading>
        </Flex>

        {/* form */}
        <Flex alignItems={"center"} justifyContent={"center"} m={"auto"}>
          <Flex direction={"column"} gap={"30px"}>
            <Flex gap={"10px"} alignItems={"center"} position={"relative"}>
              <Box
                position={"absolute"}
                top={"42px"}
                right={{ base: "2", md: "10px" }}
                zIndex={1}
                cursor={"pointer"}
              >
                {showOldPassword ? (
                  <IoEyeOffOutline onClick={toggleOldPasswordVisibility} />
                ) : (
                  <IoEyeOutline onClick={toggleOldPasswordVisibility} />
                )}
              </Box>
              <FormControl isRequired>
                <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
                  Old Password
                </FormLabel>
                <Input
                  type={showOldPassword ? "text" : "password"}
                  focusBorderColor={"gray.300"}
                  w={{ base: "300px", md: "450px" }}
                  value={formState.oldPassword}
                  name="oldPassword"
                  onChange={handleInputs}
                />
              </FormControl>
            </Flex>

            <Flex gap={"10px"} alignItems={"center"} position={"relative"}>
              <Box
                position={"absolute"}
                top={"42px"}
                right={{ base: "2", md: "10px" }}
                zIndex={1}
                cursor={"pointer"}
              >
                {showNewPassword ? (
                  <IoEyeOffOutline onClick={toggleNewPasswordVisibility} />
                ) : (
                  <IoEyeOutline onClick={toggleNewPasswordVisibility} />
                )}
              </Box>
              <FormControl isRequired>
                <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
                  New Password
                </FormLabel>
                <Input
                  type={showNewPassword ? "text" : "password"}
                  focusBorderColor={"gray.300"}
                  w={{ base: "300px", md: "450px" }}
                  value={formState.newPassword}
                  name="newPassword"
                  onChange={handleInputs}
                />
              </FormControl>
            </Flex>

            <Flex gap={"10px"} alignItems={"center"} position={"relative"}>
              <Box
                position={"absolute"}
                top={"42px"}
                right={{ base: "2", md: "10px" }}
                zIndex={1}
                cursor={"pointer"}
              >
                {showConfirmPassword ? (
                  <IoEyeOffOutline onClick={toggleConfirmPasswordVisibility} />
                ) : (
                  <IoEyeOutline onClick={toggleConfirmPasswordVisibility} />
                )}
              </Box>
              <FormControl isRequired>
                <FormLabel fontSize={"14px"} fontWeight={"semibold"}>
                  Confirm Password
                </FormLabel>
                <Input
                  type={showConfirmPassword ? "text" : "password"}
                  focusBorderColor={"gray.300"}
                  w={{ base: "300px", md: "450px" }}
                  value={formState.confirmPassword}
                  name="confirmPassword"
                  onChange={handleInputs}
                />
              </FormControl>
            </Flex>

            <Box display="flex" justifyContent="flex-end" gap={4}>
              <Button
                display={{ base: "inline", md: "block" }}
                w={"auto"}
                h={"40px"}
                size={"lg"}
                fontSize={"13px"}
                fontWeight={400}
                colorScheme={"buttonPrimary"}
                color={"white"}
                onClick={handleSubmit}
              >
                Reset
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </LayoutContainerWrapper>
  );
};

const Aside = () => {
  return <ContactCard />;
};

export default PasswordReset;

import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { accountApi } from "../../../redux/services/accountApi";
import {
  useActivateEmailMFAMutation,
  useDeactivateEmailMFAMutation,
  useRequestEmailOtpQuery,
} from "../../../redux/services/twoFAApi";
import { showError, showSuccess } from "../../../utils/Alert";
import Loader from "../../../utils/Loader";

const EmailForTwoFactorAuthenticationModal = ({
  isOpen,
  onClose,
  is2faActive,
}: {
  isOpen: boolean;
  onClose: () => void;
  is2faActive: boolean;
}) => {

  const [skipTFARequest, setSkipTFARequest] = useState(true);
  const [activateTFA, { isLoading: isActivateTFALoading }] =
    useActivateEmailMFAMutation();
  const [deactivateTFA, { isLoading: isDeactivateTFALoading }] =
    useDeactivateEmailMFAMutation();
  const [code, setCode] = useState("");
  const dispatch = useDispatch();

  const {
    data: emailOtpReq,
    isError: isEmailOtpRequestError,
    isLoading: isEmailOtpLoading,
  } = useRequestEmailOtpQuery("", { skip: skipTFARequest });

  console.log("otp sent", emailOtpReq)

  useEffect(() => {
    if (isOpen) {
      setSkipTFARequest(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isEmailOtpRequestError) {
      showError("Error, could not send code");
      onClose();
    }
  }, [isEmailOtpRequestError, onClose]);

  const handleSubmit = async () => {
    if (code === "") {
      showError("Enter security code");
      return;
    }

    if (is2faActive) {
      await deactivateTFA({ code })
        .unwrap()
        .then((resp) => {
          console.log(resp);
          showSuccess("Token deactivation successful");
          dispatch(accountApi.util.invalidateTags(["profile"]));
          onClose();
        })
        .catch((err) => {
          console.log(err);
          showError(err?.message || err?.data?.message || "");
        });
    } else {
      await activateTFA({ code })
        .unwrap()
        .then((resp) => {
          console.log(resp);
          showSuccess("Token activation successful");
          dispatch(accountApi.util.invalidateTags(["profile"]));
          onClose();
        })
        .catch((err) => {
          console.log(err);
          showError(err?.message || err?.data?.message || "");
        });
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={{ base: "xs", md: "md" }}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={"1.17rem"}>
          {is2faActive ? "Disable" : "Enable"} Email Authentication
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Loader isLoading={isEmailOtpLoading} height={"100px"} />
          {!isEmailOtpLoading && (
            <FormControl>
              <FormLabel
                fontSize={"12px"}
                fontWeight={"semibold"}
                color={"#968686"}
              >
                To disable email authentication, please enter the security code
                we emailed to you.
              </FormLabel>
              <Input
                placeholder={"Enter the code to verify"}
                focusBorderColor={"gray.300"}
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </FormControl>
          )}
        </ModalBody>
        <ModalFooter>
          {!isEmailOtpLoading ? (
            <Button
              size={"lg"}
              fontSize={"16px"}
              fontWeight={400}
              colorScheme={!is2faActive ? "buttonPrimary" : ""}
              variant={is2faActive ? "danger" : "solid"}
              isLoading={isActivateTFALoading || isDeactivateTFALoading}
              w={"full"}
              onClick={handleSubmit}
            >
              {is2faActive ? "Disable" : "Confirm & Enable"}
            </Button>
          ) : (
            ""
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EmailForTwoFactorAuthenticationModal;

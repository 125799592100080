import React, { useState } from "react";
import { Box, Button, Heading, Text, Flex, Stack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import BeneficiariesModal from "../../components/beneficiaries/BeneficiariesModal";
import LayoutContainerWrapper from "../../components/dashboard/LayoutContainerWrapper";
import ContactCard from "../../components/cards/ContactCard";
import LeftArrow from "../../assets/images/icons/arrow-left.png";
import {
  useFetchBankBeneficiariesQuery,
  useFetchCryptoBeneficiariesQuery,
} from "../../redux/services/beneficiariesApi";
import Loader from "../../utils/Loader";
import BankBeneficiaryRow from "../../components/beneficiaries/BankBeneficiaryRow";
import CryptoBeneficiaryRow from "../../components/beneficiaries/CryptoBeneficiaryRow";

const ManageBeneficiaries: React.FC = () => {
  const [activeTab, setActiveTab] = useState("bank");
  const { data: bankBeneficiaries, isLoading: isBankBeneLoading } =
    useFetchBankBeneficiariesQuery();
  const { data: cryptoBeneficiaries, isLoading: isCryptoBeneLoading } =
    useFetchCryptoBeneficiariesQuery();

  //   console.log("bank, ben", bankBeneficiaries);
  //   console.log("crypt, ben", cryptoBeneficiaries);

  const renderElem = () => {
    if (activeTab === "crypto") {
      return (
        <>
        <Loader
          isLoading={isCryptoBeneLoading}
          height="100px"
          mt={0}
        />
          {!cryptoBeneficiaries?.length && !isCryptoBeneLoading ? (
            <Text fontSize={"15px"} textAlign={"center"} my={"50px"}>
              You have not added any crypto beneficiary yet.
            </Text>
          ) : (
            ""
          )}
          {cryptoBeneficiaries?.map((value: any) => (
            <CryptoBeneficiaryRow key={value?.id} beneficiary={value} />
          ))}
        </>
      );
    } else {
      return (
        <>
        <Loader
          isLoading={isBankBeneLoading}
          height="100px"
          mt={0}
        />
          {!bankBeneficiaries?.length && !isBankBeneLoading ? (
            <Text fontSize={"15px"} textAlign={"center"} my={"50px"}>
              You have not added any bank transfer beneficiary yet.
            </Text>
          ) : (
            ""
          )}
          {bankBeneficiaries?.map((value) => (
            <BankBeneficiaryRow key={value?.id} beneficiary={value} />
          ))}
        </>
      );
    }
  };

  return (
    <LayoutContainerWrapper asideChildren={<Aside />}>
      <Flex alignItems={"center"} mb={"10px"}>
        <Link to="/settings">
          <img
            src={LeftArrow}
            alt="left direction arrow"
            width={20}
            height={20}
          />
        </Link>
        <Heading
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          m={"auto"}
          fontSize={"21px"}
          fontWeight={"semibold"}
        >
          Manage beneficiaries
        </Heading>
      </Flex>
      <Box display={"flex"} flexDirection={"column"} gap={5}>
        <Stack
          direction={{ base: "column", md: "column" }}
          spacing={{ base: "4", md: "8" }}
          align={{ base: "center", md: "center" }}
          justify={{ md: "space-between" }}
        >
          <Box
            background={"#E8E8E8"}
            display={"flex"}
            gap={2}
            rounded={"30px"}
            py={"1"}
            px={"1"}
            w={{
              sm: "90%",
              md: "auto",
            }}
          >
            <Button
              w={{
                base: "30vw",
                md: "15vw",
              }}
              rounded="20px"
              py="2"
              px="2"
              color={activeTab === "bank" ? "white" : "#232A44"}
              bg={activeTab === "bank" ? "#232A44" : "#f0f0f0"}
              _hover={{
                bg: activeTab === "bank" ? "#232A44" : "#f0f0f0",
              }}
              fontSize={"13px"}
              fontWeight={400}
              onClick={() => setActiveTab("bank")}
            >
              Bank Transfers
            </Button>

            <Button
              w={{
                base: "30vw",
                md: "15vw",
              }}
              rounded="20px"
              py="2"
              px="2"
              color={activeTab === "crypto" ? "white" : "#232A44"}
              bg={activeTab === "crypto" ? "#232A44" : "#f0f0f0"}
              fontSize={"13px"}
              fontWeight={400}
              _hover={{
                bg: activeTab === "crypto" ? "#232A44" : "#f0f0f0",
              }}
              onClick={() => setActiveTab("crypto")}
            >
              Crypto
            </Button>
          </Box>
        </Stack>
        {/* beneficiaries */}
        <Box>{renderElem()}</Box>

        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          mb={5}
          w={{
            sm: "90%",
            md: "auto",
          }}
          mt={5}
        >
          <BeneficiariesModal activeTab={activeTab} />
        </Box>
      </Box>
    </LayoutContainerWrapper>
  );
};

const Aside = () => {
  return (
    <>
      <ContactCard />
    </>
  );
};

export default ManageBeneficiaries;

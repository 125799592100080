import React, { useEffect, useState } from "react";
import { Flex, Box, Text } from "@chakra-ui/react";
import BankIcon from "../../assets/images/icons/bank.png";
import useTextTruncate from "../../hooks/useTextTruncate";
import {
  useFetchSupportedCoinsQuery,
  useFetchSupportedBanksQuery,
} from "../../redux/services/beneficiariesApi";

const BankCard = ({
  beneficiary,
  type,
  onClick,
}: {
  beneficiary: any;
  type: string;
  onClick: () => void;
}) => {
  const { truncateText } = useTextTruncate();
  const [supportedBanks, setSupportedBanks] = useState<any[]>([]);
  const [supportedCryptos, setSupportedCryptos] = useState<any[]>([]);

  const { data: fetchedSuppCoins, isSuccess: isFetchedSuppCoinsSuccess } =
    useFetchSupportedCoinsQuery();
  const { data: fetchedSuppBanks, isSuccess: isFetchedSuppBanksSuccess } =
    useFetchSupportedBanksQuery();

  //   process fetched supported banks
  useEffect(() => {
    if (isFetchedSuppBanksSuccess) {
      const newArray = fetchedSuppBanks?.map((bank: any) => ({
        ...bank,
        value: bank?.code,
      }));

      setSupportedBanks(newArray);
    }
  }, [isFetchedSuppBanksSuccess, fetchedSuppBanks]);

  //   process fetched supported coins
  useEffect(() => {
    if (isFetchedSuppCoinsSuccess) {
      const newArray = fetchedSuppCoins?.map((crypto) => ({
        ...crypto,
        value: crypto.id,
        name: crypto.name.toUpperCase(),
        icon: crypto.logo_url,
      }));

      setSupportedCryptos(newArray);
      //   console.log("supp crypto", fetchedSuppCoins);
    }
  }, [isFetchedSuppCoinsSuccess, fetchedSuppCoins]);

  const returnCoin = (coin_id: any) => {
    const coin = supportedCryptos.filter((val) => val.id === coin_id);
    if (coin.length) {
      return coin[0];
    }

    return null;
  };

  const returnBankName = (bank_code: any) => {
    const bank = supportedBanks.filter((val) => val?.code === bank_code);
    if (bank.length) {
      return bank[0]?.name;
    }

    return null;
  };

  const coinInfo = returnCoin(beneficiary?.coin_id);
  const title =
    beneficiary?.title || beneficiary?.account_name || beneficiary?.address;
  const subLabel =
    type === "bank"
      ? returnBankName(beneficiary?.bank_code || "") || "Account number"
      : coinInfo?.name;
  const subtitle =
    type === "bank"
      ? beneficiary?.account_number
      : beneficiary?.network + " network";
  const icon = coinInfo?.logo_url || BankIcon;

  return (
    <>
      <Flex
        justifyContent={"flex-start"}
        cursor="pointer"
        mb={"10px"}
        bg={"brand.white"}
        rounded={"16px"}
        shadow={"0px 4px 16px 0px rgba(0, 0, 0, 0.06)"}
        padding={"18px 24px"}
        onClick={onClick}
      >
        <Box boxSize={"44px"}>
          <img
            src={icon}
            width={"70%"}
            height={"70%"}
            style={{ objectFit: "contain" }}
            alt="bank"
          />
        </Box>
        <Box>
          <Text
            fontSize={"17px"}
            fontWeight={500}
            textTransform={"capitalize"}
            title={title}
          >
            {truncateText(title, 15)}
          </Text>
          <Text fontSize={"14px"}>
            <small>{subLabel}:</small> <b>{truncateText(subtitle, 16)}</b>
          </Text>
        </Box>
      </Flex>
    </>
  );
};

export default BankCard;

import { useEffect, useState } from "react";
import {
  // Box,
  // Button,
  // Checkbox,
  // FormControl,
  // Image,
  // Input,
  // PopoverFooter,
  Flex,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverHeader,
  Text,
  Image,
} from "@chakra-ui/react";
import {
  onValue,
  ref,
  query,
  orderByKey,
  limitToLast,
} from "firebase/database";
import { database } from "../../firebase";
import { useFetchProfileQuery } from "../../redux/services/accountApi";
import { showError } from "../../utils/Alert";
import useTextTruncate from "../../hooks/useTextTruncate";
import NotiIcon from "../../assets/images/icons/notification.png";
import WalletAdd from "../../assets/images/icons/wallet-add.png";
import WalletMinus from "../../assets/images/icons/wallet-minus.png";

const NotificationModal = () => {
  const [notifications, setNotifications] = useState<any[]>([]);
  const { data: user } = useFetchProfileQuery();
  const { truncateText } = useTextTruncate();

  // fetch messages
  useEffect(() => {
    const notifRef = ref(database, `notifications/users/${user?.uid}`);
    const notifQuery = query(notifRef, orderByKey(), limitToLast(10)); // Limiting to last 10

    const unsubscribe = onValue(
      notifQuery,
      (snapshot) => {
        if (snapshot.exists()) {
          const notificationData = snapshot.val();
          const filteredTransactions = Object.keys(notificationData)
            .map((key) => {
              const { subject, body, topic, timestamp, message } =
                notificationData[key] || {};
              return {
                id: key,
                title: subject,
                body,
                message,
                type: topic,
                time: timestamp, // Convert time to Date object
              };
            })
            .sort((a, b) => {
              if (!a.time && !b.time) return 0;
              if (!a.time) return 1;
              if (!b.time) return -1;

              const dateA = new Date(a.time);
              const dateB = new Date(b.time);

              // @ts-ignore
              return dateB - dateA;
            });
          setNotifications(filteredTransactions);
          // console.log(filteredTransactions);
        }
      },
      (error) => {
        console.error(error);
        showError("Could not fetch notifications");
      }
    );

    return () => unsubscribe();
  }, [user]);

  return (
    <>
      <PopoverArrow />
      <PopoverCloseButton top="18px" color={"#DE2C4C"} />
      <PopoverHeader
        fontSize={"22px"}
        fontWeight={700}
        borderBottom="none"
        padding={"18px 18px 16px"}
      >
        Notifications
      </PopoverHeader>
      <PopoverBody paddingTop={0} paddingInline={"18px"} paddingBottom={"18px"}>
        <Flex
          flexDirection={"column"}
          gap={4}
          style={{
            border: "1px solid #E8E8E8",
            borderRadius: "8px",
            padding: "20px 10px",
          }}
        >
          {!notifications?.length ? (
            <Text textAlign={"center"} my={"50px"} color={"brand.textMuted"}>
              You have no new notification
            </Text>
          ) : (
            ""
          )}
          {notifications?.map((val) => {
            let iconBgColor = "brand.secondary";
            let icon = NotiIcon;
            let newDate = new Date(val?.time);
            // @ts-ignore
            newDate = newDate.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            });

            if (
              val?.type?.toLowerCase() === "deposit" ||
              val?.type?.toLowerCase() === "jackpot win"
            ) {
              iconBgColor = "brand.successTint";
              icon = WalletAdd;
            }

            if (
              val?.type?.toLowerCase() === "withdraw" ||
              val?.type?.toLowerCase() === "ticket sale"
            ) {
              iconBgColor = "brand.dangerTint";
              icon = WalletMinus;
            }

            return (
              <Flex
                key={val?.id}
                justify={"space-between"}
                padding={"16px 0"}
                cursor={"pointer"}
                _hover={{ bg: "rgba(243, 243, 243, 0.3)" }}
              >
                <Flex align={"center"}>
                  <Flex
                    boxSize={["32px", "48px"]}
                    rounded={"50%"}
                    justify={"center"}
                    alignItems={"center"}
                    mr={"10px"}
                    bg={iconBgColor}
                  >
                    <Image
                      src={icon}
                      width={["18px", "24px"]}
                      height={["18px", "24px"]}
                      alt=""
                    />
                  </Flex>
                  <Flex direction={"column"} justify={"center"}>
                    <Text
                      textTransform={"capitalize"}
                      fontSize={"14px"}
                      marginBottom={"5px"}
                      fontWeight={500}
                    >
                      {truncateText(val?.message, 35)}
                    </Text>
                    <Text fontSize={"14px"} fontWeight={500} color={"#727272"}>
                      {/* @ts-ignore */}
                      {newDate}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      </PopoverBody>
    </>
  );
};

export default NotificationModal;

import { useEffect, useState } from "react";
import { Flex, Button, useToast } from "@chakra-ui/react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomInput from "../../utils/CustomInput";
import AuthLayout from "./AuthLayout";
import useCheckEmptyFields from "../../hooks/useCheckEmptyFields";
import { useFetchProfileQuery } from "../../redux/services/accountApi";
import useAlerts from "../../hooks/useAlerts";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../firebase";
import { handleFirebaseError } from "../../utils/Helpers";

const Login = ({ signInEmail }: { signInEmail?: string }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [checkEmptyFields] = useCheckEmptyFields();
  const [userSkip, setUserSkip] = useState(true);
  const { showError, showSuccess } = useAlerts();
  const toast = useToast();
  const from = location.state?.from?.pathname || "/dashboard";
  const [formState, setFormState] = useState({
    email: signInEmail || "",
    password: "",
    loading: false,
  });

  const handleInputs = (e: any) => {
    const name = e.target.name;
    const value = e.target.value;

    setFormState({ ...formState, [name]: value });
  };

  const {
    data: user,
    error: userError,
    isSuccess: isFetchUserSuccess,
  } = useFetchProfileQuery(undefined, { skip: userSkip });

  useEffect(() => {
    if (userError) {
      const msg =
        // @ts-ignore
        userError?.message ||
        // @ts-ignore
        userError?.data?.message ||
        "An error occurred, could not validate user";
      setFormState((prevState) => ({ ...prevState, loading: false }));
      toast({
        position: "top-right",
        title: msg,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      // invalidate
      localStorage.removeItem("axxToken");
      console.log(userError);
    }
  }, [userError, toast]);

  useEffect(() => {
    if (isFetchUserSuccess) {
      setFormState((prevState) => ({ ...prevState, loading: false }));
      const isWalletPinActive = user?.user?.security?.wallet_pin;
      const isAuthApp2faActive =
        user?.user?.security && user?.user?.security["2fa"]
          ? user?.user?.security["2fa"]?.status === "verified"
          : false;
      if (!isWalletPinActive) {
        navigate("/activate-wallet-pin");
      } else if (isAuthApp2faActive) {
        navigate("/verify-2fa");
      } else {
        // sign user in
        showSuccess("Successful 👍");
        navigate(from);
      }
      // navigate(from);
    }
  }, [isFetchUserSuccess, user, navigate, showSuccess, showError, from]);

  const submitForm = async (e: any) => {
    e.preventDefault();
    const { email, password } = formState;

    const isFormEmpty = checkEmptyFields({
      email,
      password,
    });

    if (!isFormEmpty) {
      if (email !== signInEmail) {
        showError("Email must match signIn email");
        return;
      }

      setFormState({ ...formState, loading: true });
      try {
        const res = await signInWithEmailAndPassword(auth, email, password);
        // @ts-ignore
        localStorage.setItem("axxToken", res?.user?.accessToken);
        setUserSkip(false);

        // rest is handled in isFetchUserSuccess useEffect
      } catch (error) {
        handleFirebaseError(error);
        setFormState({ ...formState, loading: false });
      }
    }
  };

  return (
    <AuthLayout heading="Login">
      <form onSubmit={submitForm}>
        <CustomInput
          label="Your email"
          name="email"
          type="email"
          value={formState.email}
          onChange={handleInputs}
          readOnly={true}
          mb="13px"
        />
        <CustomInput
          label="Password"
          name="password"
          type="password"
          value={formState.password}
          onChange={handleInputs}
          mb="13px"
        />
        <Flex justify={"flex-end"}>
          <Button
            variant={"link"}
            _hover={{ textDecor: "none" }}
            as={Link}
            to={"/forgot-password"}
            fontWeight={500}
          >
            Forgot password?
          </Button>
        </Flex>
        <Button
          type="submit"
          colorScheme={"buttonPrimary"}
          mt={"29px"}
          w={"full"}
          height={"48px"}
          fontWeight={500}
          isLoading={formState.loading}
          rounded="8px"
        >
          Sign in
        </Button>
      </form>
    </AuthLayout>
  );
};

export default Login;

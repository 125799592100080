import React from "react";
import {Box, Flex, Heading, Text} from "@chakra-ui/react";
import {Link} from "react-router-dom";
import { useFetchProfileQuery } from "../../../redux/services/accountApi";

const Security = () => {
  const { data: user } = useFetchProfileQuery();
  const isEmail2faActive = user?.user?.security?.email;
  const isAuthApp2faActive = user?.user?.security["2fa"]
    ? user?.user?.security["2fa"]?.status === "verified"
    : false;

  return (
    <>
      <Heading
        size={"sm"}
        fontSize={"14px"}
        color={"#868686"}
        fontWeight={600}
        textAlign={"left"}
      >
        Security
      </Heading>
      <Box display={"flex"} flexDirection={"column"} gap={5}>
        {/* <Link to={"/settings/#"}>
          <Box>Allow Notification</Box>
        </Link> */}
        <Link to={"/settings/password-reset"}>
          <Box>Password Reset</Box>
        </Link>
        <Link to={"/settings/security-pin"}>
          <Box>Wallet Pin</Box>
        </Link>
        <Link to={"/settings/2fa"}>
          <Box>
            <Flex alignItems={"center"} justifyContent={"space-between"}>
              <Text>Two-Factor Authentication</Text>
              <Text
                bg={
                  isAuthApp2faActive || isEmail2faActive ? "#06C167" : "#F3F3F3"
                }
                color={
                  isAuthApp2faActive || isEmail2faActive ? "white" : "#000"
                }
                py={"7px"}
                px={"10px"}
                rounded={"full"}
                fontSize={"12px"}
                mr={{ base: "10px", md: "0" }}
              >
                Enabled
              </Text>
            </Flex>
          </Box>
        </Link>
      </Box>
    </>
  );
};

export default Security;
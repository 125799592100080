import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import OldPinEntryModal from "../../components/OldPinEntryModal";
import useAlerts from "../../hooks/useAlerts";
import { accountApi } from "../../redux/services/accountApi";
import { useActivateWalletPinMutation } from "../../redux/services/walletApi";

const ActivateWalletPin = () => {
  const [pin1, setPin1] = useState("");
  const [formStep, setFormStep] = useState(1);
  const { showError, showSuccess } = useAlerts();
  const navigate = useNavigate();
  const token = localStorage.getItem("axxToken");
  const dispatch = useDispatch();

  const [activateWalletPin, { isLoading: isActivateWalletLoading }] =
    useActivateWalletPinMutation();

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [navigate, token]);

  const handleSubmit1 = (pin: string, resetPin: () => void) => {
    if (pin === "" || pin.length < 6) {
      showError("Enter your 6-digit wallet pin");
      return;
    }

    setPin1(pin);
    resetPin();
    setFormStep(formStep + 1);
  };

  const handleSubmit2 = async (pin: string, resetPin: () => void) => {
    if (pin === "" || pin.length < 6) {
      showError("Enter your 6-digit wallet pin");
      return;
    }

    if (pin !== pin1) {
      showError("Confirm pin does not match pin");
      setPin1("");
      resetPin();
      setFormStep(1);
      return;
    }

    await activateWalletPin({ pin, confirm_pin: pin })
      .unwrap()
      .then(() => {
        showSuccess("Pin activated successfully");
        dispatch(accountApi.util.invalidateTags(["profile"]));
        navigate("/dashboard");
      })
      .catch((err) => {
        console.log(err);
        showError(
          err?.message ||
            err?.data?.message ||
            "An error occured try again later"
        );
      });
  };

  const renderForm = () => {
    if (formStep === 1) {
      return (
        <OldPinEntryModal
          isOpen={true}
          onClose={() => null}
          onSubmit={handleSubmit1}
          subtitle={"Create a 6-Digit wallet PIN"}
        />
      );
    } else if (formStep === 2) {
      return (
        <OldPinEntryModal
          isOpen={true}
          onClose={() => null}
          onSubmit={handleSubmit2}
          title={"Confirm pin"}
          subtitle={"Create a 6-Digit wallet PIN"}
          isSubmitLoading={isActivateWalletLoading}
        />
      );
    }
  };

  return <>{renderForm()}</>;
};

export default ActivateWalletPin;

import { Button, Flex, Heading, Text } from "@chakra-ui/react";
import { Link, useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error: any = useRouteError();
  console.error(error);

  return (
    <Flex
      flexDirection={"column"}
      justify={"center"}
      height={"100vh"}
      align={"center"}
      gap={2}
      id="error-page"
    >
      <Heading>Oops!</Heading>
      <Text>Sorry, Something seems to be wrong.</Text>
      <Text color={"brand.textMuted"}>
        {error.status === 404 ? (
          <i>Page not Found</i>
        ) : (
          <i>{error.statusText || error.message}</i>
        )}
      </Text>
      <Button
        as={Link}
        to={"/dashboard"}
        fontWeight={400}
        mt={4}
        variant={"primary"}
      >
        Back to Dashboard
      </Button>
    </Flex>
  );
}

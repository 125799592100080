import { Box, Heading, Text } from "@chakra-ui/react";
import { useState } from "react";
import ContactCard from "../../components/cards/ContactCard";
import LayoutContainerWrapper from "../../components/dashboard/LayoutContainerWrapper";
import { useFetchTransactionsQuery } from "../../redux/services/walletApi";
import Loader from "../../utils/Loader";
import Pagination from "../../utils/Pagination";
import TransactionHistory from "../../utils/TransactionHistory";

const History = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const { data: transactionHistory, isLoading: isTransactionHistoryLoading } =
    useFetchTransactionsQuery(`page=${currentPage}`);

  return (
    <LayoutContainerWrapper asideChildren={<ContactCard />}>
      <Heading fontSize={"20px"}>Transactions history</Heading>
      <Box>
        <Loader isLoading={isTransactionHistoryLoading} height={"100px"} />
        {!transactionHistory?.data?.length && !isTransactionHistoryLoading ? (
          <Text color={"brand.textMuted"} textAlign={"center"} mt={"40px"}>
            You have not performed any transactions yet.
          </Text>
        ) : (
          ""
        )}
        {/* TODO: test 👇🏽👇🏽 */}
        {transactionHistory?.data?.map((value: any) => {
          let checkoutUrl = "";

          if (value?.currency === "ngn") {
            checkoutUrl = value?.meta?.data?.authorization_url;
          } else {
            if (value?.method === "credit_card") {
              checkoutUrl = value?.meta?.url;
            } else if (value?.method === "crypto") {
              checkoutUrl = value?.meta?.hosted_url;
            }
          }

          return (
            <TransactionHistory
              key={"tnx-" + value?.id}
              txnId={value?.id}
              type={value?.type}
              amount={value?.amount}
              currency={value?.currency}
              date={value?.createdAt?._seconds}
              status={value?.status}
              trnx={value}
              checkoutUrl={checkoutUrl}
            />
          );
        })}
        <Pagination
          limit={transactionHistory?.limit}
          curPage={currentPage}
          totalItems={transactionHistory?.total}
          paginate={(num: number) => setCurrentPage(num)}
        />
      </Box>
    </LayoutContainerWrapper>
  );
};

export default History;

import React, { useCallback, useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Flex,
  Heading,
  Text,
  Box,
  FormControl,
  FormLabel,
  NumberInput,
  NumberInputField,
  Select,
  InputRightAddon,
  InputGroup,
  Radio,
  Input,
} from "@chakra-ui/react";
import { PiBank } from "react-icons/pi";
import { useFetchProfileQuery } from "../../../redux/services/accountApi";
import Shield from "../../../assets/images/shield-keyhole-fill.svg";
import { Link } from "react-router-dom";
import BankIcon from "../../../assets/images/icons/bank.png";
import CardIcon from "../../../assets/images/icons/card.png";
import CryptoIcon from "../../../assets/images/icons/buy-crypto.png";
import {
  useCheckWalletPinMutation,
  useFetchSupportedCurrenciesQuery,
  useFetchWithdrawalMethodsQuery,
  useRequestWithdrawalMutation,
} from "../../../redux/services/walletApi";
import useTextTruncate from "../../../hooks/useTextTruncate";
import { showError, showSuccess } from "../../../utils/Alert";
import Loader from "../../../utils/Loader";
import {
  useAddBankBeneficiaryMutation,
  useAddCryptoBeneficiaryMutation,
  useFetchBankBeneficiariesQuery,
  useFetchCryptoBeneficiariesQuery,
  useFetchSupportedBanksQuery,
  useFetchSupportedCoinsQuery,
} from "../../../redux/services/beneficiariesApi";
import WalletPinModal from "../../settings/WalletPinModal";
import {
  useRequestOtpMutation,
  useValidateEmailMFAMutation,
  useVerifyTFAMutation,
} from "../../../redux/services/twoFAApi";
import AddBeneficiary from "./AddBeneficiary";

const WithdrawWalletModal: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isNo2faModalOpen,
    onOpen: onNo2faModalOpen,
    onClose: onNo2faModalClose,
  } = useDisclosure();
  const [page, setPage] = useState(1);

  const { formatMoney, truncateText } = useTextTruncate();
  const [supportedCurrencies, setSupportedCurrencies] = useState<any[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);
  const [beneficiaries, setBeneficiaries] = useState<any[]>([]);
  const { data: bankBeneficiaries } = useFetchBankBeneficiariesQuery();
  const { data: cryptoBeneficiaries } = useFetchCryptoBeneficiariesQuery();
  const { data: user } = useFetchProfileQuery();
  const {
    data: withdrawMethods,
    isLoading: isWithdrawMethodsLoading,
    isSuccess: isFetchDepositMethodsSuccess,
  } = useFetchWithdrawalMethodsQuery();
  const {
    data: fetchedSupportedCurrencies,
    isSuccess: isFetchSupportedCurrenciesSuccess,
  } = useFetchSupportedCurrenciesQuery();
  const [checkWalletPin, { isLoading: isWalletPinLoading }] =
    useCheckWalletPinMutation();
  const [requestOtp, { isLoading: isRequestOtpLoading }] =
    useRequestOtpMutation();
  const [validateEmailOtp, { isLoading: isValidateEmailOtp }] =
    useValidateEmailMFAMutation();
  const [validateTFAOtp, { isLoading: isValidateTFAOtp }] =
    useVerifyTFAMutation();
  const [addBankBeneficiary, { isLoading: isAddBankBeneficiaryLoading }] =
    useAddBankBeneficiaryMutation();
  const [addCryptoBeneficiary, { isLoading: isAddCryptoBeneficiaryLoading }] =
    useAddCryptoBeneficiaryMutation();

  const {
    isOpen: isWalletPinModalOpen,
    onOpen: onWalletPinModalOpen,
    onClose: onWalletPinModalClose,
  } = useDisclosure();
  const {
    isOpen: isEmailCodeModalOpen,
    onOpen: onEmailCodeModalOpen,
    onClose: onEmailCodeModalClose,
  } = useDisclosure();
  //   const {isOpen: isWalletPinModalOpen, onOpen: onWalletPinModalOpen, onClose: onWalletPinModalClose} = useDisclosure()

  const [rate, setRate] = useState({
    rate: 1,
    symbol: "$",
  });
  const [withdrawalFormStates, setWithdrawalFormStates] = useState({
    currency: "",
    amount: "",
    amountToRecieve: 0,
  });
  const [finalFormState, setFinalFormState] = useState({
    currency: "",
    amount: 0,
    method: "",
    destination_id: "",
    destination: {},
    code: "",
  });

  const [bankBeneFormState, setBankBeneFormState] = useState({
    account_number: "",
    account_name: "",
    bank_id: "",
    bank_code: "",
    bank_name: "",
    saveForLater: false,
    account_type: "",
    sort_code: "",
    routing_number: "",
    note: "",
  });

  const [cryptoBeneFormstate, setCryptoBeneFormState] = useState({
    coin_id: "",
    network: "",
    address: "",
    tag_id: "",
    saveForLater: false,
  });

  const handleOnBeneInputChange = useCallback(
    (val: any, name: string) => {
      const activePayMethod = paymentMethods.filter(
        (val) => val.isActive === true
      );
      if (activePayMethod.length) {
        if (activePayMethod[0]?.code === "bank_transfer") {
          setBankBeneFormState((prevState) => ({ ...prevState, [name]: val }));
        } else {
          setCryptoBeneFormState((prevState) => ({
            ...prevState,
            [name]: val,
          }));
        }
      }
    },
    [paymentMethods]
  );

  const isEmail2faActive = user?.user?.security?.email;
  const isAuthApp2faActive = user?.user?.security["2fa"]
    ? user?.user?.security["2fa"]?.status === "verified"
    : false;

  const handleOnOpen = () => {
    if (isEmail2faActive || isAuthApp2faActive) {
      onOpen();
    } else {
      onNo2faModalOpen();
    }
  };

  const toggleActive = (clickedMethod: any) => {
    const updatedMethods = paymentMethods.map((method: any) => ({
      ...method,
      isActive: method === clickedMethod,
    }));

    setPaymentMethods(updatedMethods);
  };

  const toggleBeneficiaryActive = (clickedItem: any) => {
    const updatedItems = beneficiaries.map((item: any) => ({
      ...item,
      isActive: item === clickedItem,
    }));

    setBeneficiaries(updatedItems);
  };

  const handleCurrencyChange = (val: string) => {
    setWithdrawalFormStates({ ...withdrawalFormStates, currency: val });

    if (val === "ngn") {
      let getRate = supportedCurrencies.filter((cur) => cur.code === "usd");
      setRate({
        rate: getRate.length ? getRate[0]?.rates?.usd?.rate : 0,
        symbol: getRate.length ? getRate[0].symbol : "?",
      });
    } else if (val === "usd") {
      setRate({
        rate: 1,
        symbol: "$",
      });
    }
  };

  const returnPayMethodIcon = (code: string) => {
    if (code === "bank_transfer") {
      return BankIcon;
    } else if (code === "credit_card") {
      return CardIcon;
    } else if (code === "crypto") {
      return CryptoIcon;
    }
  };

  const { data: supportedCryptos } = useFetchSupportedCoinsQuery();
  const { data: supportedBanks } = useFetchSupportedBanksQuery();
  const [reqWithdraw, { isLoading: isReqWithdrawLoading }] =
    useRequestWithdrawalMutation();

  const returnBankName = (bank_code: string) => {
    const bank = supportedBanks?.filter((val) => val?.code === bank_code);
    if (bank?.length) {
      return bank[0]?.name;
    }
    return null;
  };

  const returnCoin = (coin_id: string) => {
    const coin = supportedCryptos?.filter((val) => val.id === coin_id);
    // console.log("cid", coin_id);
    // supportedCryptos.map((val) => console.log("vid", val.id));
    if (coin?.length) {
      return coin[0];
    }

    return null;
  };

  //   process payment methods
  useEffect(() => {
    if (isFetchDepositMethodsSuccess) {
      const availableMethods = withdrawMethods
        ?.filter((method: any) =>
          method?.currencies.includes(withdrawalFormStates.currency)
        )
        ?.map((method: any) => ({
          ...method,
          isActive: false,
          icon: returnPayMethodIcon(method.code),
        }));

      setPaymentMethods(availableMethods);
    }
  }, [
    isFetchDepositMethodsSuccess,
    withdrawMethods,
    withdrawalFormStates.currency,
  ]);

  //   process fetched Supp. currencies
  useEffect(() => {
    if (isFetchSupportedCurrenciesSuccess) {
      const newArray = fetchedSupportedCurrencies?.map((cur: any) => ({
        ...cur,
        title: cur.name,
        value: cur.code,
        name: cur.code.toUpperCase(),
      }));

      setSupportedCurrencies(newArray);

      if (page === 1 && !withdrawalFormStates.currency) {
        setWithdrawalFormStates((state) => ({ ...state, currency: "usd" }));
      }
    }
  }, [
    isFetchSupportedCurrenciesSuccess,
    fetchedSupportedCurrencies,
    page,
    withdrawalFormStates.currency,
  ]);

  // calc amtToRec
  useEffect(() => {
    let amountToRecieve =
      Number(withdrawalFormStates.amount) * Number(rate.rate);
    amountToRecieve = parseFloat(amountToRecieve.toFixed(2));
    setWithdrawalFormStates((curState) => ({ ...curState, amountToRecieve }));
  }, [rate.rate, withdrawalFormStates.amount]);

  const closeModal = () => {
    onClose();
    setPage(1);
    onEmailCodeModalClose();
    onWalletPinModalClose();
    setRate({
      rate: 1,
      symbol: "$",
    });
    setWithdrawalFormStates({
      currency: "usd",
      amount: "",
      amountToRecieve: 0,
    });
    setFinalFormState({
      currency: "",
      amount: 0,
      method: "",
      destination_id: "",
      destination: {},
      code: "",
    });
  };

  const handleNext = () => {
    const { currency, amount, amountToRecieve } = withdrawalFormStates;
    if (page === 1) {
      if (!currency || !amount || !amountToRecieve) {
        showError("All fields are required");
        return;
      }

      setPage(page + 1);
    } else if (page === 2) {
      const activeMethod = paymentMethods.filter(
        (val) => val.isActive === true
      );

      if (!activeMethod.length) {
        showError("Select a payment method");
        return;
      }

      if (activeMethod[0].code === "bank_transfer") {
        const curBankBeneficiary = bankBeneficiaries?.filter(
          (ben) => ben?.currency === withdrawalFormStates.currency
        );
        const newArray = curBankBeneficiary?.map((val) => ({
          ...val,
          isActive: false,
          icon: BankIcon,
        }));

        setBeneficiaries(newArray || []);
      } else if (activeMethod[0].code === "crypto") {
        const newArray = cryptoBeneficiaries?.map((val) => ({
          ...val,
          isActive: false,
          icon: returnCoin(val?.coin_id)?.logo_url,
        }));

        setBeneficiaries(newArray || []);
      }

      setPage(page + 1);
    } else if (page === 3) {
      const activeBen = beneficiaries.filter((val) => val.isActive === true);
      const activeMethod = paymentMethods.filter(
        (val) => val.isActive === true
      );

      if (!activeBen.length) {
        showError("Select a beneficiary");
        return;
      }

      setFinalFormState({
        ...finalFormState,
        currency: withdrawalFormStates.currency,
        amount: Number(withdrawalFormStates.amount),
        method: activeMethod[0]?.code,
        destination_id: activeBen[0]?.id,
      });

      onWalletPinModalOpen();
    } else if (page === 4) {
      const activeMethod = paymentMethods.filter(
        (val) => val.isActive === true
      );

      if (activeMethod?.length && activeMethod[0]?.code === "bank_transfer") {
        if (withdrawalFormStates.currency === "ngn") {
          if (
            bankBeneFormState.account_number === "" ||
            bankBeneFormState.bank_code === ""
          ) {
            showError("Required fields are missing");
            return;
          }

          if (bankBeneFormState.account_name === "") {
            showError("Could not verify account number");
            return;
          }

          if (bankBeneFormState.saveForLater) {
            setFinalFormState({
              ...finalFormState,
              currency: withdrawalFormStates.currency,
              amount: Number(withdrawalFormStates.amount),
              method: activeMethod[0]?.code,
            });
          } else {
            setFinalFormState({
              ...finalFormState,
              currency: withdrawalFormStates.currency,
              amount: Number(withdrawalFormStates.amount),
              method: activeMethod[0]?.code,
              destination: {
                account_number: bankBeneFormState.account_number,
                account_name: bankBeneFormState.account_name,
                bank_code: bankBeneFormState.bank_code,
              },
            });
          }

          onWalletPinModalOpen();
        } else if (withdrawalFormStates.currency === "usd") {
          if (
            bankBeneFormState.routing_number === "" ||
            bankBeneFormState.sort_code === "" ||
            bankBeneFormState.account_number === "" ||
            bankBeneFormState.account_name === "" ||
            bankBeneFormState.account_type === ""
          ) {
            showError("Required fields are missing");
            return;
          }

          if (bankBeneFormState.saveForLater) {
            setFinalFormState({
              ...finalFormState,
              currency: withdrawalFormStates.currency,
              amount: Number(withdrawalFormStates.amount),
              method: activeMethod[0]?.code,
            });
          } else {
            setFinalFormState({
              ...finalFormState,
              currency: withdrawalFormStates.currency,
              amount: Number(withdrawalFormStates.amount),
              method: activeMethod[0]?.code,
              destination: {
                account_number: bankBeneFormState.account_number,
                account_name: bankBeneFormState.account_name,
                recipient_name: bankBeneFormState.account_name,
                routing_number: bankBeneFormState.routing_number,
                sort_code: bankBeneFormState.sort_code,
                account_type: bankBeneFormState.account_type,
              },
            });
          }

          onWalletPinModalOpen();
        }
      } else if (activeMethod?.length && activeMethod[0]?.code === "crypto") {
        if (
          cryptoBeneFormstate.coin_id === "" ||
          cryptoBeneFormstate.network === "" ||
          cryptoBeneFormstate.address === ""
        ) {
          showError("Required fields are missing");
          return;
        }

        if (cryptoBeneFormstate.saveForLater) {
          setFinalFormState({
            ...finalFormState,
            currency: withdrawalFormStates.currency,
            amount: Number(withdrawalFormStates.amount),
            method: activeMethod[0]?.code,
          });
        } else {
          setFinalFormState({
            ...finalFormState,
            currency: withdrawalFormStates.currency,
            amount: Number(withdrawalFormStates.amount),
            method: activeMethod[0]?.code,
            destination: {
              coin_id: cryptoBeneFormstate.coin_id,
              address: cryptoBeneFormstate.address,
              tag_id: cryptoBeneFormstate.tag_id,
              save_beneficiary: cryptoBeneFormstate.saveForLater,
            },
          });
        }

        onWalletPinModalOpen();
      }
    }
  };

  const validateWalletPin = async (pin: string, resetPin: () => void) => {
    if (pin?.length < 6) {
      showError("Provide wallet pin");
      return;
    }

    await checkWalletPin({ pin })
      .unwrap()
      .then(async (resp) => {
        if (resp?.success) {
          if (isAuthApp2faActive) {
            onWalletPinModalClose();
            onEmailCodeModalOpen();
            resetPin();
          } else {
            requestOtp()
              .unwrap()
              .then((resp) => {
                if (resp?.success) {
                  onWalletPinModalClose();
                  onEmailCodeModalOpen();
                  showSuccess("A code has been sent to your email");
                  resetPin();
                }
              })
              .catch((err) => {
                showError(
                  err?.message ||
                    err?.data?.message ||
                    "An error occurred, could not send email code"
                );
              });
          }
        } else {
          showError("check pin failed");
          console.log(resp);
        }
      })
      .catch((err) => {
        console.log(err);
        showError(err?.message || err?.data?.message || "An error occurred");
      });
  };

  const handleSubmit = async () => {
    const { code } = finalFormState;
    if (code === "") {
      showError("Enter code");
      return;
    }

    let isValidateOtpErr = true;
    if (isAuthApp2faActive) {
      await validateTFAOtp({ code })
        .unwrap()
        .then((resp) => {
          if (resp?.success) {
            isValidateOtpErr = false;
          } else {
            showError("An unknown error occurred");
          }
        })
        .catch((err) => {
          console.log(err);
          showError(
            err?.message || err?.data?.message || "Could not validate code"
          );
        });
    } else if (isEmail2faActive) {
      await validateEmailOtp({ code })
        .unwrap()
        .then((resp) => {
          if (resp?.success) {
            isValidateOtpErr = false;
          } else {
            showError("An unknown error occurred");
          }
        })
        .catch((err) => {
          console.log(err);
          showError(
            err?.message || err?.data?.message || "Could not validate code"
          );
        });
    }

    if (!isValidateOtpErr) {
      let benID = "";
      const {
        account_name,
        account_number,
        account_type,
        bank_code,
        bank_id,
        note,
        routing_number,
        sort_code,
        saveForLater: bankSaveForLater,
      } = bankBeneFormState;
      const {
        address,
        coin_id,
        network,
        tag_id,
        saveForLater: crpytoSaveForLater,
      } = cryptoBeneFormstate;

      if (bankSaveForLater) {
        let benFD: any = {
          title: account_name,
          currency: withdrawalFormStates.currency,
          account_number,
          account_name,
          routing_number,
          sort_code,
          account_type,
          description: note,
        };

        if (withdrawalFormStates.currency === "ngn") {
          benFD = {
            title: account_name,
            currency: withdrawalFormStates.currency,
            account_number,
            account_name,
            bank_code,
            bank_id,
          };
        }

        await addBankBeneficiary(benFD)
          .unwrap()
          .then((resp) => {
            // reset bankBeneForm
            console.log("Beneficiary saved", resp);
            benID = resp?.data?.id;
          })
          .catch((err) => {
            console.log(err);
            showError(
              err?.message || err?.data?.message || "Could not save beneficiary"
            );
          });
      }

      if (crpytoSaveForLater) {
        await addCryptoBeneficiary({
          title: "",
          coin_id,
          network,
          address,
          tag_id,
        })
          .unwrap()
          .then((resp) => {
            // reset cryptBeneForm
            console.log("Beneficiary saved", resp);
            benID = resp?.data?.id;
          })
          .catch((err) => {
            console.log(err);
            showError(
              err?.message || err?.data?.message || "Could not save beneficiary"
            );
          });
      }

      let reqFD = finalFormState;
      if (bankSaveForLater || crpytoSaveForLater) {
        if (!benID) {
          showError("Beneficiary could not be saved");
          return;
        } else {
          reqFD = {
            ...finalFormState,
            destination_id: benID,
          };
        }
      }

      await reqWithdraw(reqFD)
        .unwrap()
        .then((resp) => {
          console.log(resp);
          showSuccess("Withdrawal request sent");
          closeModal();
        })
        .catch((err) => {
          console.log(err);
          showError(
            err?.message ||
              err?.data?.message ||
              "An error occurred, try again later"
          );
        });
    }
  };

  return (
    <>
      <Button
        leftIcon={<PiBank fontSize={"18px"} />}
        size={"lg"}
        fontSize={"16px"}
        fontWeight={400}
        variant={"outline"}
        colorScheme={"buttonPrimary"}
        border={"2px"}
        onClick={handleOnOpen}
        rounded={"8px"}
      >
        Withdraw
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size={{ base: "xs", md: "lg" }}
      >
        <ModalOverlay />
        <ModalContent rounded={"16px"}>
          <ModalHeader>Withdraw Wallet</ModalHeader>
          <ModalCloseButton color={"brand.dangerDark"} />

          {page === 1 && (
            <ModalBody>
              <FormControl display={"flex"} flexDirection={"column"} gap={5}>
                <Box>
                  <FormLabel fontWeight={"semibold"}>Currency</FormLabel>
                  <Select
                    w="100%"
                    focusBorderColor={"gray.300"}
                    fontSize={"13px"}
                    value={withdrawalFormStates.currency}
                    onChange={({ target }) =>
                      handleCurrencyChange(target.value)
                    }
                  >
                    {supportedCurrencies?.map((cur, idx) => (
                      <option key={`curOpt-${idx}`} value={cur?.value}>
                        {cur?.name}
                      </option>
                    ))}
                  </Select>
                </Box>

                <Box>
                  <FormLabel fontWeight={"semibold"}>
                    How much would you like to withdraw?
                  </FormLabel>

                  <InputGroup>
                    <NumberInput
                      w="100%"
                      focusBorderColor={"gray.300"}
                      value={withdrawalFormStates.amount}
                      name="amount"
                      onChange={(value) =>
                        setWithdrawalFormStates({
                          ...withdrawalFormStates,
                          amount: value,
                        })
                      }
                    >
                      <NumberInputField
                        placeholder="0"
                        borderRightRadius="0"
                        borderRight={"0"}
                      />
                    </NumberInput>

                    <InputRightAddon>USD</InputRightAddon>
                  </InputGroup>

                  <Text fontSize={"13px"}>
                    Rates: <b>$1 = {rate.symbol + formatMoney(rate.rate)}</b>
                  </Text>
                </Box>

                <Box>
                  <FormLabel fontWeight={"semibold"}>
                    Amount to receive
                  </FormLabel>

                  <InputGroup>
                    <NumberInput
                      w="100%"
                      min={0}
                      focusBorderColor={"gray.300"}
                      pointerEvents={"none"}
                      value={withdrawalFormStates.amountToRecieve}
                    >
                      <NumberInputField
                        borderRightRadius="0"
                        borderRight={"0"}
                      />
                    </NumberInput>

                    <InputRightAddon textTransform={"uppercase"}>
                      {withdrawalFormStates.currency}
                    </InputRightAddon>
                  </InputGroup>
                </Box>
              </FormControl>
            </ModalBody>
          )}

          {page === 2 && (
            <ModalBody>
              <Loader
                isLoading={isWithdrawMethodsLoading}
                height={"100px"}
                mt={0}
              />
              {paymentMethods?.map((method, idx) => (
                <Flex
                  key={`wMeth-${idx}`}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  cursor="pointer"
                  onClick={() => toggleActive(method)}
                  mb={5}
                >
                  <Flex alignItems={"center"} gap={5} cursor={"pointer"}>
                    <Box backgroundColor={"#F3F3F3"} p={3} rounded={"full"}>
                      <img
                        src={method?.icon}
                        width={"20px"}
                        height={"20px"}
                        style={{ objectFit: "cover" }}
                        alt="card"
                      />
                    </Box>
                    <Box>
                      <Heading as={"h6"} size={"sm"}>
                        {method?.name}
                      </Heading>
                      <Text>{method?.description}</Text>
                    </Box>
                  </Flex>
                  <Radio
                    value={"1"}
                    size={"lg"}
                    isChecked={method?.isActive}
                    transition={".3s ease-out"}
                    style={{
                      borderColor: method?.isActive ? "#232A44" : undefined,
                      backgroundColor: method?.isActive ? "#232A44" : undefined,
                    }}
                  />
                </Flex>
              ))}
            </ModalBody>
          )}

          {page === 3 && (
            <ModalBody>
              <Box>
                <Heading as={"h2"} size={"sm"} mb={4}>
                  Select Beneficiary
                </Heading>
                {!beneficiaries.length ? (
                  <Text textAlign={"center"} mt={4} color={"gray.500"}>
                    You have not added any beneficiaries yet
                  </Text>
                ) : (
                  ""
                )}
                {beneficiaries.map((ben) => (
                  <Flex
                    key={ben?.id}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    cursor="pointer"
                    mb={5}
                    onClick={() => toggleBeneficiaryActive(ben)}
                  >
                    <Flex alignItems={"center"} gap={5} cursor={"pointer"}>
                      <Box backgroundColor={"#F3F3F3"} p={3} rounded={"full"}>
                        <img
                          src={ben?.icon}
                          width={"20px"}
                          height={"20px"}
                          style={{ objectFit: "cover" }}
                          alt="card"
                        />
                      </Box>
                      <Box>
                        <Heading
                          as={"h6"}
                          size={"sm"}
                          textTransform={"capitalize"}
                        >
                          {ben?.title || ben?.account_name || ben?.address}
                        </Heading>
                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          gap={1}
                          alignItems={"center"}
                        >
                          <Text as={"small"}>
                            {returnBankName(ben?.bank_code) ||
                              ben?.network ||
                              "Account number"}
                            :
                          </Text>
                          <b>
                            {truncateText(
                              ben?.account_number || ben?.address,
                              15
                            )}
                          </b>
                        </Box>
                      </Box>
                    </Flex>
                    <Radio
                      value={beneficiaries[0].id.toString()}
                      size={"lg"}
                      isChecked={ben?.isActive}
                      style={{
                        borderColor: ben?.isActive ? "#232A44" : undefined,
                        backgroundColor: ben?.isActive ? "#232A44" : undefined,
                      }}
                    />
                  </Flex>
                ))}
                <Button
                  w="full"
                  background="#E8E8E8"
                  mt={4}
                  color={"gray.700"}
                  onClick={() => setPage(4)}
                >
                  New Beneficiary
                </Button>
              </Box>
            </ModalBody>
          )}

          {page === 4 && (
            <ModalBody>
              <AddBeneficiary
                currency={withdrawalFormStates.currency}
                bankFormState={bankBeneFormState}
                cryptoFormState={cryptoBeneFormstate}
                handleChange={handleOnBeneInputChange}
                payMethod={
                  paymentMethods
                    ? paymentMethods.filter((val) => val.isActive === true)[0]
                    : ""
                }
              />
            </ModalBody>
          )}

          <ModalFooter>
            <Button
              size={"lg"}
              fontSize={"16px"}
              fontWeight={400}
              variant={"light"}
              // backgroundColor={"#E8E8E8"}
              mr={3}
              onClick={() => setPage(page > 1 ? page - 1 : 1)}
              isDisabled={page === 1}
            >
              Back
            </Button>

            <Button
              size={"lg"}
              fontSize={"16px"}
              fontWeight={400}
              colorScheme={"buttonPrimary"}
              onClick={handleNext}
            >
              {page > 2 ? "Submit" : "Next"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* wallet pin modal */}
      <WalletPinModal
        isOpen={isWalletPinModalOpen}
        onClose={onWalletPinModalClose}
        onSubmit={validateWalletPin}
        isLoading={isWalletPinLoading || isRequestOtpLoading}
      />

      {/* email/2fa code modal */}
      <Modal
        isOpen={isEmailCodeModalOpen}
        onClose={onEmailCodeModalClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent rounded={"16px"}>
          <ModalHeader>Enter Security Code</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Loader isLoading={isRequestOtpLoading} height={"100px"} />
            {!isRequestOtpLoading && (
              <FormControl>
                <FormLabel fontSize={"12px"}>
                  {isAuthApp2faActive
                    ? "To confirm this request, please enter the security code from your Authenticator App"
                    : "To confirm this request, please enter the security code we emailed to you"}
                </FormLabel>
                <Input
                  placeholder={"Enter the code to verify"}
                  focusBorderColor={"gray.300"}
                  value={finalFormState.code}
                  onChange={(e) =>
                    setFinalFormState({
                      ...finalFormState,
                      code: e.target.value,
                    })
                  }
                />
              </FormControl>
            )}
          </ModalBody>
          <ModalFooter justifyContent={"space-between"} pb={"18px"}>
            <Button
              colorScheme={"buttonPrimary"}
              width={"full"}
              fontWeight={400}
              onClick={handleSubmit}
              isLoading={
                isReqWithdrawLoading ||
                isValidateEmailOtp ||
                isValidateTFAOtp ||
                isAddBankBeneficiaryLoading ||
                isAddCryptoBeneficiaryLoading
              }
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* no2fa modal */}
      <Modal isOpen={isNo2faModalOpen} onClose={onNo2faModalClose} isCentered>
        <ModalOverlay />
        <ModalContent rounded={"16px"}>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex justify={"center"}>
              <img src={Shield} width={"50%"} alt="" />
            </Flex>
            <Heading
              as={"h3"}
              fontSize={["20px", "22px"]}
              textAlign={"center"}
              fontWeight={700}
              my={2}
            >
              Two-Factor Authentication not enabled
            </Heading>
            <Text
              fontSize={["14px", "16px"]}
              textAlign={"center"}
              fontWeight={500}
            >
              Enable Two-Factor Authentication to withdraw
            </Text>
          </ModalBody>
          <ModalFooter justifyContent={"space-between"} pb={"18px"}>
            <Button
              colorScheme={"buttonLight"}
              color={"root.black"}
              width={"full"}
              fontWeight={400}
              as={Link}
              to={"/dashboard/settings/2fa"}
            >
              Enable 2FA
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default WithdrawWalletModal;

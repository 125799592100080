import { Box, BoxProps, Flex, Icon, Progress, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useEffect } from "react";
import { FaGamepad } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import { toggleHasQuestProgressAnimationRun } from "../../redux/features/animationSlice";
import { RootState } from "../../redux/store";

const QuestProgress = ({ ...rest }: BoxProps) => {
  const dispatch = useDispatch();
  const [controllerAnimePaused, setControllerAnimePaused] = useState(false);
  const hasAnimationRun = useSelector(
    (state: RootState) => state.animationState.hasQuestProgressAnimationRun
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setControllerAnimePaused((state) => !state);
    }, 7000);

    // Clean up interval when component unmounts
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    dispatch(toggleHasQuestProgressAnimationRun(true));
  }, [dispatch]);

  return (
    <Box {...rest}>
      <Text
        fontSize={"14px"}
        fontWeight={500}
        mb={"12px"}
        data-aos={!hasAnimationRun ? "fade-up" : ""}
        data-aos-delay="50"
      >
        Weekly Quests
      </Text>
      <Box
        padding={"14px"}
        py={"18px"}
        bg={"#1A1A1A"}
        color={"#fff"}
        borderRadius={"16px"}
        boxShadow={"0px 5px 10px 0px rgba(0, 0, 0, 0.06)"}
        data-aos={!hasAnimationRun ? "fade-up" : ""}
      >
        <Text>Earn Lo3re token!</Text>

        <Flex my={"12px"} align={"center"} gap={"10px"}>
          <Icon
            as={FaGamepad}
            color={"brand.secondary"}
            boxSize={"24px"}
            className={controllerAnimePaused ? "controllerAnime" : ""}
          />
          <Text fontSize={"14px"} fontWeight={50}>
            Play at least 10 games weekly
          </Text>
        </Flex>

        <Progress
          value={3}
          rounded={"40px"}
          size={"lg"}
          color={"brand.secondary"}
          colorScheme={"progressSecondary"}
          max={10}
          min={0}
          hasStripe
          isAnimated
        />
      </Box>
    </Box>
  );
};

export default QuestProgress;

import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./customFetchBase";
import { BuyTicketBody, GameObj, LeaderboardObj, TicketObj } from "./types";

export const gamesApi = createApi({
  reducerPath: "gamesApi",
  baseQuery,
  tagTypes: ["joinedGames", "myTickets", "isFollowingGm"],
  endpoints: (builder) => ({
    fetchGames: builder.query<GameObj[], void>({
      query: () => `/games?status=live`,
      transformResponse: (results: { data: GameObj[] }) => results.data,
    }),
    fetchGame: builder.query<GameObj, string>({
      query: (gid: string) => `/games/${gid}`,
      transformResponse: (results: { data: GameObj }) => results.data,
    }),
    fetchGameTickets: builder.query<TicketObj[], string>({
      query: (gid: string) => `/games/${gid}/tickets`,
      transformResponse: (results: { data: TicketObj[] }) => results.data,
    }),
    fetchJoinedGames: builder.query<GameObj[], void>({
      query: () => `/games/joined`,
      transformResponse: (results: { data: GameObj[] }) => results.data,
      providesTags: ["joinedGames"],
    }),
    fetchMyTickets: builder.query<TicketObj[], void>({
      query: () => `/tickets`,
      transformResponse: (results: { data: TicketObj[] }) => results.data,
      providesTags: ["myTickets"],
    }),
    fetchLeaderBoard: builder.query<{ leaderboards: LeaderboardObj[] }, string>(
      {
        query: (gid) => `/games/${gid}/leaderboard`,
        transformResponse: (results: {
          data: { leaderboards: LeaderboardObj[] };
        }) => results.data,
      }
    ),
    buyTicket: builder.mutation({
      query: (data: BuyTicketBody) => ({
        url: `/games/${data.gid}/tickets/buy`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["joinedGames", "myTickets"],
    }),
    gameMasterStats: builder.query<any, string>({
      query: (gmId) => `/stats/gm/${gmId}`,
      // transformResponse: (results: { data: GameObj[] }) => results.data,
    }),
    followingGameMaster: builder.query<any, string>({
      query: (gmId) => `/follow/${gmId}`,
      providesTags: ["isFollowingGm"],
      // transformResponse: (results: { data: GameObj[] }) => results.data,
    }),
    followGM: builder.mutation({
      query: (gmId) => ({
        url: `/follow/${gmId}`,
        method: "POST",
      }),
      invalidatesTags: ["isFollowingGm"],
    }),
    unfollowGM: builder.mutation({
      query: (gmId) => ({
        url: `/follow/${gmId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["isFollowingGm"],
    }),
  }),
});

export const {
  useFetchGamesQuery,
  useFetchGameTicketsQuery,
  useFetchJoinedGamesQuery,
  useBuyTicketMutation,
  useFetchMyTicketsQuery,
  useFetchGameQuery,
  useGameMasterStatsQuery,
  useFollowGMMutation,
  useFetchLeaderBoardQuery,
  useFollowingGameMasterQuery,
  useUnfollowGMMutation,
} = gamesApi;

import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Text,
  Image,
} from "@chakra-ui/react";
import { GoDotFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import GradientBg from "../../assets/images/Rectangle gradient.png";
import useTextTruncate from "../../hooks/useTextTruncate";
import useTimeFormatter from "../../hooks/useTimeFormatter";
import { GameObj } from "../../redux/services/types";
import Logo from "../../assets/images/logo-dark.svg";

const FeaturedGameCardMini = ({ game }: { game: GameObj }) => {
  const navigate = useNavigate();
  const { truncateText } = useTextTruncate();
  const { formatDuration } = useTimeFormatter();

  const viewGame = () => {
    navigate(`/lotteries/view-game/${game?.id}`);
  };

  return (
    <Box
      height={"232px"}
      rounded={"16px"}
      padding={"32px"}
      width={{ base: "full" }}
      mx={"auto"}
      backgroundImage={GradientBg}
      backgroundRepeat={"no-repeat"}
      backgroundSize={"cover"}
      position={"relative"}
      color={"brand.white"}
      overflow={"hidden"}
    >
      <Flex
        flexDir={"column"}
        gap={"8px"}
        mb={"28px"}
        pos={"relative"}
        zIndex={2}
      >
        <Heading
          fontSize={"20px"}
          fontWeight={700}
          textTransform={"capitalize"}
        >
          Play {truncateText(game?.title, 15)}
        </Heading>
        <Text fontSize={"14px"}>Click to start playing</Text>
        <Flex gap={"8px"}>
          <Flex align={"center"}>
            <Icon as={GoDotFill} className={`game-${game?.status}`} />
            <Text
              fontWeight={500}
              fontSize={"14px"}
              textTransform={"capitalize"}
            >
              {game?.status}
            </Text>
          </Flex>
          {formatDuration(game?.endOn) ? (
            <Text
              bg={"brand.lightGrey"}
              rounded={"30px"}
              display={"inline"}
              padding={"3px 8px"}
              color={"#000"}
              fontSize={"14px"}
            >
              {formatDuration(game?.endOn)} left
            </Text>
          ) : (
            ""
          )}
        </Flex>
      </Flex>
      <Button
        size={["md"]}
        variant={"outline"}
        border={"2px solid"}
        rounded={"8px"}
        borderColor={"brand.white"}
        color={"brand.white"}
        transition={"0.3s ease-out"}
        width={["180px"]}
        fontWeight={400}
        _hover={{ bg: "rgba(255, 255, 255, 0.4)" }}
        pos={"relative"}
        zIndex={2}
        onClick={viewGame}
      >
        Play now!
      </Button>
      <Image
        src={game?.coverUrl}
        position={"absolute"}
        top={0}
        right={0}
        width={"100%"}
        height={"100%"}
        objectFit={"cover"}
        zIndex={1}
        opacity={"0.3"}
      />
      <Image
        src={Logo}
        position={"absolute"}
        right={"-100px"}
        bottom={"-30px"}
        height={["70%", "85%"]}
        opacity={"0.7 !important"}
        className="featureDiceAnime"
        data-aos={"fade-up-left"}
        data-aos-duration={1500}
        zIndex={1}
        alt=""
      />
    </Box>
  );
};

export default FeaturedGameCardMini;

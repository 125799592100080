import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "./customFetchBase";
import { WalletTypeObj } from "./types";

export const walletApi = createApi({
  reducerPath: "walletApi",
  baseQuery,
  tagTypes: ["balance", "transactions"],
  endpoints: (builder) => ({
    fetchWalletBalance: builder.query<WalletTypeObj[], void>({
      query: () => `/wallets`,
      providesTags: ["balance"],
      transformResponse: (results: { data: WalletTypeObj[] }) => results.data,
    }),
    fetchTransactions: builder.query<any, string | void>({
      query: (query) => `/wallets/transactions${query ? "?" + query : ""}`,
      providesTags: ["transactions"],
      transformResponse: (results: { data: any }) => results.data,
    }),
    activateWalletPin: builder.mutation({
      query: (data) => ({
        url: `/auth/wallet/pin/new`,
        method: "POST",
        body: data,
      }),
    }),
    updateWalletPin: builder.mutation({
      query: (data) => ({
        url: `auth/wallet/pin/update`,
        method: "POST",
        body: data,
      }),
    }),
    checkWalletPin: builder.mutation({
      query: (data) => ({
        url: `auth/wallet/pin`,
        method: "POST",
        body: data,
      }),
    }),
    requestWithdrawal: builder.mutation({
      query: (data) => ({
        url: `wallets/withdrawals`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["transactions"],
    }),
    requestDeposit: builder.mutation({
      query: (data) => ({
        url: `wallets/deposits`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["transactions"],
    }),
    fetchSupportedCurrencies: builder.query<any, void>({
      query: () => `wallets/supported-currencies`,
    }),
    fetchDepositMethods: builder.query<any, void>({
      query: () => `/wallets/deposit-methods`,
    }),
    fetchWithdrawalMethods: builder.query<any, void>({
      query: () => `/wallets/withdrawal-methods`,
    }),
  }),
});

export const {
  useFetchWalletBalanceQuery,
  useActivateWalletPinMutation,
  useUpdateWalletPinMutation,
  useCheckWalletPinMutation,
  useRequestWithdrawalMutation,
  useFetchTransactionsQuery,
  useRequestDepositMutation,
  useFetchSupportedCurrenciesQuery,
  useFetchDepositMethodsQuery,
  useFetchWithdrawalMethodsQuery,
} = walletApi;

import React from "react";
import {Box, Flex} from "@chakra-ui/react";
import ContactCard from "../../../components/cards/ContactCard";
import LayoutContainerWrapper from "../../../components/dashboard/LayoutContainerWrapper";
import UserCount from "../../../components/settings/UserCount";
import Profile from "../../../components/settings/Profile";
import Security from "../../../components/settings/security/Security";
import AboutLo3Re from "../../../components/settings/AboutLo3re";

const Settings = () => {
  return (
    <LayoutContainerWrapper asideChildren={<Aside />}>
      <Box mt={"10px"}>
        <UserCount />

        <Box my={"30px"}>
          <hr />
        </Box>

        <Flex direction={"column"} gap={"30px"}>
          <Profile />
          <Security />
          <AboutLo3Re />
        </Flex>
      </Box>
    </LayoutContainerWrapper>
  );
};

const Aside = () => {
    return (
        <>
            <ContactCard/>
        </>
    );
};

export default Settings;